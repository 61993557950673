import React from 'react';
import classes from '../doelen.module.scss';
import Uitloggen from '../../../assets/svg/Uitloggen.svg';

type ReferentieInfoModalButtonProps = {
  openModal?: () => void;
};

export default function DoelDetailButton({
  openModal
}: ReferentieInfoModalButtonProps) {
  return (
    <img
      className={`${classes.gegevens_doelen_row_button}`}
      src={Uitloggen}
      onClick={() => {
        if (openModal) {
          openModal();
        }
      }}
      alt="bekijken"
      title="bekijken"
    />
  );
}
