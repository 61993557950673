import React from 'react';
import classes from './button.module.scss';

type InviteButtonProps = {
  openModal?: () => void;
  text: string;
  func: (() => void) | undefined;
  col?: '2' | '4' | '8' | '10' | '12';
  isMobile?: boolean;
  extraClasses?: string;
};

export default function InviteButton({
  text,
  func,
  col,
  openModal,
  extraClasses,
  isMobile = false
}: InviteButtonProps) {
  return (
    <div
      id="invite_button"
      className={`col-${!isMobile && col ? col : 12} ${
        classes.button_wrapper
      } ${extraClasses}`}>
      <div
        onClick={() => {
          if (openModal) {
            openModal();
          }

          if (func) {
            func();
          }
        }}
        className={`${classes.button}`}>
        {text}
      </div>
    </div>
  );
}
