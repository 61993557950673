import React, { ReactElement, useEffect, useState } from 'react';
import classes from './referenties.module.scss';
import Header from '../../components/header/header';
import CardRow from '../../components/card-row/card-row';
import Datagrid from '../../components/datagrid/datagrid';
import useWindowDimensions from '../../helpers/window-dimensions';
import { CellInfo, Column } from '../../components/datagrid/gridinterface';
import LogoItem from '../../components/logo-item/logo-item';
import { createInitialsFromFullName } from '../../helpers/logo-helpers';
import ReferentieCard from '../dashboard/referentie-card/referentie-card';
import Footer from '../../components/footer/footer';
import { ApiController } from '../../apiServices/calls/apiController';
import ModalButton from '../../components/modal-button/modal-button';
import ReferentieCardModalBody from './referentie-modal/modal-body';
import ReferentieInfoButton from './referentie-modal/modal-button';
import { enumStatusNumbersToStrings } from '../../helpers/enum-helpers';
import { CustomerReferenceModelWithStrings } from '../../apiServices/calls/controllers/reference';

type ReferentieScreenState = {
  referenties: CustomerReferenceModelWithStrings[] | undefined;
  rewards: CustomerReferenceModelWithStrings[] | null | undefined;
  data: CustomerReferenceModelWithStrings[];
};

export default function Referenties() {
  const { isMobile, width } = useWindowDimensions();
  const [referentieModalIsOpen, setReferentieModalIsOpen] = useState(false);
  const [screenState, setScreenState] = useState<ReferentieScreenState>({
    referenties: undefined,
    rewards: undefined,
    data: []
  });

  const onSaveCallback = async (): Promise<void> => {
    const data = await ApiController.Reference.getCustomerReferences();
    if (data) {
      const referencesOriginal = data.references ? data.references : [];
      const referencesUpdated: CustomerReferenceModelWithStrings[] =
        referencesOriginal.map((reference) => {
          return {
            ...reference,
            originalStatus: reference.status,
            originalConversionTotal: reference.conversionTotal,
            status: enumStatusNumbersToStrings(
              reference.status ? reference.status : 0
            ),
            conversionTotal: `€${
              reference.conversionTotal ? reference.conversionTotal : 0
            }`,
            isReward: false
          };
        });

      return setScreenState({
        ...screenState,
        referenties: referencesUpdated
      });
    }

    return setScreenState({
      ...screenState,
      referenties: []
    });
  };

  useEffect(() => {
    if (
      screenState.referenties === undefined &&
      screenState.rewards === undefined
    ) {
      Promise.all([
        ApiController.Reference.getCustomerReferences(),
        ApiController.Customer.getRewards()
      ]).then((data): void => {
        if (data[0] && data[1]) {
          const referencesOriginal = data[0].references
            ? data[0].references
            : [];
          const referencesUpdated: CustomerReferenceModelWithStrings[] =
            referencesOriginal.map((reference) => {
              return {
                ...reference,
                originalStatus: reference.status,
                originalConversionTotal: reference.conversionTotal,
                status: enumStatusNumbersToStrings(
                  reference.status ? reference.status : 0
                ),
                conversionTotal: `€${
                  reference.conversionTotal ? reference.conversionTotal : 0
                }`
              };
            });

          const rewardsOriginal = data[1].rewards ? data[1].rewards : [];
          const rewardsUpdated: CustomerReferenceModelWithStrings[] =
            rewardsOriginal.map((reward) => {
              return {
                id: reward.id,
                name: reward.name || 'Onboarding reward',
                email: '',
                phoneNumber: '',
                referralDate: undefined,
                originalStatus: undefined,
                status: 'Afgerond (Onboarding reward)',
                companyName: reward.companyName,
                originalConversionTotal: reward.value,
                conversionTotal: `€${reward.value}`,
                isReward: false,
                awardedTo: reward.awardedTo,
                awardedBy: reward.awardedBy,
                ambassadorName: reward.ambassadorName,
                reason: reward.reason,
                value: reward.value,
                createdAt: reward.createdAt
              };
            });

          const dataOriginal = referencesUpdated.concat(rewardsUpdated);

          return setScreenState({
            referenties: referencesUpdated,
            rewards: rewardsUpdated,
            data: dataOriginal
          });
        }

        return undefined;
      });
    }
  }, [screenState]);

  const createRowButton = (e: CellInfo): ReactElement => {
    return (
      <ModalButton
        cardWidth={isMobile ? '12' : '4'}
        modalBody={<ReferentieCardModalBody data={e.value} />}>
        <ReferentieInfoButton />
      </ModalButton>
    );
  };

  const columnHeaders: Column[] = [
    {
      id: 'name',
      label: 'Referenties',
      active: true,
      offset: 'L',
      offsetSize: 1,
      component: (c) => (
        <div className={classes.logo_height_fix}>
          <LogoItem
            logo={createInitialsFromFullName(c.value.name)}
            name={c.value.name}
            company={width > 640 ? '' : c.value.companyName}
          />
        </div>
      )
    },
    { id: 'companyName', label: 'Bedrijf', active: false },
    {
      id: 'status',
      label: 'Status',
      active: false,
      offset: 'S',
      offsetSize: 1
    },
    {
      id: 'conversionTotal',
      label: 'Conversie',
      active: false,
      offset: 'S',
      offsetSize: 1
    }
  ];

  return (
    <div className={`flex-column ${classes.referenties_container}`}>
      <Header subtitle="Jouw referenties!" />
      <CardRow isMobile={isMobile}>
        <Datagrid
          backgroundColor={null}
          col={isMobile ? '12' : '8'}
          columns={columnHeaders}
          data={screenState.data || []}
          rowButton={(e) => (!e.value.isReward ? createRowButton(e) : <></>)}
        />
        {!isMobile && (
          <ReferentieCard
            isMobile={isMobile}
            referentieModalIsOpen={referentieModalIsOpen}
            setReferentieModalIsOpen={setReferentieModalIsOpen}
            onSaveCallback={onSaveCallback}
          />
        )}
      </CardRow>
      {isMobile && (
        <ReferentieCard
          isMobile={isMobile}
          referentieModalIsOpen={referentieModalIsOpen}
          setReferentieModalIsOpen={setReferentieModalIsOpen}
          onSaveCallback={onSaveCallback}
        />
      )}
      <Footer />
    </div>
  );
}
