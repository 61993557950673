import React from 'react';
import { Redirect } from 'react-router-dom';
import { AppState, useAppState } from '../apiServices/appState';
import { Roles } from './router';

const selector = (state: AppState) => state.authentication.accessToken?.role;

export const UnAuthenticatedRouteRedirectsBasedOnRole = () => {
  const role = useAppState(selector).current;

  return (
    <>
      {role === Roles.AttensieCompanyIdentityUser && (
        <Redirect
          to={{
            pathname: '/organisaties/klanten',
            state: { prevPath: window.location.pathname }
          }}
        />
      )}
      {role === Roles.AttensieCustomerIdentityUser && (
        <Redirect
          to={{
            pathname: '/dashboard',
            state: { prevPath: window.location.pathname }
          }}
        />
      )}
      {role === Roles.AttensieAdmin && (
        <Redirect
          to={{
            pathname: '/admin/bedrijven',
            state: { prevPath: window.location.pathname }
          }}
        />
      )}
    </>
  );
};
