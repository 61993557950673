export const createInitialsFromFullName = (
  fullName: string | undefined
): string => {
  if (fullName) {
    const namesArray = fullName.split(' ');
    if (namesArray.length > 1) {
      return `${namesArray[0][0]}${namesArray[namesArray.length - 1][0]}`;
    }
    return fullName[0];
  }

  return '';
};
