import React, { useState } from 'react';
import classes from '../login-register.module.scss';
import AttensieLogoBlauw from '../../../assets/png/Attensie_Logo_Blauw.png';
import AttensieLogoWit from '../../../assets/svg/Attensie_Logo_Wit.svg';
import Groepskorting from '../../../assets/svg/attensie-groepskorting.svg';
import Input, { InputTypes } from '../../../components/input/Input';
import { ApiController } from '../../../apiServices/calls/apiController';

export default function PasswordForgetOne() {
  const [passwordForgetOne, setPasswordForgetOne] = useState({
    email: '',
    error: '',
    submitted: false
  });
  const { email, error, submitted } = passwordForgetOne;

  function handleFormChange(e: React.ChangeEvent<HTMLInputElement>) {
    setPasswordForgetOne({
      ...passwordForgetOne,
      [e.target.name]: e.target.value
    });
  }

  const handlePasswordForgetOne = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    if (passwordForgetOne.email.length === 0) {
      setPasswordForgetOne({
        ...passwordForgetOne,
        error: 'Vul eerst een email email in'
      });

      return;
    }

    const data = await ApiController.Identity.requestPasswordReset(
      passwordForgetOne.email
    );

    if (typeof data === 'string') {
      setPasswordForgetOne({
        ...passwordForgetOne,
        error: data
      });
      return;
    }

    setPasswordForgetOne({
      ...passwordForgetOne,
      submitted: true
    });
  };

  return (
    <>
      <div className={classes.register}>
        <div className={`${classes.register_left}`}>
          <div className={`${classes.register_left_content}`}>
            <div className={classes.register_left_content_img}>
              <img
                className={classes.register_left_content_img_logo}
                src={AttensieLogoBlauw}
                alt="Attensie"
              />
            </div>
            <div className={`${classes.register_left_content_container}`}>
              <p className={`${classes.register_left_content_container_steps}`}>
                &nbsp;
              </p>
              <h1>Wachtwoord vergeten</h1>
              {submitted ? (
                <p>
                  Er is een mail naar je verstuurd met daarin een link om je
                  wachtwoord te wijzigen.
                </p>
              ) : (
                <form
                  className={`d-flex flex-column ${classes.register_left_content_container_form}`}
                  onSubmit={handlePasswordForgetOne}>
                  <Input
                    type={InputTypes.email}
                    placeholder="email@domein.nl"
                    name="email"
                    value={email}
                    onChange={(e) => handleFormChange(e)}
                    required
                  />
                  {error && (
                    <span className={classes.register_error}>{error}</span>
                  )}
                  <button type="submit">STUUR WIJZIGINGSLINK</button>
                </form>
              )}

              <p className={`${classes.register_left_content_container_terms}`}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.attensie.nl/algemene-voorwaarden">
                  Privacy Policy
                </a>
                &nbsp;en de&nbsp;
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.attensie.nl/algemene-voorwaarden">
                  Algemene Voorwaarden
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className={`${classes.register_right}`}>
          <img src={AttensieLogoWit} alt="Attensie" />
        </div>
      </div>
      <img
        className={`${classes.register_groepskorting}`}
        src={Groepskorting}
        alt="groepskorting"
      />
    </>
  );
}
