import React from 'react';
import RouteItem from '../route-item';
import BedrijvenSVG from '../../assets/svg/Bedrijven.svg';

export enum RoutesAdmin {
  bedrijven = '/admin/bedrijven'
}

type SidebarBodyProps = {
  currentRoute: string;
};
export default function AdminSidebarBody({ currentRoute }: SidebarBodyProps) {
  return (
    <div>
      <RouteItem
        logo={BedrijvenSVG}
        routeName="Bedrijven"
        route="/admin/bedrijven"
        active={currentRoute === RoutesAdmin.bedrijven}
      />
    </div>
  );
}
