import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppState, useAppState } from '../../../apiServices/appState';
import { ApiController } from '../../../apiServices/calls/apiController';
import classes from './landing-page.module.scss';

type ValidatedUrlStateType = {
  isValid: boolean;
  value: number;
};

const selector = (state: AppState) => state.isAuthenticated;

export default function LandingPage() {
  const isAuthed = useAppState(selector).current;
  const { companyId, rewardId } =
    useParams<{ companyId: string; rewardId: string }>();

  const [loading, setLoading] = useState(true);
  const [validatedUrlState, setValidatedUrlState] =
    useState<ValidatedUrlStateType>({
      isValid: false,
      value: 0
    });

  useEffect(() => {
    if (rewardId) {
      ApiController.Customer.checkRewardLink(rewardId).then((data): void => {
        setValidatedUrlState({
          isValid: data.isValid,
          value: data.value
        });
        sessionStorage.setItem('rewardId', rewardId);
        setLoading(false);
      });
    }
  }, []);

  return (
    <div className={`${classes.landing}`}>
      {loading ? (
        <div className={`${classes.lds_ring}`}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        <>
          {validatedUrlState.isValid ? (
            <div className={`${classes.wrapper}`}>
              <h2 className={`${classes.title}`}>Waarde van je beloning:</h2>
              <div className={`${classes.container}`}>
                <span className={`${classes.container_reward}`}>
                  {`€ ${validatedUrlState.value}`}
                </span>
              </div>
              <div className={`${classes.links}`}>
                <span className={`${classes.links_link}`}>
                  {isAuthed ? (
                    <a href="/dashboard">Naar dashboard</a>
                  ) : (
                    <>
                      <span className={`${classes.links_link}`}>
                        <a href="/login">Inloggen</a>
                      </span>
                      <span className={`${classes.links_seperator}`}>|</span>
                      <span className={`${classes.links_link}`}>
                        <a href={`/klant/registreer/${companyId}`}>
                          Registreren
                        </a>
                      </span>
                    </>
                  )}
                </span>
              </div>
            </div>
          ) : (
            <p className={`${classes.error}`}>
              De deelcode is ongeldig, neem contact op met de verstuurder.
            </p>
          )}
        </>
      )}
    </div>
  );
}
