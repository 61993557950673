import React, { ReactElement, SyntheticEvent, useState } from 'react';
import Card from '../card/card';
import classes from './modal-button.module.scss';

type ModalButtonProps = {
  children: ReactElement<any, any>;
  modalBody: ReactElement<any, any>;
  cardWidth: '2' | '4' | '6' | '8' | '10' | '12';
  isOpen?: boolean;
};

export default function ModalButton({
  children,
  modalBody,
  cardWidth,
  isOpen
}: ModalButtonProps) {
  const [isModalOpen, setModalOpen] = useState(isOpen || false);

  return (
    <>
      <div
        className={`d-flex align-items-center justify-content-center ${classes.button}`}>
        <div
          onClick={() => {
            setModalOpen(false);
          }}
          className={`${
            isModalOpen ? classes.button_modal : classes.button_modal_closed
          }`}>
          <Card
            onClick={(event: SyntheticEvent) => event.stopPropagation()}
            isModal
            backgroundColor={null}
            col={cardWidth}
            height="auto">
            {React.cloneElement(modalBody, {
              closeModal: (): void => setModalOpen(false),
              openModal: (): void => setModalOpen(true)
            })}
          </Card>
        </div>
        {React.cloneElement(children, {
          closeModal: (): void => setModalOpen(false),
          openModal: (): void => setModalOpen(true)
        })}
      </div>
    </>
  );
}
