import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classesH from '../dashboard.module.scss';
import classesV from './referenties.module.scss';
import Card from '../../../components/card/card';
import ModalButton from '../../../components/modal-button/modal-button';
import ReferentieCardModalButton from './referentie-card-modal/referentie-card-modal-button';
import ReferentieCardModalBody from './referentie-card-modal/referentie-card-modal-body';
import groepsKorting from '../../../assets/svg/attensie-groepskorting.svg';

type CurrentCompaniesCardProps = {
  isMobile: boolean;
  referentieModalIsOpen: boolean;
  setReferentieModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  height?: string | null;
  vertical?: boolean | null;
  onSaveCallback?: () => Promise<void> | undefined;
};

type ReferenceCardHProps = {
  isMobile: boolean;
  referentieModalIsOpen: boolean;
  setReferentieModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSaveCallback?: () => Promise<void> | undefined;
  title: string;
};

type ReferenceCardVProps = {
  isMobile: boolean;
  referentieModalIsOpen: boolean;
  setReferentieModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  height?: string | null;
  onSaveCallback?: () => Promise<void> | undefined;
  title: string;
};

export default function ReferentieCard({
  isMobile,
  referentieModalIsOpen,
  setReferentieModalIsOpen,
  height,
  vertical,
  onSaveCallback
}: CurrentCompaniesCardProps) {
  const location = useLocation();
  const [title, setTitle] = useState('');

  useEffect(() => {
    setTitle(() =>
      location.pathname === '/dashboard'
        ? 'Tevreden over een bedrijf?'
        : 'Tip een nieuwe referentie!'
    );
  }, [location.pathname]);

  return !vertical ? (
    <ReferentieCardV
      isMobile={isMobile}
      referentieModalIsOpen={referentieModalIsOpen}
      setReferentieModalIsOpen={setReferentieModalIsOpen}
      height={height}
      onSaveCallback={onSaveCallback}
      title={title}
    />
  ) : (
    <ReferentieCardH
      isMobile={isMobile}
      referentieModalIsOpen={referentieModalIsOpen}
      setReferentieModalIsOpen={setReferentieModalIsOpen}
      onSaveCallback={onSaveCallback}
      title={title}
    />
  );
}

export function ReferentieCardH({
  isMobile,
  referentieModalIsOpen,
  setReferentieModalIsOpen,
  onSaveCallback,
  title
}: ReferenceCardHProps) {
  return (
    <Card backgroundColor="#3d5afe" col={isMobile ? '12' : '8'} height={null}>
      <div className={`col-12 ${classesH.dashboard_new_referer}`}>
        <div
          className={`flex-column col-${isMobile ? '12' : '6'} d-flex 
          ${classesH.dashboard_new_referer_text_wrapper}`}>
          <div className={`${classesH.dashboard_new_referer_title_wrapper}`}>
            <span className={`${classesH.dashboard_new_referer_title}`}>
              {title}
            </span>
          </div>
          <div>
            <span
              className={`${classesH.dashboard_new_referer_body} ${classesH.dashboard_new_referer_text}`}>
              Elke referentie die jij aanmeldt bij jouw ambassadeurs bedrijven
              is geld voor je waard.
            </span>
          </div>
          <div className="w-100 d-flex align-items-center justify-content-center">
            <ModalButton
              cardWidth={isMobile ? '12' : '4'}
              modalBody={
                <ReferentieCardModalBody
                  onSaveCallback={onSaveCallback}
                  closeModal={() => setReferentieModalIsOpen(false)}
                />
              }
              isOpen={referentieModalIsOpen}>
              <ReferentieCardModalButton
                setReferentieModalIsOpen={setReferentieModalIsOpen}
              />
            </ModalButton>
          </div>
        </div>
        <div
          className={`col-${isMobile ? '12' : '6'} d-flex 
          ${classesH.image_group_wrapper}`}>
          <img
            className={`${classesH.image_group}`}
            alt="groepsKorting"
            src={`${groepsKorting}`}
          />
        </div>
      </div>
    </Card>
  );
}

export function ReferentieCardV({
  isMobile,
  referentieModalIsOpen,
  setReferentieModalIsOpen,
  height,
  onSaveCallback,
  title
}: ReferenceCardVProps) {
  return (
    <Card
      backgroundColor="#3d5afe"
      col={isMobile ? '12' : '4'}
      height={height ?? null}>
      <div className={`col-12 row ${classesV.referenties_new_referer}`}>
        <div className="col d-flex justify-content-center">
          <img
            className={`${classesV.image_group}`}
            alt="groepsKorting"
            src={groepsKorting}
          />
        </div>
        <div
          className={`flex-column col-6 d-flex ${classesV.referenties_new_referer_text_wrapper}`}>
          <div className={`${classesV.referenties_new_referer_title}`}>
            {title}
          </div>
          <div
            className={`${classesV.referenties_new_referer_body} ${classesV.referenties_new_referer_text}`}>
            Elke referentie die jij aanmeldt bij jouw ambassadeurs bedrijven is
            geld voor je waard.
          </div>
          <div
            className={`d-flex align-items-center justify-content-center ${classesV.modal_button_width}`}>
            <ModalButton
              cardWidth={isMobile ? '12' : '4'}
              modalBody={
                <ReferentieCardModalBody onSaveCallback={onSaveCallback} />
              }
              isOpen={referentieModalIsOpen}>
              <ReferentieCardModalButton
                setReferentieModalIsOpen={setReferentieModalIsOpen}
              />
            </ModalButton>
          </div>
        </div>
      </div>
    </Card>
  );
}
