import {
  CompanyIntentionsListApiModel,
  EmbassyListApiModel
} from '../../models/api';
import { http } from '../http';

// All calls for the credit modal
export interface CreditType {
  totalEarned: number;
  totalSpent: number;
  totalAvailable: number;
}

export interface MonthlyCreditType {
  monthStartDate: string;
  totalCredit: number;
}

export interface CustomerReferenceModelWithStringStatus {
  id?: string;
  name?: string;
  email?: string;
  phoneNumber?: string;
  referralDate?: Date;
  status?: string;
  companyName?: string;
  conversionTotal?: number;
}

export interface CustomerRewardApiModel {
  companyName: string;
  id: string;
  name?: string | null;
  value: number;
  isOnboardingReward: boolean;
  awardedTo: string;
  awardedBy: string;
  ambassadorName: string;
  reason: string;
  createdAt: string;
}

export interface CustomerRewardApiListModel {
  rewards?: Array<CustomerRewardApiModel> | null;
}

export interface CustomerCheckRewardLinkListApiModel {
  isValid: boolean;
  value: number;
}
export interface CustomerRedeemRewardLinkListApiModel {
  isSuccesful: boolean;
}

export class CustomerController {
  constructor() {
    throw Error(
      'CustomerController should not initialize, try using it like this => CustomerController.embassies'
    );
  }

  static getEmbassies = async (): Promise<EmbassyListApiModel> =>
    http<EmbassyListApiModel>(
      `${process.env.REACT_APP_SERVER_URL}customer/embassies`
    );

  static getIntentions = async (): Promise<CompanyIntentionsListApiModel> =>
    http<CompanyIntentionsListApiModel>(
      `${process.env.REACT_APP_SERVER_URL}customer/intentions`
    );

  static getRewards = async (): Promise<CustomerRewardApiListModel> =>
    http<CustomerRewardApiListModel>(
      `${process.env.REACT_APP_SERVER_URL}customer/rewards`
    );

  static checkRewardLink = async (
    rewardLinkId: string
  ): Promise<CustomerCheckRewardLinkListApiModel> =>
    http<CustomerCheckRewardLinkListApiModel>(
      `${process.env.REACT_APP_SERVER_URL}customer/checkrewardlink/?rewardLinkId=${rewardLinkId}`
    );

  static redeemRewardLink = async (
    rewardLinkId: string
  ): Promise<CustomerRedeemRewardLinkListApiModel> =>
    http<CustomerRedeemRewardLinkListApiModel>(
      `${process.env.REACT_APP_SERVER_URL}customer/redeemrewardlink/?rewardLinkId=${rewardLinkId}`
    );
}
