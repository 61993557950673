import React from 'react';
import { useHistory } from 'react-router-dom';
import AttensieLogoBlauw from '../../../../assets/png/Attensie_Logo_Blauw.png';
import AttensieLogoWit from '../../../../assets/svg/Attensie_Logo_Wit.svg';
import Groepskorting from '../../../../assets/svg/attensie-groepskorting.svg';
import classes from '../../login-register.module.scss';

export default function VerifyEmail() {
  const history = useHistory();

  return (
    <>
      <div className={classes.register}>
        <div className={`${classes.register_left}`}>
          <div className={`${classes.register_left_content}`}>
            <div className={classes.register_left_content_img}>
              <img
                className={classes.register_left_content_img_logo}
                src={AttensieLogoBlauw}
                alt="Attensie"
              />
            </div>
            <div className={`${classes.register_left_content_container}`}>
              <h1>
                Gelukt!<span> Je account is aangemaakt.</span>
              </h1>
              <p
                className={
                  classes.register_left_content_container_message_info
                }>
                Bevestig je e-mailadres in de mail die je zojuist hebt ontvangen
                vervolgens kan je inloggen en aan de slag!
              </p>
              <button type="button" onClick={() => history.push('/login')}>
                INLOGGEN
              </button>
            </div>
          </div>
        </div>
        <div className={`${classes.register_right}`}>
          <img src={AttensieLogoWit} alt="Attensie" />
        </div>
      </div>
      <img
        className={`${classes.register_groepskorting}`}
        src={Groepskorting}
        alt="groepskorting"
      />
    </>
  );
}
