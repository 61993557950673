import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { AppState, useAppState } from '../apiServices/appState';
import Dashboard from '../views/dashboard/dashboard';
import Referenties from '../views/referenties/referenties';

export enum Roles {
  AttensieCustomerIdentityUser = 'AttensieCustomerIdentityUser',
  AttensieCompanyIdentityUser = 'AttensieCompanyIdentityUser',
  AttensieAdmin = 'AttensieBackofficeIdentityUser',
  Redirects = 'Redirects'
}

const selector = (state: AppState) => ({
  loading: state.authentication.loading,
  role: state.authentication.accessToken?.role,
  authed: state.isAuthenticated
});

const AttensieViewRouter = () => {
  const { loading } = useAppState(selector).current;
  if (loading) return null;

  return (
    <Switch>
      <Route path="/register">
        <div>Register</div>
      </Route>
      <Route path="/login">
        <div>Login</div>
      </Route>
      <Route path="/dashboard">
        <Dashboard />
      </Route>
      <Route path="/referenties">
        <Referenties />
      </Route>
      <Route path="/beloningen">
        <div>Beloningen</div>
      </Route>
      <Route path="/bedrijven">
        <div>Bedrijven</div>
      </Route>
      <Route path="/profiel">
        <div>Profiel</div>
      </Route>
      <Route path="/share/:id">
        <div>Share</div>
      </Route>
      {/* <Route path="/">
        <div>Home</div>
      </Route> */}
    </Switch>
  );
};

export default AttensieViewRouter;
