import React, { useEffect, useState } from 'react';
import classes from './account.module.scss';
import Card from '../../components/card/card';
import Input, {
  InputClassNameType,
  InputTypes
} from '../../components/input/Input';
import { ApiController } from '../../apiServices/calls/apiController';
import {
  AppState,
  updateAppState,
  useAppState
} from '../../apiServices/appState';

export enum DetailsType {
  customerDetails,
  password,
  companyDetails
}

type ChangeAccountCardProps = {
  isMobile: boolean;
  height?: string | undefined;
  col: '2' | '4' | '6' | '8' | '10' | '12';
  title: string;
  firstValue?: string | undefined;
  firstLabel: string;
  firstPlaceholder: string;
  secondValue?: string | undefined;
  secondLabel: string;
  secondPlaceholder: string;
  buttonText: string;
  typeOne: InputTypes;
  typeTwo: InputTypes;
  detailsType: DetailsType;
  disabled?: boolean | undefined;
};

const selector = (state: AppState) => ({
  userDetails: state.userDetails,
  companyDetails: state.companyDetails
});

type DetailsValueType = {
  firstValue: string | null;
  secondValue: string | null;
};

export default function ChangeAccountCard({
  isMobile,
  col,
  height,
  title,
  firstValue,
  firstLabel,
  firstPlaceholder,
  secondValue,
  secondLabel,
  secondPlaceholder,
  buttonText,
  typeOne,
  typeTwo,
  detailsType,
  disabled
}: ChangeAccountCardProps) {
  const [details, setDetails] = useState<DetailsValueType>({
    firstValue: null,
    secondValue: null
  });
  const { userDetails } = useAppState(selector).current;
  const [confirmActive, setConfirmActive] = useState(false);

  const validateEmail = (email: string | null) => {
    if (email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    }
    return true;
  };

  useEffect(() => {
    if (
      detailsType === DetailsType.customerDetails &&
      userDetails.name.length > 0 &&
      userDetails.email.length > 0
    ) {
      setDetails({
        firstValue: userDetails.name,
        secondValue: userDetails.email
      });
    }
  }, [detailsType, userDetails]);

  const handleSubmit = (
    e: React.FormEvent<HTMLFormElement>
  ): void | boolean => {
    e.preventDefault();

    switch (detailsType) {
      case DetailsType.customerDetails:
        if (details.secondValue && !validateEmail(details.secondValue)) {
          return false;
        }
        ApiController.Identity.updateCustomerDetails(
          details.firstValue || userDetails.name,
          details.secondValue || userDetails.email
        );

        updateAppState((state) => {
          state.userDetails.name = details.firstValue || userDetails.name;
          state.userDetails.email = details.secondValue || userDetails.email;
        });
        break;
      case DetailsType.password:
        if (details.secondValue !== null) {
          ApiController.Identity.updatePassword(
            details.firstValue || '',
            details.secondValue
          );
        }
        break;
      default:
        throw Error('Unkown details type');
    }

    setConfirmActive(false);
    return true;
  };

  return (
    <Card
      backgroundColor={null}
      col={isMobile ? '12' : col}
      height={height ?? null}
      disabled={disabled}>
      <div
        className={`d-flex flex-column justify-content-start ${classes.account_card}`}>
        <p
          className={`${classes.account_title} ${classes.account_title_align_left}`}>
          {title}
        </p>
        <hr className={classes.account_hr} />
        <form className={classes.account_card_form} onSubmit={handleSubmit}>
          <label htmlFor={firstValue}>{firstLabel}</label>
          <Input
            type={typeOne}
            value={
              detailsType === DetailsType.customerDetails
                ? details.firstValue || firstValue
                : undefined
            }
            classNameType={InputClassNameType.card}
            placeholder={
              detailsType === DetailsType.customerDetails
                ? undefined
                : firstPlaceholder
            }
            name={firstValue || firstLabel}
            onChange={(e) => {
              setDetails({ ...details, firstValue: e.target.value });
              setConfirmActive(true);
            }}
            id={firstValue}
          />
          <br />
          <br />
          <label htmlFor={secondValue}>{secondLabel}</label>
          <Input
            type={typeTwo}
            placeholder={
              detailsType === DetailsType.customerDetails
                ? undefined
                : secondPlaceholder
            }
            value={
              detailsType === DetailsType.customerDetails
                ? details.secondValue || secondValue
                : undefined
            }
            classNameType={InputClassNameType.card}
            name={secondValue || secondLabel}
            onChange={(e) => {
              setDetails({ ...details, secondValue: e.target.value });
              setConfirmActive(true);
            }}
            id={secondValue}
          />
          <button
            className={`${classes.account_card_form_button} ${
              confirmActive ? classes.account_card_form_button_active : null
            }`}
            type="submit">
            {buttonText}
          </button>
        </form>
      </div>
    </Card>
  );
}
