import { CompanyListModel } from '../../models/api';
import { http } from '../http';

export class AdminController {
  constructor() {
    throw Error(
      'AdminController should not initialize, try using it like this => AdminController.embassies'
    );
  }

  static getBusinesses = async (): Promise<CompanyListModel> =>
    http<CompanyListModel>(
      `${process.env.REACT_APP_SERVER_URL}backoffice/overview`
    );

  static getExport = async (): Promise<any> =>
    http<any>(`${process.env.REACT_APP_SERVER_URL}backoffice/export`);

  static export = async (): Promise<any> =>
    fetch(`${process.env.REACT_APP_SERVER_URL}backoffice/export`)
      .then((response) => new Response(response.body))
      .then((blob) => blob.blob())
      .catch((err) => {
        // throw Error(`something went wrong error: ${err}`);
        console.warn(`something went wrong error: ${err}`);
      });
}
