import React, { ReactElement, useRef, useState } from 'react';
import classes from './reward-link-field.module.scss';

type RewardLinkProps = {
  rewardLink: string;
};

type CopiedState = {
  copied: boolean;
};

export const RewardLinkField = ({
  rewardLink
}: RewardLinkProps): ReactElement => {
  const [modalBodyState, setModalBodyState] = useState<CopiedState>({
    copied: false
  });

  const textInputRef: React.RefObject<HTMLInputElement> = useRef(null);

  const copyLink = () => {
    if (textInputRef !== null && textInputRef.current !== null) {
      const text = textInputRef.current.value;
      navigator.clipboard.writeText(text);
      setModalBodyState({
        copied: true
      });
      setTimeout(() => {
        setModalBodyState({
          copied: false
        });
      }, 3000);
    }
  };
  return (
    <div className={classes.link_field_body_input}>
      <input
        type="text"
        id="inviteLink"
        onClick={copyLink}
        readOnly
        style={{ cursor: 'pointer' }}
        ref={textInputRef}
        value={rewardLink}
      />
      <div className={`${classes.link_field_copied_wrapper}`}>
        {modalBodyState.copied && (
          <span className={`${classes.link_field_copied}`}>Gekopieerd!</span>
        )}
      </div>
    </div>
  );
};
