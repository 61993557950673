import React, { useState } from 'react';
import InviteButton from '../../../components/button/invite-button';
import classes from '../referenties.module.scss';
import helpendeHand from '../../../assets/svg/helpendehand.svg';
import sluit from '../../../assets/png/close_icon.png';
import LogoItem from '../../../components/logo-item/logo-item';
import { createInitialsFromFullName } from '../../../helpers/logo-helpers';
import { ApiController } from '../../../apiServices/calls/apiController';
import { CompanyReferenceModelWithStringStatus } from '../../../apiServices/calls/controllers/company';
import { Status, StatusStrings } from '../../../apiServices/models/api';
import { enumStatusNumbersToStrings } from '../../../helpers/enum-helpers';

type ReferentieCardModalBodyProps = {
  data: CompanyReferenceModelWithStringStatus;
  closeModal?: () => void;
  getReferenceData: () => void;
};

type ModalStateType = {
  status: Status;
};

export enum DoelenModalSteps {
  stepOne,
  success
}

const enumArray = (): string[] => {
  return Object.keys(Status)
    .filter((e) => Number.isNaN(Number(e)) === false)
    .map((key) => {
      return key;
    });
};

export default function ReferentieCardModalBody({
  closeModal,
  data,
  getReferenceData
}: ReferentieCardModalBodyProps) {
  const [modalStep, setModalStep] = useState(DoelenModalSteps.stepOne);
  const [modalState, setModalState] = useState<ModalStateType>({
    status: data.OriginalStatus || Status.NUMBER_1
  });
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

  const renderBody = (step: DoelenModalSteps) => {
    switch (step) {
      case DoelenModalSteps.stepOne:
        return (
          <>
            <div className={classes.referentie_info_modal_body_data}>
              <h4>Gegevens:</h4>
              <span>{`Naam: ${data.name}`}</span>
              <span>{`Email: ${data?.email || ''}`}</span>
              <span>{`Telefoon: ${data?.phoneNumber || ''}`}</span>
              <span>{`Toegevoegd op: ${
                data.referralDate
                  ? new Date(data.referralDate).toLocaleDateString()
                  : ''
              }`}</span>
              <span>{`Toegevoegd door: ${data?.refereeName || ''}`}</span>
            </div>
            <div className={classes.referentie_info_modal_body_data}>
              <h4>Doel:</h4>
              <span>{`Doel: ${data.intention?.name || ''}`}</span>
              <span>{`Status: ${data.status}`}</span>
              <span>{`Waarde: € ${data.intention?.reward || ''}`}</span>
            </div>
            <div className={classes.referentie_info_modal_body_data}>
              <h4>Status:</h4>
              {Number(data.OriginalStatus) === Status.NUMBER_2 ? (
                <>
                  <span>{`Doel: ${enumStatusNumbersToStrings(
                    Number(data.OriginalStatus)
                  )}`}</span>
                </>
              ) : (
                <>
                  <div className={classes.referentie_modal_select_wrapper}>
                    <select
                      onChange={(e) => {
                        setModalState({
                          status: Number(e.currentTarget.value)
                        });
                      }}>
                      {enumArray().map((value) => {
                        return (
                          <option
                            selected={`${data.OriginalStatus}` === value}
                            value={Number(value)}>
                            {`${enumStatusNumbersToStrings(Number(value))}`}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <span>{`Status: ${enumStatusNumbersToStrings(
                    modalState.status
                  )}`}</span>
                </>
              )}
            </div>
          </>
        );
      case DoelenModalSteps.success:
        return (
          <div className={`${classes.referentie_info_modal_success_wrapper}`}>
            <div>
              <img src={helpendeHand} alt="succes_image" />
            </div>
            <div className={`${classes.referentie_info_modal_success_title}`}>
              <span>
                Doel is
                {modalState.status === Status.NUMBER_2
                  ? ' afgerond'
                  : ' aangepast'}
              </span>
            </div>
            <div
              className={`${classes.referentie_info_modal_success_subtitle}`}>
              <span>
                {modalState.status === Status.NUMBER_2
                  ? `Het geld wordt toegekend aan de ambassadeur, deze wordt hier nu
                ook van op de hoogte gesteld.`
                  : `Het doel is succesvol aangepast`}
              </span>
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div
      className={`d-flex flex-column ${classes.referentie_info_modal} ${
        modalStep === DoelenModalSteps.success
          ? classes.referentie_info_modal_success
          : ''
      }`}>
      {modalStep === DoelenModalSteps.stepOne && (
        <div className={classes.referentie_info_modal_header}>
          <LogoItem
            logo={createInitialsFromFullName(data.name || '')}
            name={data.name}
            company={data.intention?.name}
          />
          <img
            src={sluit}
            alt="sluit"
            className={classes.referentie_info_modal_header_close}
            onClick={() => {
              if (closeModal) {
                setModalStep(DoelenModalSteps.stepOne);
                closeModal();
              }
            }}
          />
        </div>
      )}
      <div className={classes.referentie_info_modal_body}>
        {renderBody(modalStep)}
      </div>
      {data.status !== StatusStrings.completed && (
        <div className={classes.referentie_info_modal_footer}>
          <InviteButton
            col="12"
            text={
              modalStep === DoelenModalSteps.success ? 'AFRONDEN' : 'OPSLAAN'
            }
            func={async (): Promise<void> => {
              if (buttonDisabled) {
                return;
              }

              if (modalStep === DoelenModalSteps.success && closeModal) {
                setModalStep(DoelenModalSteps.stepOne);
                getReferenceData();
                closeModal();
                return;
              }
              setButtonDisabled(true);
              await ApiController.Reference.referenceUpdateStatus(
                data.id || '',
                modalState.status
              );
              await getReferenceData();
              setButtonDisabled(false);
              setModalStep(DoelenModalSteps.success);
            }}
            isMobile={false}
          />
        </div>
      )}
    </div>
  );
}
