import React, { FunctionComponent } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AppState, useAppState } from '../apiServices/appState';
import Layout from '../layout/layout';
import { AuthenticatedCompanyRoute } from './AuthenticatedCompanyRoute';
import { AuthenticatedAdminRoute } from './AuthenticatedAdminRoute';
import { Roles } from './router';
import { AuthenticatedCustomerRoute } from './AuthenticatedCustomerRoute copy';

type AuthenticatedRouteProps = {
  component: FunctionComponent;
  path: string;
  forRole: Roles;
};

const selector = (state: AppState) => state.isAuthenticated;

export const AuthenticatedRoute = ({
  component: Component,
  path,
  forRole
}: AuthenticatedRouteProps) => {
  const isAuthed = useAppState(selector).current;
  return (
    <Route path={path}>
      {isAuthed ? (
        <Layout>
          {forRole === Roles.AttensieAdmin && (
            <AuthenticatedAdminRoute path={path} component={Component} />
          )}
          {forRole === Roles.AttensieCompanyIdentityUser && (
            <AuthenticatedCompanyRoute path={path} component={Component} />
          )}
          {forRole === Roles.AttensieCustomerIdentityUser && (
            <AuthenticatedCustomerRoute path={path} component={Component} />
          )}
          {forRole === Roles.Redirects && <Component />}
        </Layout>
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { prevPath: window.location.pathname }
          }}
        />
      )}
    </Route>
  );
};
