import React from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import Dashboard from './views/dashboard/dashboard';
import Referenties from './views/referenties/referenties';
import Sparen from './views/sparen/sparen';

import Account from './views/account/account';
import B2bReferenties from './views/b2b-referenties/b2b-referenties';
import B2bKlanten from './views/b2b-klanten/b2b-klanten';
import B2bDoelen from './views/b2b-doelen/b2b-doelen';
import B2bAccount from './views/b2b-account/b2b-account';
import { RedirectBasedOnRoleHandler } from './router/RedirectBasedOnRoleHandler';
import { AuthenticatedRoute } from './router/AutheticatedRoute';
import { Roles } from './router/router';
import { UnAuthenticatedRoute } from './router/UnAuthenticatedRoute';
import AdminBedrijven from './views/account/admin-bedrijven/admin-bedrijven';
import RegisterCompany from './views/login-register/register/company/register-company';
import Login from './views/login-register/login/login';
import PasswordForgetOne from './views/login-register/password-forget/password-forget-one';
import PasswordForgetTwo from './views/login-register/password-forget/password-forget-two';
import VerifyEmail from './views/login-register/register/company/verify-email';
import RegisterCustomer from './views/login-register/register/register-customer';
import Success from './views/login-register/register/company/success';
import LandingPage from './views/login-register/landing-page/landing-page';

function App() {
  return (
    <div className="app">
      <Switch>
        <AuthenticatedRoute
          forRole={Roles.AttensieCustomerIdentityUser}
          path="/dashboard"
          component={Dashboard}
        />
        <AuthenticatedRoute
          forRole={Roles.AttensieCustomerIdentityUser}
          path="/referenties"
          component={Referenties}
        />
        <AuthenticatedRoute
          component={Sparen}
          forRole={Roles.AttensieCustomerIdentityUser}
          path="/beloningen"
        />
        <AuthenticatedRoute
          forRole={Roles.AttensieCustomerIdentityUser}
          path="/profiel"
          component={Account}
        />
        <AuthenticatedRoute
          path="/admin/bedrijven"
          component={AdminBedrijven}
          forRole={Roles.AttensieAdmin}
        />
        <AuthenticatedRoute
          component={B2bKlanten}
          forRole={Roles.AttensieCompanyIdentityUser || Roles.AttensieAdmin}
          path="/organisaties/klanten"
        />
        <AuthenticatedRoute
          component={B2bReferenties}
          forRole={Roles.AttensieCompanyIdentityUser || Roles.AttensieAdmin}
          path="/organisaties/referenties"
        />
        <AuthenticatedRoute
          path="/organisaties/doelen"
          component={B2bDoelen}
          forRole={Roles.AttensieCompanyIdentityUser || Roles.AttensieAdmin}
        />
        <AuthenticatedRoute
          path="/organisaties/profiel"
          component={B2bAccount}
          forRole={Roles.AttensieCompanyIdentityUser || Roles.AttensieAdmin}
        />
        <UnAuthenticatedRoute
          path="/registreer"
          component={() => <RegisterCompany />}
        />
        <UnAuthenticatedRoute
          path="/klant/registreer/:companyId"
          component={() => <RegisterCustomer />}
        />
        <Route path="/verify/:token/:email" component={() => <Success />} />
        <UnAuthenticatedRoute
          path="/verify"
          component={() => <VerifyEmail />}
        />
        <UnAuthenticatedRoute path="/login" component={() => <Login />} />
        <UnAuthenticatedRoute
          path="/wachtwoord-vergeten"
          component={() => <PasswordForgetOne />}
        />
        <UnAuthenticatedRoute
          path="/reset/password"
          component={() => <PasswordForgetTwo />}
        />
        <Route path="/klant/beloning/:rewardId/:companyId">
          <LandingPage />
        </Route>
        <AuthenticatedRoute
          forRole={Roles.Redirects}
          path="*"
          component={RedirectBasedOnRoleHandler}
        />
      </Switch>
    </div>
  );
}

export default App;
