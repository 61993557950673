import React, { ReactNode } from 'react';
import classes from './referentie-card-modal.module.scss';
import { ReferentieModalSteps } from './referentie-card-modal-body';

export const renderHeader = (
  currentStep: ReferentieModalSteps,
  closeModal?: () => void
): ReactNode => {
  switch (currentStep) {
    case ReferentieModalSteps.stepOne:
      return (
        <>
          <div
            className={classes.referentie_modal_header_close}
            onClick={closeModal}>
            <span>x</span>
          </div>
          <span className={classes.referentie_modal_header_title}>
            1/2 Referentie aanmelden
          </span>
          <span className={classes.referentie_modal_header_subtitle}>
            Kies het bedrijf en het doel
          </span>
        </>
      );
    case ReferentieModalSteps.stepTwo:
      return (
        <>
          <span className={classes.referentie_modal_header_title}>
            2/2 Referentie aanmelden
          </span>
          <span className={classes.referentie_modal_header_subtitle}>
            Vul de gegevens in van de referentie
          </span>
        </>
      );
    default:
      return <></>;
  }
};
