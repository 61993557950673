import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classes from '../login-register.module.scss';
import AttensieLogoBlauw from '../../../assets/png/Attensie_Logo_Blauw.png';
import AttensieLogoWit from '../../../assets/svg/Attensie_Logo_Wit.svg';
import Groepskorting from '../../../assets/svg/attensie-groepskorting.svg';
import Input, { InputTypes } from '../../../components/input/Input';
import { ApiController } from '../../../apiServices/calls/apiController';
import { loginAction } from '../../../apiServices/actions/login';
import { TokenResponseApiModel } from '../../../apiServices/models/api';

type LoginStateType = {
  loginEmail: string;
  loginPassword: string;
  error: string | null;
};

export default function Login() {
  const [loginForm, setLoginForm] = useState<LoginStateType>({
    loginEmail: '',
    loginPassword: '',
    error: null
  });
  const { loginEmail, loginPassword, error } = loginForm;

  function handleFormChange(e: React.ChangeEvent<HTMLInputElement>) {
    setLoginForm({
      ...loginForm,
      [e.target.name]: e.target.value
    });
  }

  const handleLogin = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    if (
      loginForm.loginEmail.length === 0 ||
      loginForm.loginPassword.length === 0
    ) {
      setLoginForm({
        ...loginForm,
        error: 'Vul eerst een email en wachtwoord in'
      });

      return;
    }
    await ApiController.Auth.login(
      loginForm.loginEmail,
      loginForm.loginPassword
    )
      .then((res: TokenResponseApiModel | string) => {
        if (typeof res === 'string') {
          throw Error(res);
        }

        loginAction({
          accessToken: res.accessToken.token,
          refreshToken: res.refreshToken.token
        });

        setLoginForm({
          ...loginForm,
          error: null
        });
      })
      .catch((errorMessage: Error) => {
        setLoginForm({
          ...loginForm,
          error: errorMessage.message
        });
      });
  };

  return (
    <>
      <div className={classes.register}>
        <div className={`${classes.register_left}`}>
          <div className={`${classes.register_left_content}`}>
            <div className={classes.register_left_content_img}>
              <img
                className={classes.register_left_content_img_logo}
                src={AttensieLogoBlauw}
                alt="Attensie"
              />
            </div>
            <div className={`${classes.register_left_content_container}`}>
              <p className={`${classes.register_left_content_container_steps}`}>
                &nbsp;
              </p>
              <h1>Login</h1>
              <form
                className={`d-flex flex-column ${classes.register_left_content_container_form}`}
                onSubmit={handleLogin}>
                <Input
                  type={InputTypes.email}
                  placeholder="email@domein.nl"
                  name="loginEmail"
                  value={loginEmail}
                  onChange={(e) => handleFormChange(e)}
                  required
                />
                <Input
                  type={InputTypes.password}
                  placeholder="Voer je wachtwoord in"
                  name="loginPassword"
                  value={loginPassword}
                  onChange={(e) => handleFormChange(e)}
                  required
                />
                {error && (
                  <span className={classes.register_error}>{error}</span>
                )}
                <button type="submit">LOGIN</button>
                <p
                  className={`${classes.register_left_content_container_form_login}`}>
                  Nog geen account?{' '}
                  <Link to="/registreer">Registreer hier.</Link>
                </p>
                <p
                  className={`${classes.register_left_content_container_form_login}`}>
                  <Link to="/wachtwoord-vergeten">Wachtwoord vergeten</Link>
                </p>
              </form>

              <p className={`${classes.register_left_content_container_terms}`}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.attensie.nl/algemene-voorwaarden">
                  Privacy Policy
                </a>
                &nbsp;en de&nbsp;
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.attensie.nl/algemene-voorwaarden">
                  Algemene Voorwaarden
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className={`${classes.register_right}`}>
          <img src={AttensieLogoWit} alt="Attensie" />
        </div>
      </div>
      <img
        className={`${classes.register_groepskorting}`}
        src={Groepskorting}
        alt="groepskorting"
      />
    </>
  );
}
