import React, { ReactNode } from 'react';
import classes from './referentie-card-modal.module.scss';
import {
  ReferentieModalBodyState,
  ReferentieModalSteps
} from './referentie-card-modal-body';
import { ApiController } from '../../../../apiServices/calls/apiController';

export const renderFooter = (
  currentStep: ReferentieModalSteps,
  setModal: (value: React.SetStateAction<ReferentieModalSteps>) => void,
  closeModal: (() => void) | undefined,
  modalBodyState: ReferentieModalBodyState,
  onSaveCallback?: () => Promise<void> | undefined
): ReactNode => {
  switch (currentStep) {
    case ReferentieModalSteps.stepOne:
      return (
        <>
          <div
            onClick={() => {
              setModal(ReferentieModalSteps.stepTwo);
            }}
            className={`${classes.referentie_modal_footer_button}`}>
            VERDER
          </div>
        </>
      );
    case ReferentieModalSteps.stepTwo:
      return (
        <>
          <div
            onClick={() => {
              const { companyId, intentionId, name, email, tel } =
                modalBodyState;

              if (
                !companyId ||
                companyId.length === 0 ||
                !intentionId ||
                intentionId?.length === 0 ||
                !name ||
                name?.length === 0 ||
                !email ||
                email.length === 0 ||
                !tel ||
                tel.length === 0
              ) {
                // Eventual error handling
                return;
              }
              setModal(ReferentieModalSteps.success);
            }}
            className={`${classes.referentie_modal_footer_button}`}>
            AANMELDEN
          </div>
        </>
      );
    case ReferentieModalSteps.success:
      return (
        <>
          <div
            onClick={async () => {
              if (closeModal) {
                closeModal();
              }

              const { companyId, intentionId, name, email, tel } =
                modalBodyState;

              if (
                !companyId ||
                companyId.length === 0 ||
                !intentionId ||
                intentionId?.length === 0 ||
                !name ||
                name?.length === 0 ||
                !email ||
                email.length === 0 ||
                !tel ||
                tel.length === 0
              ) {
                // Eventual error handling
                return;
              }

              await ApiController.Reference.createReference(
                companyId,
                intentionId,
                name,
                email,
                tel,
                modalBodyState.mayBeContacted || false
              );

              if (onSaveCallback) {
                await onSaveCallback();
              }

              setModal(ReferentieModalSteps.stepOne);
            }}
            className={`${classes.referentie_modal_footer_button}`}>
            AFRONDEN
          </div>
        </>
      );
    default:
      return <></>;
  }
};
