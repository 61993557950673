import React from 'react';
import { useLocation } from 'react-router-dom';
import { logoutAction } from '../apiServices/actions/logout';
import attensieLogo from '../assets/svg/LogoAttensie.svg';
import Uitloggen from '../assets/svg/Uitloggen.svg';
import useWindowDimensions from '../helpers/window-dimensions';
import classes from './layout.module.scss';
import AdminSidebarBody from './sidebar-nav-body/admin-body';
import ClientSidebarBody from './sidebar-nav-body/client-body';
import OrganisationSidebarBody from './sidebar-nav-body/organisation-body';

type SidebarProps = {
  sidebarOpen?: boolean | undefined;
};

enum SidebarBodyTypes {
  organisaties = 'organisaties',
  admin = 'admin'
}
export default function Sidebar({ sidebarOpen }: SidebarProps) {
  const currentRoute = useLocation().pathname;
  const { isMobile, isTablet } = useWindowDimensions();

  return (
    <div className={`column ${classes.sidebar_container}`}>
      <div>
        <div className={`${classes.sidebar_header}`}>
          <img
            src={attensieLogo}
            alt="attensie_logo"
            className={
              typeof sidebarOpen !== 'undefined' && isMobile && !isTablet
                ? classes.sidebar_header_logo_mobile
                : ''
            }
          />
        </div>
        {currentRoute.includes(SidebarBodyTypes.admin) ? (
          <AdminSidebarBody currentRoute={currentRoute} />
        ) : currentRoute.includes(SidebarBodyTypes.organisaties) ? (
          <OrganisationSidebarBody currentRoute={currentRoute} />
        ) : (
          <ClientSidebarBody currentRoute={currentRoute} />
        )}
      </div>
      <div
        className={`${classes.sidebar_logout}`}
        onClick={() => logoutAction()}>
        <h3>Uitloggen</h3>
        <img src={Uitloggen} alt="uitloggen" />
      </div>
    </div>
  );
}
