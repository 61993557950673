import { createImmutableState } from '../tools/immutable-state';
import Token, { TokenStatus } from './config/token';

const tokens = {
  accessToken: localStorage.getItem('accessToken'),
  refreshToken: localStorage.getItem('refreshToken'),
  participationToken: localStorage.getItem('participantToken')
};

const accessToken = tokens.accessToken ? new Token(tokens.accessToken) : null;
const refreshToken = tokens.refreshToken
  ? new Token(tokens.refreshToken)
  : null;
const isAuthenticated =
  accessToken?.status === TokenStatus.Valid &&
  refreshToken?.status === TokenStatus.Valid;

const initialState = {
  isAuthenticated,
  authentication: {
    accessToken,
    refreshToken,
    sessionId: '',
    id: '',
    role: '',
    loading: true
  },
  userDetails: {
    name: '',
    email: '',
    loading: true,
    id: ''
  },
  companyDetails: {
    companyName: '',
    addressStreet: '',
    addressNumber: '',
    addressAddition: '',
    addressPostalCode: '',
    addressCity: '',
    email: '',
    name: '',
    loading: true,
    id: ''
  },
  adminDetails: {
    name: '',
    email: '',
    id: '',
    loading: true,
    companyId: ''
  }
};

export type AppState = typeof initialState;
export const { useImmutableState: useAppState, updateState: updateAppState } =
  createImmutableState('AppState', initialState);
