import { useEffect, useState } from 'react';
import { AppState, useAppState } from '../apiServices/appState';
import { Roles } from '../router/router';

export default function useCompanyId() {
  const [returnedCompanyId, setReturnedCompanyId] = useState('');

  const selector = (state: AppState) => ({
    role: state.authentication.accessToken?.role,
    companyId: state.adminDetails.companyId
  });
  const { role, companyId } = useAppState(selector).current;

  useEffect(() => {
    setReturnedCompanyId(companyId);
  }, [companyId]);

  return role === Roles.AttensieAdmin ? returnedCompanyId : '';
}
