import React, { ReactElement, useEffect, useState } from 'react';
import Card from '../card/card';
import classes from './datagrid.module.scss';
import GridHeader from './gridheader';
import GridRow from './gridrow';
import { Column, CellInfo } from './gridinterface';
import LogoItem from '../logo-item/logo-item';
import useWindowDimensions from '../../helpers/window-dimensions';

type CardProps = {
  col: '2' | '4' | '8' | '10' | '12';
  backgroundColor: string | null;
  columns: Column[];
  data: any[];
  rowButton?: ((cellInfo: CellInfo) => React.ReactNode) | ReactElement;
};

export default function Datagrid({
  col,
  backgroundColor,
  columns,
  data,
  rowButton
}: CardProps) {
  const [colSizeGrid, setColSizeGrid] = useState<number>(0);
  const { isMobile, width } = useWindowDimensions();

  useEffect(() => {
    setColSizeGrid(12 / columns.length);
    columns[0].active = true;
  }, [columns, isMobile]);

  const calcCellSize = (
    offset: 'S' | 'L' | undefined,
    offsetSize: number | undefined
  ): number => {
    const size: number = colSizeGrid;
    if (isMobile && width <= 640) {
      return 10;
    }
    switch (offset) {
      case 'S':
        return size - (offsetSize ?? 0);
      case 'L':
        return size + (offsetSize ?? 0);
      default:
        return size;
    }
  };

  return (
    <Card
      backgroundColor={backgroundColor}
      col={isMobile ? '12' : col}
      height="fit-content">
      <div className={`${classes.datagrid_wrapper}`}>
        <GridHeader
          calcCellSize={calcCellSize}
          columns={isMobile && width <= 640 ? [columns[0]] : columns}
        />
        {data && data.length ? (
          data.map((item, index) => (
            <GridRow
              index={index}
              calcCellSize={calcCellSize}
              item={item}
              columns={isMobile && width <= 640 ? [columns[0]] : columns}
              rowButton={rowButton}
            />
          ))
        ) : (
          <div className="col-8">
            <LogoItem
              logo="?"
              name="Geef je eerste referentie door."
              company="Je bent goed bezig!"
            />
          </div>
        )}
      </div>
    </Card>
  );
}
