import React from 'react';
import classes from '../klanten.module.scss';
import Uitloggen from '../../../assets/svg/Uitloggen.svg';

type AmbassadeurInfoButtonProps = {
  openModal?: () => void;
  setAmbassadeurProfileModalIsOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  ambassadeurProfileModalIsOpen: boolean;
  textBefore?: string | undefined;
};

export default function AmbassadeurInfoButton({
  openModal,
  setAmbassadeurProfileModalIsOpen: setAmbassadeurModalIsOpen,
  ambassadeurProfileModalIsOpen: ambassadeurModalIsOpen,
  textBefore
}: AmbassadeurInfoButtonProps) {
  return (
    <>
      {textBefore && <p className={classes.beforeText}>{textBefore}</p>}
      <img
        className={`${classes.datagrid_button}`}
        src={Uitloggen}
        onClick={() => {
          if (openModal) {
            openModal();
          }
          setAmbassadeurModalIsOpen(!ambassadeurModalIsOpen);
        }}
        alt="bekijken"
        title="bekijken"
      />
    </>
  );
}
