import React, { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import classes from '../login-register.module.scss';
import AttensieLogoBlauw from '../../../assets/png/Attensie_Logo_Blauw.png';
import Checkmark from '../../../assets/svg/Checkmark.svg';
import AttensieLogoWit from '../../../assets/svg/Attensie_Logo_Wit.svg';
import Groepskorting from '../../../assets/svg/attensie-groepskorting.svg';
import inputClasses from '../../../components/input/input.module.scss';
import { ApiController } from '../../../apiServices/calls/apiController';
import Input, { InputTypes } from '../../../components/input/Input';

export enum PageState {
  registerStepOne
}

type RegisterCustomerParamsType = {
  companyId: string;
};

export default function RegisterCustomer() {
  const [registerFormOne, setRegisterFormOne] = useState({
    fullName: '',
    email: '',
    password: '',
    error: ''
  });
  const history = useHistory();

  const { fullName, email, password, error } = registerFormOne;
  const params = useParams<RegisterCustomerParamsType>();

  function handleFormChange(e: React.ChangeEvent<HTMLInputElement>) {
    setRegisterFormOne({
      ...registerFormOne,
      [e.target.name]: e.target.value
    });
  }

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    if (registerFormOne.password.length <= 5) {
      return;
    }

    const rewardId = sessionStorage.getItem('rewardId');
    const errorStatus = await ApiController.Identity.registerCustomer({
      email: registerFormOne.email,
      password: registerFormOne.password,
      fullName: registerFormOne.fullName,
      companyId: params.companyId,
      rewardId
    });

    if (typeof errorStatus === 'string') {
      setRegisterFormOne({
        ...registerFormOne,
        error: errorStatus
      });
      return;
    }

    sessionStorage.clear();
    history.push('/verify');
  };

  return (
    <>
      <div className={classes.register}>
        <div className={`${classes.register_left}`}>
          <div className={`${classes.register_left_content}`}>
            <div className={classes.register_left_content_img}>
              <img
                className={classes.register_left_content_img_logo}
                src={AttensieLogoBlauw}
                alt="Attensie"
              />
              <div
                style={{ paddingTop: '5px' }}
                className={`${classes.register_left_checkmarks}`}>
                <div className="d-flex flex-row">
                  <img
                    className={classes.register_left_checkmarks_checkmark}
                    src={Checkmark}
                    alt="check"
                  />
                  <span>Raad de bedrijven aan waar jij achter staat</span>
                </div>
                <div className="d-flex flex-row">
                  <img
                    className={classes.register_left_checkmarks_checkmark}
                    src={Checkmark}
                    alt="check"
                  />
                  <span>Verdien geld met jou netwerk</span>
                </div>
              </div>
            </div>
            <div className={`${classes.register_left_content_container}`}>
              <h1>Maak je account</h1>
              <form
                className={`d-flex flex-column ${classes.register_left_content_container_form}`}
                onSubmit={handleSubmit}>
                <Input
                  type={InputTypes.text}
                  placeholder="Volledige naam"
                  name="fullName"
                  value={fullName}
                  onChange={(e) => handleFormChange(e)}
                  required
                />
                <Input
                  type={InputTypes.email}
                  placeholder="email@domein.nl"
                  name="email"
                  value={email}
                  onChange={(e) => handleFormChange(e)}
                  required
                />
                <input
                  type="password"
                  name="password"
                  placeholder="Voer je wachtwoord in"
                  className={inputClasses.default}
                  value={password}
                  required
                  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{6,}$"
                  onChange={(e) => {
                    handleFormChange(e);
                    if (!e.currentTarget.validity.patternMismatch) {
                      e.currentTarget.setCustomValidity('');
                    }

                    if (e.currentTarget.validity.patternMismatch) {
                      e.currentTarget.setCustomValidity(
                        'Het wachtwoord moet minimaal 6 karakters, één hoofdletter, één cijfer en één speciaal teken bevatten'
                      );
                    }
                  }}
                />
                {error && error.length !== 0 && (
                  <div>
                    <span style={{ color: 'red' }}>{error}</span>
                  </div>
                )}
                <button type="submit">REGISTREER</button>
                <p
                  className={`${classes.register_left_content_container_login}`}>
                  Al een account? <Link to="/login">Log dan in.</Link>
                </p>
              </form>
              <p className={`${classes.register_left_content_container_terms}`}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.attensie.nl/algemene-voorwaarden">
                  Privacy Policy
                </a>
                &nbsp;en de&nbsp;
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.attensie.nl/algemene-voorwaarden">
                  Algemene Voorwaarden
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className={`${classes.register_right}`}>
          <img src={AttensieLogoWit} alt="Attensie" />
        </div>
      </div>
      <img
        className={`${classes.register_groepskorting}`}
        src={Groepskorting}
        alt="groepskorting"
      />
    </>
  );
}
