import React, { ReactElement, useState } from 'react';
import { AppState, useAppState } from '../../../../apiServices/appState';
import { ApiController } from '../../../../apiServices/calls/apiController';
import { RewardLinkButton } from './reward-link-button/reward-link-button';
import { RewardLinkField } from './reward-link-field/reward-link-field';

type RewardLinkGeneratorProps = {
  rewardId: string;
};

const selector = (state: AppState) => ({
  companyId: state.companyDetails.id
});

export const RewardLinkGenerator = ({
  rewardId
}: RewardLinkGeneratorProps): ReactElement => {
  const [showRewardLinkField, setShowRewardLinkField] = useState(false);
  const [rewardLink, setRewardLink] = useState('');
  const { companyId } = useAppState(selector).current;

  const getRewardUrl = () => {
    ApiController.Company.createRewardLink(rewardId).then((data): void => {
      setRewardLink(`${data.url}/${companyId}`);
    });
  };

  const generateRewardLink = () => {
    getRewardUrl();
    setShowRewardLinkField(true);
  };

  return (
    <>
      <RewardLinkButton onClick={generateRewardLink} />
      {showRewardLinkField && <RewardLinkField rewardLink={rewardLink} />}
    </>
  );
};
