import React, { useCallback, useEffect, useState } from 'react';
import classes from './doelen.module.scss';
import Header from '../../components/header/header';
import CardRow from '../../components/card-row/card-row';
import useWindowDimensions from '../../helpers/window-dimensions';
import CurrentDoelenCard from './current-doelen-card/current-doelen-card';
import Footer from '../../components/footer/footer';
import { ApiController } from '../../apiServices/calls/apiController';
import {
  IntentionApiModel,
  RewardApiModel,
  CompanyAmbassadorModel
} from '../../apiServices/models/api';
import useCompanyId from '../../helpers/useCompanyId';
import Card from '../../components/card/card';
import CurrentBeloningenCard from './current-beloningen-card/current-beloningen-card';

export type DoelenScreenStateType = {
  intentions?: IntentionApiModel[];
  rewards?: RewardApiModel[];
  ambassadors?: CompanyAmbassadorModel[];
};

export default function B2bDoelen() {
  const { isMobile } = useWindowDimensions();
  const [screenState, setScreenState] = useState<DoelenScreenStateType>({
    intentions: undefined,
    rewards: undefined,
    ambassadors: undefined
  });
  const companyId = useCompanyId();

  const getDoelenData = useCallback(() => {
    Promise.all([
      ApiController.Company.getCompanyIntentions(companyId),
      ApiController.Company.getCompanyRewards(companyId),
      ApiController.Company.getAmbassadors(companyId)
    ]).then((data): void => {
      return setScreenState({
        intentions: data[0] ? data[0].intentions : undefined,
        rewards: data[1].rewards
          ? data[1].rewards.map((x: RewardApiModel) => {
              return {
                ...x,
                date: new Date(x.date).toLocaleDateString('en-CA', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit'
                })
              };
            })
          : undefined,
        ambassadors: data[2] ? data[2].ambassadors : undefined
      });
    });
  }, [setScreenState, companyId]);

  useEffect(() => {
    if (
      !screenState.ambassadors ||
      !screenState.intentions ||
      !screenState.rewards
    ) {
      getDoelenData();
    }
  }, [screenState, getDoelenData]);
  return (
    <div className={`flex-column ${classes.gegevens_container}`}>
      <Header subtitle="Welkom terug!" />
      <CardRow isMobile={isMobile} justifyStart>
        <CurrentDoelenCard
          data={screenState?.intentions || []}
          isMobile={isMobile}
          getDoelenData={getDoelenData}
        />
        <CurrentBeloningenCard
          data={screenState?.rewards || []}
          isMobile={isMobile}
          state={screenState}
          getDoelenData={getDoelenData}
        />
        <Card col="4" height="0px" backgroundColor="rgba(72, 107, 147, 0)">
          {}
        </Card>
      </CardRow>
      <Footer />
    </div>
  );
}
