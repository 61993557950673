import React, { ReactNode, useState } from 'react';
import Sidebar from './sidebar';
import classes from './layout.module.scss';
import useWindowDimensions from '../helpers/window-dimensions';
import TopHeader from './top-header';

type LayoutProps = {
  children: ReactNode;
};
export default function Layout({ children }: LayoutProps) {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const { isMobile, isTablet } = useWindowDimensions();
  return (
    <div className={`${classes.layout_container} row`}>
      {isMobile && !isTablet && (
        <TopHeader setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />
      )}
      <div
        className={`${classes.layout_sidebar} col-sm-4 col-md-3 col-lg-2 ${
          isMobile && !isTablet && sidebarOpen
            ? classes.layout_sidebar_show
            : ''
        }`}>
        <Sidebar sidebarOpen={sidebarOpen} />
      </div>
      <div className="col-sm-8 col-md-9 col-lg-10 wrapper">{children}</div>
    </div>
  );
}
