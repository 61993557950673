import React from 'react';
import classes from '../klanten.module.scss';
import Card from '../../../components/card/card';
import groepskorting from '../../../assets/svg/attensie-groepskorting.svg';
import ModalButton from '../../../components/modal-button/modal-button';
import AmbassadeurInviteModalBody from '../amb-invite-modal/modal-body';
import AmbassadeurInviteButton from '../amb-invite-modal/modal-button';

type ReferentiesCardProps = {
  height?: string | null | undefined;
  isMobile: boolean;
  setAmbassadeurModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  ambassadeurModalIsOpen: boolean;
};

export default function AmbassadeurCard({
  height,
  isMobile,
  setAmbassadeurModalIsOpen,
  ambassadeurModalIsOpen
}: ReferentiesCardProps) {
  return (
    <Card backgroundColor="#3d5afe" col="4" height={height ?? null}>
      <div className={`col-12 row ${classes.referenties_new_referer}`}>
        <div className="col d-flex justify-content-center">
          <img
            className={`${classes.image_group}`}
            alt="groepskorting"
            src={groepskorting}
          />
        </div>
        <div
          className={`flex-column col-6 d-flex ${classes.referenties_new_referer_text_wrapper}`}>
          <div className={`${classes.referenties_new_referer_title}`}>
            Meld een nieuwe ambassadeur aan.
          </div>
          <div
            className={`${classes.referenties_new_referer_body} ${classes.referenties_new_referer_text}`}>
            Elke ambassadeur die jij aanmeldt gaat voor jou aan de slag om je
            bedrijf te vertegenwoordigen.
          </div>
          <ModalButton
            cardWidth={isMobile ? '12' : '4'}
            modalBody={
              <AmbassadeurInviteModalBody
                closeAmbassadeurModal={() => setAmbassadeurModalIsOpen(false)}
              />
            }
            isOpen={ambassadeurModalIsOpen}>
            <AmbassadeurInviteButton
              setAmbassadeurModalIsOpen={setAmbassadeurModalIsOpen}
              ambassadeurModalIsOpen={ambassadeurModalIsOpen}
              isMobile={false}
            />
          </ModalButton>
        </div>
      </div>
    </Card>
  );
}
