import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ApiController } from '../../../../apiServices/calls/apiController';
import classes from '../../login-register.module.scss';
import AttensieLogoBlauw from '../../../../assets/png/Attensie_Logo_Blauw.png';
import AttensieLogoWit from '../../../../assets/svg/Attensie_Logo_Wit.svg';
import Groepskorting from '../../../../assets/svg/attensie-groepskorting.svg';
import { logoutAction } from '../../../../apiServices/actions/logout';

type VerifyTokenParams = {
  token: string;
  email: string;
};

export default function Success() {
  const params = useParams<VerifyTokenParams>();

  useEffect(() => {
    logoutAction();
    if (params.token && params.email) {
      Promise.all([
        ApiController.Identity.confirmEmail({
          email: params.email,
          token: params.token
        })
      ]);
    }
  }, [params.token, params.email]);

  const history = useHistory();
  return (
    <>
      <div className={classes.register}>
        <div className={`${classes.register_left}`}>
          <div className={`${classes.register_left_content}`}>
            <div className={classes.register_left_content_img}>
              <img
                className={classes.register_left_content_img_logo}
                src={AttensieLogoBlauw}
                alt="Attensie"
              />
            </div>
            <div className={`${classes.register_left_content_container}`}>
              <h1>Email bevestigd.</h1>
              <p
                className={
                  classes.register_left_content_container_message_info
                }>
                Je kunt nu aan de slag! Alleen nog even inloggen.
              </p>
              <button type="button" onClick={() => history.push('/login')}>
                INLOGGEN
              </button>
            </div>
          </div>
        </div>
        <div className={`${classes.register_right}`}>
          <img src={AttensieLogoWit} alt="Attensie" />
        </div>
      </div>
      <img
        className={`${classes.register_groepskorting}`}
        src={Groepskorting}
        alt="groepskorting"
      />
    </>
  );
}
