import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import { useHistory } from 'react-router-dom';
import classes from './admin-bedrijven.module.scss';
import Header from '../../../components/header/header';
import CardRow from '../../../components/card-row/card-row';
import Datagrid from '../../../components/datagrid/datagrid';
import useWindowDimensions from '../../../helpers/window-dimensions';
import { Column } from '../../../components/datagrid/gridinterface';
import LogoItem from '../../../components/logo-item/logo-item';
import { createInitialsFromFullName } from '../../../helpers/logo-helpers';
import Footer from '../../../components/footer/footer';
import UitloggenSVG from '../../../assets/svg/Uitloggen.svg';

import { CompaniesApiModel } from '../../../apiServices/models/api';
import { ApiController } from '../../../apiServices/calls/apiController';
import { updateAppState } from '../../../apiServices/appState';

export type BusinessesScreenStateType = {
  businesses: CompaniesApiModel[] | undefined;
  datagridData: BusinessDatagridType[];
  fetchData: boolean;
};

export type BusinessDatagridType = {
  name?: string | undefined;
  joinDate?: string | undefined;
  id?: string | undefined;
};

export default function AdminBedrijven() {
  const { isMobile } = useWindowDimensions();

  const [screenState, setScreenSate] = useState<BusinessesScreenStateType>({
    businesses: undefined,
    datagridData: [],
    fetchData: true
  });
  const history = useHistory();

  const handleClick = (companyId: string, companyName: string) => {
    updateAppState((state) => {
      state.adminDetails.companyId = companyId;
      state.companyDetails.companyName = companyName;
    });

    history.push('/organisaties/klanten');
  };

  const exportAttensies = () => {
    // eslint-disable-next-line consistent-return
    return Promise.all([ApiController.Admin.export()]).then((data): void => {
      saveAs(data[0], 'export.csv');
      let exportedFile;
      if (data) {
        return exportedFile;
      }
    });
  };

  useEffect(() => {
    if (!screenState.businesses || screenState.fetchData) {
      Promise.all([ApiController.Admin.getBusinesses()]).then((data): void => {
        if (data[0]) {
          const businesses = data[0].backofficeCompanyApiModels || [];
          const datagridData: BusinessDatagridType[] = [];

          businesses.forEach((business) => {
            datagridData.push({
              joinDate: business?.joinDate
                ? new Date(business.joinDate).toLocaleDateString()
                : '',
              name: business.name || '',
              id: business.id
            });
          });

          setScreenSate({
            businesses,
            datagridData,
            fetchData: false
          });
        }
      });
    }
  }, [screenState, screenState.fetchData]);

  const columnHeaders: Column[] = [
    {
      id: 'name',
      label: 'Bedrijven',
      active: true,
      offset: 'L',
      offsetSize: 0,
      component: (c) => (
        <div className={classes.logo_height_fix}>
          <LogoItem
            logo={createInitialsFromFullName(c.value.name)}
            name={c.value.name}
          />
        </div>
      )
    },
    {
      id: 'joinDate',
      label: 'Datum aanmelding',
      active: false,
      offset: 'S',
      offsetSize: 0
    },
    {
      id: 'id',
      label: '',
      active: false,
      offset: 'S',
      offsetSize: 0,
      component: (c) => (
        <div
          style={{ textDecoration: 'none' }}
          className={`d-flex ${classes.referenties_cta}`}
          onClick={() => handleClick(c.value.id, c.value.name)}>
          <p>Log in</p>
          <img src={UitloggenSVG} alt="img" />
        </div>
      )
    }
  ];

  return (
    <div className={`flex-column ${classes.referenties_container}`}>
      <Header subtitle="Welkom terug!" />
      <CardRow isMobile={isMobile}>
        <Datagrid
          backgroundColor={null}
          col={isMobile ? '12' : '8'}
          columns={columnHeaders}
          data={screenState.datagridData}
        />
      </CardRow>
      <CardRow isMobile={false}>
        <button type="button" onClick={() => exportAttensies()}>
          EXPORT ATTENSIES
        </button>
      </CardRow>
      <Footer />
    </div>
  );
}
