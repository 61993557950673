import React, { useRef, useState } from 'react';
import InviteButton from '../../../components/button/invite-button';
import classes from '../klanten.module.scss';
import helpendeHand from '../../../assets/svg/helpendehand.svg';
import sluit from '../../../assets/png/close_icon.png';
import { ApiController } from '../../../apiServices/calls/apiController';
import { AppState, useAppState } from '../../../apiServices/appState';

type AmbassadeurCardModalBodyProps = {
  closeAmbassadeurModal: () => void;
  closeModal?: () => void;
};

type AmbassadeurCardModalBodyState = {
  copied: boolean;
};

export enum AmbassadeurInviteModalSteps {
  stepOne,
  success
}

const selector = (state: AppState) => ({
  companyDetails: state.companyDetails
});

export default function AmbassadeurInviteModalBody({
  closeModal,
  closeAmbassadeurModal
}: AmbassadeurCardModalBodyProps) {
  const { companyDetails } = useAppState(selector).current;
  const [modalBodyState, setModalBodyState] =
    useState<AmbassadeurCardModalBodyState>({
      copied: false
    });
  const [modalStep, setModalStep] = useState(
    AmbassadeurInviteModalSteps.stepOne
  );
  const [ambassadeurInfo, setAmbassadeurInfo] = useState({
    name: '',
    email: ''
  });
  const textAreaRef = useRef(null);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setAmbassadeurInfo({ ...ambassadeurInfo, [name]: value });
  };

  const renderBody = (step: AmbassadeurInviteModalSteps) => {
    switch (step) {
      case AmbassadeurInviteModalSteps.stepOne:
        return (
          <>
            <div className={classes.amb_invite_modal_body_input}>
              <span>Naam:</span>
              <input
                type="text"
                name="name"
                placeholder="Voor- en achternaam"
                onChange={onChange}
              />
            </div>
            <div className={classes.amb_invite_modal_body_input}>
              <span>E-mail:</span>
              <input
                type="email"
                name="email"
                placeholder="email@domein.nl"
                onChange={onChange}
              />
            </div>
            <div className={classes.amb_invite_modal_body_spacer}>
              <span>Of</span>
            </div>
            <div className={classes.amb_invite_modal_body_input}>
              <span>Uitnodigen met een URL:</span>
              <input
                type="text"
                id="inviteLink"
                onClick={() => {
                  const text = document.getElementById(
                    'inviteLink'
                  ) as HTMLInputElement;

                  if (text) {
                    text.select();
                    document.execCommand('copy');
                    setModalBodyState({
                      copied: true
                    });
                    setTimeout(() => {
                      setModalBodyState({
                        copied: false
                      });
                    }, 3000);
                  }
                }}
                readOnly
                style={{ cursor: 'pointer' }}
                ref={textAreaRef}
                value={`${window.location.origin.toString()}/klant/registreer/${
                  companyDetails.id
                }`}
              />
              <div className={`${classes.amb_invite_modal_copied_wrapper}`}>
                {modalBodyState.copied && (
                  <span className={`${classes.amb_invite_modal_copied}`}>
                    Gekopieerd!
                  </span>
                )}
              </div>

              <p>
                Elke ambassadeur die wordt uitgenodigd met de onderstaande link
                wordt gekoppeld aan je bedrijf.
              </p>
            </div>
          </>
        );
      case AmbassadeurInviteModalSteps.success:
        return (
          <div className={`${classes.amb_invite_modal_success_wrapper}`}>
            <div>
              <img src={helpendeHand} alt="succes_image" />
            </div>
            <div className={`${classes.amb_invite_modal_success_title}`}>
              <span>De ambassadeur is uitgenodigd!</span>
            </div>
            <div className={`${classes.amb_invite_modal_success_subtitle}`}>
              <span>
                Wanneer de ambassadeur een account aanmaakt wordt deze getoond
                bij je ambassadeurs.
              </span>
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div
      className={`d-flex flex-column ${classes.amb_invite_modal} ${
        modalStep === AmbassadeurInviteModalSteps.success
          ? classes.amb_invite_modal_success
          : ''
      }`}>
      {modalStep === AmbassadeurInviteModalSteps.stepOne && (
        <div className={classes.amb_invite_modal_header}>
          <span className={classes.amb_invite_modal_header_title}>
            Ambassadeurs uitnodigen
          </span>
          <span className={classes.amb_invite_modal_header_subtitle}>
            Deel de link of nodig de Ambassadeur uit via de email.
          </span>
          <img
            src={sluit}
            alt="sluit"
            className={classes.ambassadeur_info_modal_header_close}
            onClick={() => {
              if (closeModal) {
                closeModal();
              }
              closeAmbassadeurModal();
            }}
          />
        </div>
      )}
      <div className={classes.amb_invite_modal_body}>
        {renderBody(modalStep)}
      </div>
      <div
        className={
          ambassadeurInfo.name && ambassadeurInfo.email
            ? classes.amb_invite_modal_footer
            : `${classes.amb_invite_modal_footer} ${classes.amb_invite_modal_footer_disabled}`
        }>
        <InviteButton
          text={
            modalStep === AmbassadeurInviteModalSteps.success
              ? 'AFRONDEN'
              : 'STUUR UITNODIGING'
          }
          func={(): void | null => {
            if (
              modalStep === AmbassadeurInviteModalSteps.success &&
              closeModal
            ) {
              setModalStep(AmbassadeurInviteModalSteps.stepOne);
              setAmbassadeurInfo({
                email: '',
                name: ''
              });
              return closeModal();
            }

            if (
              ambassadeurInfo.name.length > 0 &&
              ambassadeurInfo.email.length > 0
            ) {
              ApiController.Company.createCompanyInvite(
                ambassadeurInfo.name,
                ambassadeurInfo.email
              ).then(() => setModalStep(AmbassadeurInviteModalSteps.success));
            }
            return null;
          }}
          isMobile={false}
        />
      </div>
    </div>
  );
}
