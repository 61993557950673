import React from 'react';
import InviteButton from '../../../components/button/invite-button';
import useWindowDimensions from '../../../helpers/window-dimensions';
import classes from '../doelen.module.scss';

type DoelenCardModalButtonProps = {
  openModal?: () => void;
  setDoelenModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  doelenModalIsOpen: boolean;
};

export default function DoelenModalButton({
  openModal,
  setDoelenModalIsOpen,
  doelenModalIsOpen
}: DoelenCardModalButtonProps) {
  const { isMobile } = useWindowDimensions();
  return (
    <InviteButton
      text="TIP AANMAKEN"
      func={() => {
        if (openModal) {
          openModal();
        }
        setDoelenModalIsOpen(!doelenModalIsOpen);
      }}
      isMobile={isMobile}
      extraClasses={classes.gegevens_doelen_button}
      col="12"
    />
  );
}
