// all single user related calls

import { AuthController } from './controllers/auth';
import { CompanyController } from './controllers/company';
import { CreditController } from './controllers/credit';
import { CustomerController } from './controllers/customer';
import { IdentityController } from './controllers/identity';
import { ReferenceController } from './controllers/reference';
import { AdminController } from './controllers/admin';

export class ApiController {
  constructor() {
    throw Error(
      'APIcontroller should not initialize, try using it like this => ApiController.Auth'
    );
  }

  static Auth = AuthController;

  static Credit = CreditController;

  static Company = CompanyController;

  static Reference = ReferenceController;

  static Customer = CustomerController;

  static Identity = IdentityController;

  static Admin = AdminController;
}
