import React from 'react';
import classes from '../klanten.module.scss';

type AmbassadeurInfoModalButtonProps = {
  openModal?: () => void;
  setAmbassadeurModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  ambassadeurModalIsOpen: boolean;
  isMobile: boolean;
};

export default function AmbassadeurInviteButton({
  openModal,
  setAmbassadeurModalIsOpen,
  ambassadeurModalIsOpen,
  isMobile
}: AmbassadeurInfoModalButtonProps) {
  return (
    <div
      className={`${classes.referenties_new_referer_button} ${
        isMobile ? classes.referenties_invite_button : ''
      }`}
      onClick={() => {
        if (openModal !== undefined) {
          openModal();
        }
        setAmbassadeurModalIsOpen(!ambassadeurModalIsOpen);
      }}>
      NODIG AMBASSADEUR UIT
    </div>
  );
}
