import React, { ReactNode } from 'react';
import classes from './referentie-card-modal.module.scss';
import {
  ReferentieModalBodyState,
  ReferentieModalSteps
} from './referentie-card-modal-body';
import helpendeHand from '../../../../assets/svg/helpendehand.svg';
import Input, { InputTypes } from '../../../../components/input/Input';

type ReferentieBodyProps = {
  setBodyState: React.Dispatch<React.SetStateAction<ReferentieModalBodyState>>;
  currentStep: ReferentieModalSteps;
  bodyState: ReferentieModalBodyState;
};

export const RenderBody = ({
  currentStep,
  setBodyState,
  bodyState
}: ReferentieBodyProps): ReactNode => {
  switch (currentStep) {
    case ReferentieModalSteps.stepOne:
      return (
        <>
          <div className={classes.referentie_modal_select_wrapper}>
            <span>Het bedrijf:</span>
            <select
              onChange={(e) => {
                const index = Number(e.currentTarget.value);
                const selected = bodyState?.companies
                  ? bodyState.companies[index]
                  : null;

                if (selected) {
                  setBodyState({
                    ...bodyState,
                    companyId: selected.companyId,
                    intentionId: selected.intentions
                      ? selected.intentions[0].id
                      : undefined,
                    intentions: selected.intentions ? selected.intentions : []
                  });
                }
              }}>
              {bodyState.companies?.map((company, index) => {
                return <option value={index}>{company.companyName}</option>;
              })}
            </select>
          </div>
          {bodyState.companyId && (
            <div className={classes.referentie_modal_select_wrapper}>
              <span>Het doel:</span>
              <select
                onChange={(e) => {
                  setBodyState({
                    ...bodyState,
                    intentionId: e.currentTarget.value
                  });
                }}>
                {bodyState.intentions?.map((intention) => {
                  return (
                    <option value={intention.id}>
                      {`${intention.name} €${intention.reward}`}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
        </>
      );
    case ReferentieModalSteps.stepTwo:
      return (
        <>
          <div className={classes.referentie_modal_form}>
            <div className={classes.referentie_modal_form_input_row}>
              <span>Naam:</span>
              <Input
                name="naam"
                onChange={(e) =>
                  setBodyState({ ...bodyState, name: e.currentTarget.value })
                }
                placeholder="Naam"
                type={InputTypes.text}
              />
            </div>
            <div className={classes.referentie_modal_form_input_row}>
              <span>E-mail:</span>
              <Input
                name="email"
                onChange={(e) =>
                  setBodyState({ ...bodyState, email: e.currentTarget.value })
                }
                placeholder="E-mail"
                type={InputTypes.email}
              />
            </div>
            <div className={classes.referentie_modal_form_input_row}>
              <span>Telefoonnummer:</span>
              <Input
                name="telefoonnummer"
                onChange={(e) =>
                  setBodyState({ ...bodyState, tel: e.currentTarget.value })
                }
                placeholder="Telefoonnummer"
                type={InputTypes.number}
              />
            </div>
            <div
              className={`${classes.referentie_modal_form_input_row} ${classes.referentie_modal_form_input_row_last} `}>
              <span>Initiatief ligt bij:</span>
              <div
                className={`d-flex align-items-center ${classes.referentie_modal_form_input_radio_wrapper}`}>
                <input
                  className={classes.referentie_modal_form_radio}
                  type="radio"
                  radioGroup="initiatief"
                  name="initiatief"
                  aria-label="Radio button for following text input"
                  onClick={() =>
                    setBodyState({ ...bodyState, mayBeContacted: false })
                  }
                />
                <span
                  className={` ${classes.referentie_modal_form_input_radio}`}>
                  Deze persoon benadert jullie zelf
                </span>
              </div>
              <div
                className={`d-flex align-items-center ${classes.referentie_modal_form_radio_wrapper}`}>
                <input
                  className={classes.referentie_modal_form_radio}
                  type="radio"
                  radioGroup="initiatief"
                  name="initiatief"
                  aria-label="Radio button for following text input"
                  onClick={() =>
                    setBodyState({ ...bodyState, mayBeContacted: true })
                  }
                />
                <span
                  className={` ${classes.referentie_modal_form_input_radio}`}>
                  Jullie mogen contact opnemen
                </span>
              </div>
            </div>
            <div className={`${classes.referentie_modal_form_permission}`}>
              <input type="checkbox" />
              <span className={`${classes.referentie_modal_form_text}`}>
                <span
                  className={`${classes.referentie_modal_form_text_underline}`}>
                  <a
                    className={`${classes.referentie_modal_form_text}`}
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.attensie.nl/algemene-voorwaarden">
                    Ik heb toestemming
                  </a>
                </span>
                &nbsp;van deze persoon om hem op te geven als referentie
              </span>
            </div>
          </div>
        </>
      );
    case ReferentieModalSteps.success:
      return (
        <div className={`${classes.referentie_modal_success_wrapper}`}>
          <div>
            <img src={`${helpendeHand}`} alt="succes_image" />
          </div>
          <div className={`${classes.referentie_modal_success_title}`}>
            <span>Je referentie is aangemeld!</span>
          </div>
          <div className={`${classes.referentie_modal_success_subtitle}`}>
            <span>
              Je referentie heeft een uitnodiging gehad en het bedrijf is op de
              hoogte gesteld.
            </span>
          </div>
        </div>
      );
    default:
      return <></>;
  }
};
