import React from 'react';
import { useHistory } from 'react-router-dom';
import classes from '../dashboard.module.scss';
import Card from '../../../components/card/card';

type CurrentCompaniesCardProps = {
  isMobile: boolean;
  title: string;
  showLink: boolean;
  amount: number | undefined;
  subTitle: string;
  height?: string | undefined;
  col: '2' | '4' | '6' | '8' | '10' | '12';
};

export default function EarningsCard({
  isMobile,
  title,
  showLink,
  amount,
  subTitle,
  col,
  height
}: CurrentCompaniesCardProps) {
  const history = useHistory();

  return (
    <Card
      backgroundColor={null}
      col={isMobile ? '12' : col}
      height={height ?? null}>
      <div
        className={`d-flex flex-column ${classes.dashboard_earnings_container}`}>
        <div className={`d-flex ${classes.dashboard_earnings_wrapper}`}>
          <div
            className={`${classes.dashboard_earnings_text} ${classes.dashboard_earnings_header}`}>
            <span>{title}</span>
          </div>
          <div
            className={`${classes.dashboard_earnings_text} ${classes.dashboard_earnings_money}`}>
            <span> €{amount}</span>
          </div>
          <div
            className={`${classes.dashboard_earnings_text}${classes.dashboard_earnings_subtitle}`}>
            <span>{subTitle}</span>
          </div>
        </div>
        {showLink && (
          <div className={`${classes.dashboard_earnings_link}`}>
            <span onClick={() => history.push('/beloningen')}>
              Bekijk sparen {'>'}
            </span>
          </div>
        )}
      </div>
    </Card>
  );
}
