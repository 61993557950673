import React from 'react';
import classes from './footer.module.scss';

export default function Footer() {
  return (
    <p className={classes.footer}>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.attensie.nl/algemene-voorwaarden">
        Privacy Policy
      </a>
      &nbsp;en de&nbsp;
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.attensie.nl/algemene-voorwaarden">
        Algemene Voorwaarden
      </a>
    </p>
  );
}
