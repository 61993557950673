import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import classes from '../account/account.module.scss';

import Header from '../../components/header/header';
import CardRow from '../../components/card-row/card-row';
import useWindowDimensions from '../../helpers/window-dimensions';
import ChangeAccountCard, { DetailsType } from '../account/change-account-card';
import { InputTypes } from '../../components/input/Input';
import Card from '../../components/card/card';
import Footer from '../../components/footer/footer';
import ChangeSettingsCard from './change-settings-card';
import {
  AppState,
  updateAppState,
  useAppState
} from '../../apiServices/appState';
import { ApiController } from '../../apiServices/calls/apiController';
import { Roles } from '../../router/router';

export default function B2bAccount() {
  const { isMobile } = useWindowDimensions();
  const selector = (state: AppState) => ({
    role: state.authentication.accessToken?.role,
    companyDetails: state.companyDetails,
    companyId: state.adminDetails.companyId
  });
  const { role, companyId } = useAppState(selector).current;

  useEffect(() => {
    if (role === Roles.AttensieAdmin && companyId) {
      const setCompanyDetails = (
        companyName: string,
        name: string,
        email: string,
        id: string,
        street: string,
        houseNumber: string,
        numberAddition: string,
        postalCode: string,
        city: string,
        loading: boolean
      ) => {
        updateAppState((state) => {
          state.companyDetails.companyName = companyName;
          state.companyDetails.loading = loading;
          state.companyDetails.name = name;
          state.companyDetails.email = email;
          state.companyDetails.id = id;
          state.companyDetails.addressStreet = street;
          state.companyDetails.addressNumber = houseNumber;
          state.companyDetails.addressAddition = numberAddition;
          state.companyDetails.addressPostalCode = postalCode;
          state.companyDetails.addressCity = city;
        });
      };

      Promise.all([ApiController.Identity.getDetails(companyId)]).then(
        (data) => {
          if (data[0]) {
            setCompanyDetails(
              data[0].name || '',
              data[0].contactFullName || '',
              data[0].email || '',
              data[0].id || '',
              data[0].addressStreet || '',
              data[0].addressNumber || '',
              data[0].addressAddition || '',
              data[0].addressPostalCode || '',
              data[0].addressCity || '',
              false
            );
          }
        }
      );
    }
  }, [role, companyId]);

  return (
    <div className={`flex-column ${classes.account_container}`}>
      <Header subtitle="Je profieloverzicht" />
      <div>
        <CardRow isMobile={isMobile}>
          <ChangeSettingsCard
            col="4"
            isMobile={isMobile}
            height="850px"
            title="Je profielgegevens"
            buttonText="WIJZIG GEGEVENS"
            disabled={role === Roles.AttensieAdmin}
          />
          <ChangeAccountCard
            col="4"
            isMobile={isMobile}
            height="420px"
            title="Wachtwoord wijzigen"
            firstValue="currentPassword"
            firstLabel="Huidig wachtwoord:"
            firstPlaceholder="***************"
            secondValue="newPassword"
            secondLabel="Nieuw wachtwoord:"
            secondPlaceholder="Typ hier je nieuwe wachtwoord"
            buttonText="WIJZIG WACHTWOORD"
            typeOne={InputTypes.password}
            typeTwo={InputTypes.password}
            detailsType={DetailsType.password}
            disabled={role === Roles.AttensieAdmin}
          />
          <Card col="4" height="0px" backgroundColor="rgba(72, 107, 147, 0)">
            {}
          </Card>
        </CardRow>
        <CardRow isMobile={isMobile}>
          <Link
            to="/opzeggen"
            style={{ color: '#9a9a9a', marginTop: '-1em' }}
            className="col-4">
            <p className={classes.account_cancel}>Account opzeggen</p>
          </Link>
        </CardRow>
      </div>
      <Footer />
    </div>
  );
}
