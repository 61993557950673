import React from 'react';
import classes from '../../dashboard.module.scss';

type ReferentieCardModalButtonProps = {
  openModal?: () => void;
  setReferentieModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ReferentieCardModalButton({
  openModal,
  setReferentieModalIsOpen
}: ReferentieCardModalButtonProps) {
  return (
    <div className={`${classes.dashboard_new_referer_button_container}`}>
      <div
        onClick={() => {
          if (openModal) {
            openModal();
          }
          setReferentieModalIsOpen(true);
        }}
        className={`${classes.dashboard_new_referer_button}`}>
        Tip een bekende
      </div>
    </div>
  );
}
