import React, { ReactElement, useEffect, useState } from 'react';
import classes from './klanten.module.scss';
import Header from '../../components/header/header';
import CardRow from '../../components/card-row/card-row';
import Datagrid from '../../components/datagrid/datagrid';
import useWindowDimensions from '../../helpers/window-dimensions';
import { CellInfo, Column } from '../../components/datagrid/gridinterface';
import AmbassadeurCard from './ambassadeur-card/ambassadeur-card';
import LogoItem from '../../components/logo-item/logo-item';
import { createInitialsFromFullName } from '../../helpers/logo-helpers';
import InviteButton from '../../components/button/invite-button';
import Footer from '../../components/footer/footer';
import ModalButton from '../../components/modal-button/modal-button';
import AmbassadeurCardModalBody from './amb-profile-modal/modal-body';
import AmbassadeurInfoButton from './amb-profile-modal/modal-button';
import { CompanyAmbassadorModel } from '../../apiServices/models/api';
import { ApiController } from '../../apiServices/calls/apiController';
import AmbassadeurInviteModalBody from './amb-invite-modal/modal-body';
import useCompanyId from '../../helpers/useCompanyId';

export type AmbasssadorsScreenStateType = {
  ambassadors: CompanyAmbassadorModel[] | undefined;
  datagridData: AmbassadorDatagridType[];
  fetchData: boolean;
};

export type AmbassadorDatagridType = {
  ambassadeur: string;
  email: string;
  aanmeldingsDatum: string;
  conversie: string;
  tel: string;
  id: string;
};

export default function B2bKlanten() {
  const { isMobile, isTablet } = useWindowDimensions();
  const [ambassadeurInviteModalIsOpen, setAmbassadeurInviteModalIsOpen] =
    useState(false);
  const [ambassadeurProfileModalIsOpen, setAmbassadeurProfileModalIsOpen] =
    useState(false);
  const [screenState, setScreenSate] = useState<AmbasssadorsScreenStateType>({
    ambassadors: undefined,
    datagridData: [],
    fetchData: true
  });
  const companyId = useCompanyId();

  useEffect(() => {
    if (!screenState.ambassadors || screenState.fetchData) {
      Promise.all([ApiController.Company.getAmbassadors(companyId)]).then(
        (data): void => {
          if (data[0]) {
            const ambassadors = data[0].ambassadors || [];
            const datagridData: AmbassadorDatagridType[] = [];

            ambassadors.forEach((ambassador) => {
              datagridData.push({
                aanmeldingsDatum: ambassador?.invitationDate
                  ? new Date(ambassador.invitationDate).toLocaleDateString()
                  : '',
                ambassadeur: ambassador.name || '',
                conversie: `€ ${ambassador.totalConversion}`,
                email: ambassador.email || '',
                id: ambassador.ambassadorId,
                tel: ambassador.phoneNumber || ''
              });
            });

            setScreenSate({
              ambassadors,
              datagridData,
              fetchData: false
            });
          }
        }
      );
    }
  }, [screenState, screenState.fetchData, companyId]);

  const createRowButton = (e: CellInfo): ReactElement => {
    return (
      <ModalButton
        cardWidth={isMobile ? '12' : '4'}
        modalBody={
          <AmbassadeurCardModalBody
            setScreenState={setScreenSate}
            screenState={screenState}
            closeModal={() => setAmbassadeurProfileModalIsOpen(false)}
            data={e.value as AmbassadorDatagridType}
          />
        }
        isOpen={ambassadeurProfileModalIsOpen}>
        <AmbassadeurInfoButton
          setAmbassadeurProfileModalIsOpen={setAmbassadeurProfileModalIsOpen}
          ambassadeurProfileModalIsOpen={ambassadeurProfileModalIsOpen}
        />
      </ModalButton>
    );
  };

  const columnHeaders: Column[] = [
    {
      id: 'ambassadeur',
      label: 'Ambassadeurs',
      active: true,
      offset: 'L',
      offsetSize: 1,
      component: (c) => (
        <div className={classes.logo_height_fix}>
          <LogoItem
            logo={createInitialsFromFullName(c.value.ambassadeur)}
            name={c.value.ambassadeur}
          />
        </div>
      )
    },
    { id: 'email', label: 'Email', active: false },
    {
      id: 'aanmeldingsDatum',
      label: 'Datum aanmelding',
      active: false,
      offset: 'S',
      offsetSize: 1
    },
    {
      id: 'conversie',
      label: 'Conversie',
      active: false,
      offset: 'S',
      offsetSize: 1
    }
  ];

  return (
    <div className={`flex-column ${classes.referenties_container}`}>
      <Header subtitle="Welkom terug!" />
      <CardRow isMobile={isMobile}>
        <Datagrid
          backgroundColor={null}
          col={isMobile ? '12' : '8'}
          columns={columnHeaders}
          data={screenState.datagridData}
          rowButton={(e) => createRowButton(e)}
        />
        {!isMobile && !isTablet && (
          <AmbassadeurCard
            height="510px"
            ambassadeurModalIsOpen={ambassadeurInviteModalIsOpen}
            setAmbassadeurModalIsOpen={setAmbassadeurInviteModalIsOpen}
            isMobile={isMobile}
          />
        )}
      </CardRow>
      {(isMobile || isTablet) && (
        <ModalButton
          cardWidth={isMobile ? '12' : '4'}
          modalBody={
            <AmbassadeurInviteModalBody
              closeAmbassadeurModal={() =>
                setAmbassadeurInviteModalIsOpen(false)
              }
            />
          }
          isOpen={ambassadeurInviteModalIsOpen}>
          <InviteButton
            text="NODIG AMBASSADEUR UIT"
            func={() => setAmbassadeurInviteModalIsOpen(true)}
            isMobile={isMobile}
            col="4"
          />
        </ModalButton>
      )}
      <Footer />
    </div>
  );
}
