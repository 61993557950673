import React, { ReactNode } from 'react';
import classes from './redeem-card-modal.module.scss';
import { RedeemModalSteps } from './redeem-card-modal-body';
import helpendeHand from '../../../../assets/svg/helpendehand.svg';
import redeemClasses from '../../sparen.module.scss';

export const renderBody = (
  currentStep: RedeemModalSteps,
  image: string,
  altText: string
): ReactNode => {
  switch (currentStep) {
    case RedeemModalSteps.stepOne:
      return (
        <div className={`${classes.redeem_modal_image_wrapper}`}>
          <div className={`${redeemClasses.sparen_redeem_image}`}>
            <img
              className={`${redeemClasses.sparen_redeem_image}`}
              src={`${image}`}
              alt={altText}
            />
          </div>
        </div>
      );
    case RedeemModalSteps.success:
      return (
        <div className={`${classes.redeem_modal_success_wrapper}`}>
          <div>
            <img src={`${helpendeHand}`} alt="succes_image" />
          </div>
          <div className={`${classes.redeem_modal_success_title}`}>
            <span>Je code is verzilverd!</span>
          </div>
          <div className={`${classes.redeem_modal_success_subtitle}`}>
            <span>
              Je krijgt binnenkort een mailtje/brief met daar in de code.
            </span>
          </div>
        </div>
      );
    default:
      return <></>;
  }
};
