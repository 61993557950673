import React from 'react';
import Input, {
  InputClassNameType,
  InputTypes
} from '../../../../components/input/Input';
import classes from '../../login-register.module.scss';
import { RegisterForm } from './register-company';

type RegisterTwoProps = {
  registerForm: RegisterForm;
  handleFormChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCompanyRegister: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
};

export default function RegisterTwo({
  registerForm,
  handleFormChange,
  handleCompanyRegister
}: RegisterTwoProps) {
  const { name, street, number, addition, zip, city, error } = registerForm;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    return handleCompanyRegister(e);
  };

  return (
    <>
      <p className={`${classes.register_left_content_container_steps_two}`}>
        Stap 2 van 2
      </p>
      <h1>Welkom bij Attensie! Laten we je profiel aanvullen.</h1>
      <form
        className={`d-flex flex-column ${classes.register_left_content_container_form}`}
        onSubmit={(e) => handleSubmit(e)}>
        <Input
          type={InputTypes.text}
          placeholder="Volledige naam"
          name="name"
          value={name}
          onChange={(e) => handleFormChange(e)}
          required
        />
        <Input
          type={InputTypes.text}
          placeholder="Straatnaam"
          name="street"
          value={street}
          onChange={(e) => handleFormChange(e)}
          required
        />
        <div className={classes.register_left_content_container_form_row}>
          <Input
            type={InputTypes.number}
            placeholder="Huisnummer"
            classNameType={InputClassNameType.smallFirst}
            name="number"
            value={number}
            onChange={(e) => handleFormChange(e)}
            required
          />
          <Input
            type={InputTypes.text}
            placeholder="Toevoeging"
            classNameType={InputClassNameType.small}
            name="addition"
            value={addition}
            onChange={(e) => handleFormChange(e)}
          />
        </div>
        <Input
          type={InputTypes.text}
          placeholder="Postcode"
          name="zip"
          value={zip}
          onChange={(e) => handleFormChange(e)}
          required
        />
        <Input
          type={InputTypes.text}
          placeholder="Plaats"
          name="city"
          value={city}
          onChange={(e) => handleFormChange(e)}
          required
        />
        {error && error.length !== 0 && (
          <div>
            <span style={{ color: 'red' }}>{error}</span>
          </div>
        )}
        <div
          className={`d-flex display-row align-content-baseline ${classes.register_left_content_container_form_agree}`}>
          <input
            type="checkbox"
            className={
              classes.register_left_content_container_form_agree_checkbox
            }
            required
          />
          <p>
            Ik geef toestemming dat Attensie mijn gegevens bewaart en verwerkt,
            ik ga akkoord met de algemene voorwaarden en dat Attensie mij
            maandelijks een factuur stuurt in het hiervoor gegeven e-mailadres.
          </p>
        </div>
        <button type="submit">AFRONDEN</button>
      </form>

      <p className={`${classes.register_left_content_container_terms_two}`}>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.attensie.nl/algemene-voorwaarden">
          Privacy Policy
        </a>
        &nbsp;en de&nbsp;
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.attensie.nl/algemene-voorwaarden">
          Algemene Voorwaarden
        </a>
      </p>
    </>
  );
}
