import React, { ReactNode, useEffect, useState } from 'react';
import { ApiController } from '../../apiServices/calls/apiController';
import { CreditType } from '../../apiServices/calls/controllers/credit';
import {
  CustomerReferenceModel,
  EmbassyApiModel
} from '../../apiServices/models/api';
import CardRow from '../../components/card-row/card-row';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import useWindowDimensions from '../../helpers/window-dimensions';
import CurrentCompaniesCard from './current-companies-card/current-companies-card';
import CurrentReferentiesCard from './current-referenties-card/current-referenties-card';
import classes from './dashboard.module.scss';
import EarningsCard from './earnings-card/earnings-card';
import InfoCard from './info-card/info-card';
import ReferentieCard from './referentie-card/referentie-card';

type DashboardStateType = {
  monthlyCredit: CreditType | undefined;
  embassies: EmbassyApiModel[] | undefined;
  latestReferences: CustomerReferenceModel[] | undefined;
};

export default function Dashboard() {
  const { isMobile } = useWindowDimensions();
  const [referentieModalIsOpen, setReferentieModalIsOpen] = useState(false);
  const [screenState, setScreenState] = useState<DashboardStateType>({
    monthlyCredit: undefined,
    embassies: undefined,
    latestReferences: undefined
  });

  useEffect(() => {
    const currentUrlId = sessionStorage.getItem('currentUrlId');
    if (currentUrlId) {
      ApiController.Customer.redeemRewardLink(currentUrlId);
    }
    if (
      !screenState.monthlyCredit ||
      !screenState.latestReferences ||
      !screenState.embassies
    ) {
      Promise.all([
        ApiController.Credit.getCredit(),
        ApiController.Reference.getLatestCustomerReferences(3),
        ApiController.Customer.getEmbassies()
      ]).then((data) => {
        if (data[0]) {
          setScreenState({
            monthlyCredit: data[0],
            latestReferences: data[1].references,
            embassies: data[2].embassies
          });
        }
      });
    }
  }, [screenState]);

  const determineCardOrder = (): ReactNode => {
    if (isMobile) {
      return (
        <div className="p-0">
          <CurrentReferentiesCard
            latestReferences={
              screenState.latestReferences ? screenState.latestReferences : []
            }
            isMobile={isMobile}
          />
          <InfoCard isMobile={isMobile} />
          <EarningsCard
            col="4"
            isMobile={isMobile}
            title="Het totaal van je beloningen:"
            amount={screenState.monthlyCredit?.totalAvailable ?? 0}
            showLink
            subTitle="Wissel je beloningen in voor de leukste giftcards"
          />
          <CurrentCompaniesCard
            embassies={screenState.embassies ? screenState.embassies : []}
            isMobile={isMobile}
          />
        </div>
      );
    }

    return (
      <>
        <EarningsCard
          isMobile={isMobile}
          title="Het totaal van je beloningen:"
          col="4"
          amount={screenState.monthlyCredit?.totalAvailable ?? 0}
          showLink
          subTitle="Wissel je beloningen in voor de leukste giftcards"
        />
        <CurrentReferentiesCard
          latestReferences={
            screenState.latestReferences ? screenState.latestReferences : []
          }
          isMobile={isMobile}
        />
        <CurrentCompaniesCard
          embassies={screenState.embassies ? screenState.embassies : []}
          isMobile={isMobile}
        />
        <InfoCard isMobile={isMobile} />
      </>
    );
  };

  return (
    <div className={`flex-column ${classes.dashboard_container}`}>
      <Header subtitle="Welkom Terug!" />
      <div>
        <CardRow isMobile={isMobile}>
          <ReferentieCard
            isMobile={isMobile}
            referentieModalIsOpen={referentieModalIsOpen}
            setReferentieModalIsOpen={setReferentieModalIsOpen}
            vertical={!isMobile}
          />
          {determineCardOrder()}
        </CardRow>
      </div>
      <Footer />
    </div>
  );
}
