import React from 'react';
import RouteItem from '../route-item';
import AccountSVG from '../../assets/svg/Account-Symbol.svg';
import ReferentiesSVG from '../../assets/svg/Referenties-Symbol.svg';
import GegevensSVG from '../../assets/svg/Gegevens.svg';
import KlantenSVG from '../../assets/svg/Klanten.svg';

export enum RoutesB2B {
  acties = '/organisaties/acties',
  klanten = '/organisaties/klanten',
  referenties = '/organisaties/referenties',
  uitgiftepunten = '/organisaties/uitgiftepunten',
  klantreis = '/organisaties/klantreis',
  doelen = '/organisaties/doelen',
  profiel = '/organisaties/profiel'
}

type SidebarBodyProps = {
  currentRoute: string;
};
export default function OrganisationSidebarBody({
  currentRoute
}: SidebarBodyProps) {
  return (
    <div>
      <RouteItem
        logo={KlantenSVG}
        routeName="Ambassadeurs"
        route="/organisaties/klanten"
        active={currentRoute === RoutesB2B.klanten}
      />
      <RouteItem
        logo={ReferentiesSVG}
        routeName="Referenties"
        route="/organisaties/referenties"
        active={currentRoute === RoutesB2B.referenties}
      />
      <RouteItem
        logo={GegevensSVG}
        routeName="Tips & Beloningen"
        route="/organisaties/doelen"
        active={currentRoute === RoutesB2B.doelen}
      />
      <RouteItem
        logo={AccountSVG}
        routeName="Profiel"
        route="/organisaties/profiel"
        active={currentRoute === RoutesB2B.profiel}
      />
    </div>
  );
}
