import React, { useEffect, useState } from 'react';
import classes from './referentie-card-modal.module.scss';
import { renderHeader } from './render-headers';
import { RenderBody } from './render-bodies';
import { renderFooter } from './render-footers';
import {
  CompanyIntentionsApiModel,
  IntentionApiModel
} from '../../../../apiServices/models/api';
import { ApiController } from '../../../../apiServices/calls/apiController';

type ReferentieCardModalBodyProps = {
  closeModal?: () => void;
  onSaveCallback?: () => Promise<void> | undefined;
};

export type ReferentieModalBodyState = {
  companyId: string | undefined;
  intentionId: string | undefined;
  name: string | undefined;
  email: string | undefined;
  tel: string | undefined;
  mayBeContacted: boolean | undefined;
  companies: CompanyIntentionsApiModel[] | undefined;
  intentions: IntentionApiModel[] | undefined;
};

export enum ReferentieModalSteps {
  stepOne,
  stepTwo,
  success
}

export default function ReferentieCardModalBody({
  closeModal,
  onSaveCallback
}: ReferentieCardModalBodyProps) {
  const [modalStep, setModalStep] = useState(ReferentieModalSteps.stepOne);
  const [modalBodyState, setModalBodyState] =
    useState<ReferentieModalBodyState>({
      companies: undefined,
      companyId: undefined,
      email: undefined,
      intentionId: undefined,
      intentions: undefined,
      mayBeContacted: undefined,
      name: undefined,
      tel: undefined
    });

  useEffect(() => {
    if (!modalBodyState.companies) {
      Promise.all([ApiController.Customer.getIntentions()]).then((data) => {
        if (data[0]) {
          setModalBodyState({
            ...modalBodyState,
            companyId: data.find(() => true)?.companies?.find(() => true)
              ?.companyId,
            companies: data.find(() => true)?.companies,
            intentions: data.find(() => true)?.companies?.find(() => true)
              ?.intentions,
            intentionId: data
              .find(() => true)
              ?.companies?.find(() => true)
              ?.intentions?.find(() => true)?.id
          });
        }
      });
    }
  }, [modalBodyState]);

  return (
    <div
      className={`d-flex flex-column ${classes.referentie_modal} ${
        modalStep === ReferentieModalSteps.success
          ? classes.referentie_modal_success
          : ''
      }`}>
      {(modalStep === ReferentieModalSteps.stepOne ||
        modalStep === ReferentieModalSteps.stepTwo) && (
        <div className={classes.referentie_modal_header}>
          {renderHeader(modalStep, closeModal)}
        </div>
      )}
      <div className={classes.referentie_modal_body}>
        {RenderBody({
          currentStep: modalStep,
          bodyState: modalBodyState,
          setBodyState: setModalBodyState
        })}
      </div>
      <div className={classes.referentie_modal_footer}>
        {renderFooter(
          modalStep,
          setModalStep,
          closeModal,
          modalBodyState,
          onSaveCallback
        )}
      </div>
    </div>
  );
}
