import React from 'react';
import { AppState, useAppState } from '../../apiServices/appState';
import { Roles } from '../../router/router';
import classes from './header.module.scss';

type HeaderProps = {
  subtitle: string;
};

const selector = (state: AppState) => ({
  userDetails: state.userDetails,
  companyDetails: state.companyDetails,
  role: state.authentication.accessToken?.role
});

export default function Header({ subtitle }: HeaderProps) {
  const { userDetails, companyDetails, role } = useAppState(selector).current;
  return (
    <div className={`col-12 column ${classes.header}`}>
      <div>
        {role === Roles.AttensieAdmin && companyDetails.companyName ? (
          <span
            className={`${classes.header_name}`}>{`Ingelogd Business Dashboard van ${companyDetails.companyName}`}</span>
        ) : (
          <span className={`${classes.header_name}`}>
            {`Hey ${
              userDetails.loading
                ? companyDetails.loading
                  ? ''
                  : companyDetails.companyName
                : userDetails.name
            },`}
          </span>
        )}
      </div>
      <div>
        <span className={`${classes.header_subtitle}`}>{subtitle}</span>
      </div>
    </div>
  );
}
