import {
  InviteUrlModel,
  IntentionApiModel,
  Status,
  CompanyAmbassadorsListModel,
  IntentionListApiModel,
  CreateRewardLinkModel
} from '../../models/api';
import { http } from '../http';

export interface CompanyReferenceModelWithStringStatus {
  id?: string;
  name?: string;
  email?: string;
  phoneNumber?: string;
  referralDate?: Date;
  OriginalStatus?: Status;
  status?: string;
  refereeName?: string;
  intention?: IntentionApiModel;
}

export class CompanyController {
  static getCompanyIntentions = async (
    companyId?: string
  ): Promise<IntentionListApiModel> =>
    http<IntentionListApiModel>(
      `${process.env.REACT_APP_SERVER_URL}company/intentions${
        companyId ? `?companyId= ${companyId}` : ''
      }`
    );

  static getCompanyRewards = async (
    companyId?: string
  ): Promise<IntentionListApiModel> =>
    http<IntentionListApiModel>(
      `${process.env.REACT_APP_SERVER_URL}company/rewards${
        companyId ? `?companyId= ${companyId}` : ''
      }`
    );

  static createCompanyIntention = (
    name: string,
    reward: number,
    companyId: string | undefined
  ) =>
    http(
      `${process.env.REACT_APP_SERVER_URL}company/createintention${
        companyId ? `?companyId= ${companyId}` : ''
      }`,
      {
        method: 'POST',
        body: JSON.stringify({
          name,
          reward
        })
      }
    );

  static createCompanyReward = (
    name: string,
    reward: number,
    awardedBy: string,
    awardedTo: string,
    reason: string,
    date: string,
    ambassadorId: string | null,
    toAllAmbassadors: boolean,
    isOnboardingReward: boolean,
    companyId?: string | undefined
  ) =>
    http(
      `${process.env.REACT_APP_SERVER_URL}company/createreward${
        companyId ? `?companyId= ${companyId}` : ''
      }`,
      {
        method: 'POST',
        body: JSON.stringify({
          name,
          value: reward,
          ambassadorId,
          toAllAmbassadors,
          isOnboardingReward,
          awardedBy,
          awardedTo,
          reason,
          date
        })
      }
    );

  static updateCompanyIntention = (
    intentionId: string,
    name: string,
    reward: number,
    awardedTo: string,
    ambassador: string,
    awardedBy: string,
    reason: string,
    date: string,
    companyId?: string | undefined
  ) =>
    http(
      `${process.env.REACT_APP_SERVER_URL}company/updateintention${
        companyId ? `?companyId= ${companyId}` : ''
      }`,
      {
        method: 'PUT',
        body: JSON.stringify({
          intentionId,
          name,
          reward,
          awardedTo,
          ambassador,
          awardedBy,
          reason,
          date
        })
      }
    );

  static deleteCompanyIntention = (
    id: string,
    companyId?: string | undefined
  ) =>
    http(
      `${process.env.REACT_APP_SERVER_URL}company/deleteintention/${id}${
        companyId ? `?companyId=${companyId}` : ''
      }`,
      {
        method: 'DELETE'
      }
    );

  static getInviteUrl = async (): Promise<InviteUrlModel> =>
    http<InviteUrlModel>(
      `${process.env.REACT_APP_SERVER_URL}company/inviteurl`
    );

  static getAmbassadors = async (
    companyId?: string
  ): Promise<CompanyAmbassadorsListModel> =>
    http<CompanyAmbassadorsListModel>(
      `${process.env.REACT_APP_SERVER_URL}company/ambassadors${
        companyId ? `?companyId=${companyId}` : ''
      }`
    );

  static createCompanyInvite = (name: string, email: string) =>
    http(`${process.env.REACT_APP_SERVER_URL}company/invite`, {
      method: 'POST',
      body: JSON.stringify({
        name,
        email
      })
    });

  static deleteCompanyAmbassador = (
    ambassadorId: string,
    companyId?: string | undefined
  ) => {
    http(
      `${
        process.env.REACT_APP_SERVER_URL
      }company/removeambassador/${ambassadorId}${
        companyId ? `?companyId=${companyId}` : ''
      }`,
      {
        method: 'DELETE'
      }
    );
  };

  static createRewardLink = (
    rewardId: string
  ): Promise<CreateRewardLinkModel> =>
    http(`${process.env.REACT_APP_SERVER_URL}company/generateRewardLink`, {
      method: 'POST',
      body: JSON.stringify({
        rewardId
      })
    });
}
