import React from 'react';

import classes from './input.module.scss';

export enum InputTypes {
  text = 'text',
  email = 'email',
  password = 'password',
  number = 'number'
}

export enum InputClassNameType {
  default,
  smallFirst,
  small,
  card
}

type InputProps = {
  type: InputTypes;
  placeholder?: string;
  classNameType?: InputClassNameType | undefined;
  name: string;
  value?: string | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean | undefined;
  id?: string | undefined;
};

export default function Input({
  type,
  placeholder,
  classNameType,
  name,
  value,
  onChange,
  required,
  id
}: InputProps) {
  const getClassNames = (): string => {
    switch (classNameType) {
      case InputClassNameType.smallFirst:
        return classes.smallFirst;
      case InputClassNameType.small:
        return classes.small;
      case InputClassNameType.card:
        return classes.card;
      default:
        return classes.default;
    }
  };

  return (
    <input
      type={type}
      placeholder={placeholder}
      className={getClassNames()}
      name={name}
      value={value}
      onChange={onChange}
      required={required}
      minLength={type === InputTypes.password ? 6 : undefined}
      id={id}
    />
  );
}
