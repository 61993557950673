import React, { FunctionComponent, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AppState, updateAppState, useAppState } from '../apiServices/appState';
import { ApiController } from '../apiServices/calls/apiController';
import { Roles } from './router';

type AuthenticatedCompanyProps = {
  component: FunctionComponent;
  path: string;
};

const selector = (state: AppState) => ({
  role: state.authentication.accessToken?.role,
  companyDetails: state.companyDetails,
  companyId: state.adminDetails.companyId
});

const setCompanyDetails = (
  companyName: string,
  name: string,
  email: string,
  id: string,
  street: string,
  houseNumber: string,
  numberAddition: string,
  postalCode: string,
  city: string,
  loading: boolean
) => {
  updateAppState((state) => {
    state.companyDetails.companyName = companyName;
    state.companyDetails.loading = loading;
    state.companyDetails.name = name;
    state.companyDetails.email = email;
    state.companyDetails.id = id;
    state.companyDetails.addressStreet = street;
    state.companyDetails.addressNumber = houseNumber;
    state.companyDetails.addressAddition = numberAddition;
    state.companyDetails.addressPostalCode = postalCode;
    state.companyDetails.addressCity = city;
  });
};

export const AuthenticatedCompanyRoute = ({
  component: Component,
  path
}: AuthenticatedCompanyProps) => {
  const { role, companyDetails, companyId } = useAppState(selector).current;

  useEffect(() => {
    if (companyDetails.loading) {
      Promise.all([ApiController.Identity.getDetails(companyId)]).then(
        (data) => {
          if (data[0]) {
            setCompanyDetails(
              data[0].name || '',
              data[0].contactFullName || '',
              data[0].email || '',
              data[0].id || '',
              data[0].addressStreet || '',
              data[0].addressNumber || '',
              data[0].addressAddition || '',
              data[0].addressPostalCode || '',
              data[0].addressCity || '',
              false
            );
          }
        }
      );
    }
  }, [companyDetails, companyId]);

  return (
    <Route path={path}>
      {role === Roles.AttensieCompanyIdentityUser || Roles.AttensieAdmin ? (
        <Component />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { prevPath: window.location.pathname }
          }}
        />
      )}
    </Route>
  );
};
