import React from 'react';
import { Link } from 'react-router-dom';
import classes from '../../login-register.module.scss';
import inputClasses from '../../../../components/input/input.module.scss';
import { PageState, RegisterForm } from './register-company';
import Input, { InputTypes } from '../../../../components/input/Input';

type RegisterOneProps = {
  registerForm: RegisterForm;
  handleFormChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setPageState: React.Dispatch<React.SetStateAction<PageState>>;
};

export default function RegisterOne({
  registerForm,
  handleFormChange,
  setPageState
}: RegisterOneProps) {
  const { companyName, email, password, error } = registerForm;

  const handleSubmit = () => {
    setPageState(PageState.formTwo);
  };

  return (
    <>
      <p className={`${classes.register_left_content_container_steps}`}>
        Stap 1 van 2
      </p>
      <h1>Maak je account</h1>
      <form
        className={`d-flex flex-column ${classes.register_left_content_container_form}`}
        onSubmit={handleSubmit}>
        <Input
          type={InputTypes.text}
          placeholder="Bedrijfsnaam"
          name="companyName"
          value={companyName}
          onChange={(e) => handleFormChange(e)}
          required
        />
        <Input
          type={InputTypes.email}
          placeholder="email@domein.nl"
          name="email"
          value={email}
          onChange={(e) => handleFormChange(e)}
          required
        />
        <input
          type="password"
          name="password"
          placeholder="Voer je wachtwoord in"
          className={inputClasses.default}
          value={password}
          required
          pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{6,}$"
          onChange={(e) => {
            handleFormChange(e);
            if (!e.currentTarget.validity.patternMismatch) {
              e.currentTarget.setCustomValidity('');
            }

            if (e.currentTarget.validity.patternMismatch) {
              e.currentTarget.setCustomValidity(
                'Het wachtwoord moet minimaal 6 karakters, één hoofdletter, één cijfer en één speciaal teken bevatten'
              );
            }
          }}
        />
        {error && error.length !== 0 && (
          <div>
            <span style={{ color: 'red' }}>{error}</span>
          </div>
        )}
        <button type="submit">REGISTREER</button>
        <p className={`${classes.register_left_content_container_login}`}>
          Al een account? <Link to="/login">Log dan in.</Link>
        </p>
      </form>
      <p className={`${classes.register_left_content_container_terms}`}>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.attensie.nl/algemene-voorwaarden">
          Privacy Policy
        </a>
        &nbsp;en de&nbsp;
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.attensie.nl/algemene-voorwaarden">
          Algemene Voorwaarden
        </a>
      </p>
    </>
  );
}
