import React from 'react';
import classes from '../sparen.module.scss';
import Card from '../../../components/card/card';

type SummaryCardprops = {
  isMobile: boolean;
  totalAvailable: number | undefined;
  totalEarned: number | undefined;
  totalSpent: number | undefined;
};

export default function SummaryCard({
  isMobile,
  totalAvailable,
  totalEarned,
  totalSpent
}: SummaryCardprops) {
  return (
    <Card col={isMobile ? '12' : '6'} backgroundColor={null} height="245px">
      <div
        className={`w-100 h-100 flex-column col-12 ${classes.sparen_overview}`}>
        <div className={`${classes.sparen_overview_header}`}>
          <span>Overzicht</span>
        </div>
        <div className="flex-column col-12">
          <div className={`row ${classes.sparen_overview_label}`}>
            <div className={`col-8 ${classes.sparen_overview_label_text}`}>
              <span>Ontvangen</span>
            </div>
            <div className={`col-4 ${classes.sparen_overview_label_currency}`}>
              <span>€{totalEarned}</span>
            </div>
          </div>
          <div
            className={`row ${classes.sparen_overview_label} ${classes.sparen_overview_label_last}`}>
            <div className={`col-8 ${classes.sparen_overview_label_text}`}>
              <span>Verzilverd</span>
            </div>
            <div className={`col-4 ${classes.sparen_overview_label_currency}`}>
              <span className={`${classes.sparen_overview_label_currency}`}>
                €{totalSpent}
              </span>
              <div className={`${classes.sparen_overview_label_spacer}`} />
              <div className={`${classes.sparen_overview_label_minus}`} />
            </div>
          </div>
          <div className={`col-12 ${classes.sparen_overview_spacer}`} />
          <div
            className={`row ${classes.sparen_overview_label} ${classes.sparen_overview_label_summary}`}>
            <div className={`col-8 ${classes.sparen_overview_label_text}`}>
              <span>Resterend</span>
            </div>
            <div className={`col-4 ${classes.sparen_overview_label_currency}`}>
              <span>€{totalAvailable}</span>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}
