import React, { FunctionComponent } from 'react';
import { Route } from 'react-router-dom';
import { AppState, useAppState } from '../apiServices/appState';
import { UnAuthenticatedRouteRedirectsBasedOnRole } from './UnAuthenticatedRouteRedirectsBasedOnRole';

type UnAuthenticatedRouteProps = {
  component: FunctionComponent;
  path: string;
};

const selector = (state: AppState) => state.isAuthenticated;

export const UnAuthenticatedRoute = ({
  component: Component,
  path
}: UnAuthenticatedRouteProps) => {
  const isAuthed = useAppState(selector).current;

  return (
    <Route path={path}>
      {isAuthed ? <UnAuthenticatedRouteRedirectsBasedOnRole /> : <Component />}
    </Route>
  );
};
