import React, { ReactElement } from 'react';
import RoundLogo from '../../helpers/round-logo/round-logo';
import classes from './logo-item.module.scss';

type RouteItemProps = {
  logo?: string | undefined;
  name?: string | undefined;
  company?: string;
  rowButton?: (() => React.ReactNode) | ReactElement;
  hasBeenRedeemed?: boolean;
  hr?: boolean | undefined;
};

export default function LogoItem({
  logo,
  name,
  company,
  rowButton,
  hasBeenRedeemed,
  hr
}: RouteItemProps) {
  return (
    <>
      <div className={`col-12 align-items-center ${classes.logo_item_wrapper}`}>
        <div className={`row ${classes.logo_item_wrapper}`}>
          {logo && (
            <div className={`col-2 `}>
              <RoundLogo
                backgroundColor="#1a237e"
                diameter="35px"
                logo={logo}
              />
            </div>
          )}
          <div
            className={`col-8 flex-column ${classes.logo_item_wrapper_text}`}>
            <div
              className={`${classes.logo_item_text} ${classes.logo_item_header}`}>
              <span>{name}</span>
            </div>
            {company && (
              <div className={`${classes.logo_item_subtitle}`}>
                <span>{company}</span>
              </div>
            )}
          </div>
          {rowButton && typeof rowButton === 'function'
            ? rowButton()
            : rowButton}
          {hasBeenRedeemed && <div className={classes.logo_item_triangle} />}
        </div>
      </div>
      {hr && <hr className={classes.logo_item_hr} />}
    </>
  );
}
