// tslint:disable
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|'
};

export interface TokenResponseApiModel {
  accessToken: {
    token: string;
  };
  refreshToken: {
    token: string;
  };
}

export interface CompanyIntentionsApiModel {
  companyId?: string;
  companyName?: string;
  companyEmail?: string;
  companyPhoneNumber?: string;
  invitationDate?: Date;
  referenceCount?: number;
  totalEarnings?: number;
  intentions?: Array<IntentionApiModel>;
}

export interface CompaniesApiModel {
  name?: string;
  joinDate?: string;
  id?: string;
}

export interface CompanyListModel {
  backofficeCompanyApiModels?: Array<CompaniesApiModel>;
}

export interface CompanyIntentionsListApiModel {
  companies?: Array<CompanyIntentionsApiModel>;
}

export interface CompanyReferenceListModel {
  references?: Array<CompanyReferenceModel>;
}

export interface CompanyAmbassadorModel {
  ambassadorId: string;
  name?: string;
  email?: string;
  invitationDate: Date;
  totalConversion: number;
  phoneNumber: string | null;
}

export interface CompanyAmbassadorsListModel {
  ambassadors?: Array<CompanyAmbassadorModel>;
}

export interface CreateRewardLinkModel {
  url: string;
}

export interface CompanyReferenceModel {
  id?: string;
  name?: string;
  email?: string;
  phoneNumber?: string;
  referralDate?: Date;
  status?: Status;
  refereeName?: string;
  intention?: IntentionApiModel;
}

export interface CreditOverviewApiModel {
  totalEarned?: number;
  totalSpent?: number;
  totalAvailable?: number;
}

export interface CustomerReferenceListModel {
  references?: Array<CustomerReferenceModel>;
}

export interface CustomerReferenceModel {
  id?: string;
  name?: string;
  email?: string;
  phoneNumber?: string;
  referralDate?: Date;
  status?: Status;
  companyName?: string;
  conversionTotal?: number;
  rewardName?: string;
  rewardAmount?: string;
  issuedTo?: string;
  issuedBy?: string;
  ambassador?: string;
  reason?: string;
  issuedDate?: string;
}

export interface EmbassyApiModel {
  ambassadorId?: string;
  referenceCount?: number;
  companyId?: string;
  companyName?: string;
  totalEarnings?: number;
}

export interface EmbassyListApiModel {
  embassies?: Array<EmbassyApiModel>;
}

export interface IntentionApiModel {
  id?: string;
  name?: string;
  reward?: number;
  issuedTo?: string;
  ambassador?: string;
  issuedBy?: string;
  reason?: string;
  issueDate: string;
}

export interface IntentionAndRewardApiModel {
  intentions?: IntentionApiModel[];
  rewards?: RewardApiModel[];
}

export interface IntentionListApiModel {
  rewards: RewardApiModel[] | undefined;
  intentions?: IntentionApiModel[];
}

export interface RewardApiModel {
  id: string;
  name: string;
  value: number;
  awardedTo: string;
  ambassadorName: string;
  awardedBy: string;
  reason: string;
  date: string;
  hasBeenRedeemed: boolean;
}

export interface RewardListApiModel {
  rewards?: Array<RewardApiModel>;
}

export interface InviteUrlModel {
  inviteUrl?: string;
}

export interface MonthlyCreditApiModel {
  monthStartDate?: Date;
  totalCredit?: number;
}

export enum Status {
  NUMBER_1 = <any>1,
  NUMBER_2 = <any>2,
  NUMBER_3 = <any>3,
  NUMBER_4 = <any>4,
  NUMBER_5 = <any>5,
  NUMBER_6 = <any>6
}

export enum StatusStrings {
  new = 'Nieuw',
  completed = 'Afgehandeld',
  willContact = 'Neemt contact op',
  needsToBeContacted = 'Contact opnemen',
  rejected = 'Afgeketst',
  deleted = 'Verwijderd'
}

export interface UserDetailsModel {
  name?: string;
  email?: string;
  contactFullName?: string;
  addressStreet?: string;
  addressNumber?: string;
  addressAddition?: string;
  addressPostalCode?: string;
  addressCity?: string;
  id?: string;
}
