import React from 'react';
import classes from '../../sparen.module.scss';

type RedeemCardModalButtonProps = {
  openModal?: () => void;
  setRedeemModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function RedeemCardModalButton({
  openModal,
  setRedeemModalIsOpen
}: RedeemCardModalButtonProps) {
  return (
    <div className={`${classes.sparen_redeem_button_container}`}>
      <div
        onClick={() => {
          if (openModal) {
            openModal();
          }
          setRedeemModalIsOpen(true);
        }}
        className={`${classes.sparen_redeem_button}`}>
        VERZILVEREN
      </div>
    </div>
  );
}
