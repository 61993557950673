import React, { ReactElement, useState } from 'react';
import classes from '../doelen.module.scss';
import Card from '../../../components/card/card';
import LogoItem from '../../../components/logo-item/logo-item';
import { IntentionApiModel } from '../../../apiServices/models/api';
import ModalButton from '../../../components/modal-button/modal-button';
import DoelDetailButton from '../current-doel-detail-modal/modal-button';
import DoelDetailCardModalBody from '../current-doel-detail-modal/modal-body';
import DoelenCardModalBody from '../doelen-modal/modal-body';
import DoelenModalButton from '../doelen-modal/modal-button';

type CurrentDoelenCardProps = {
  data: IntentionApiModel[];
  isMobile: boolean;
  getDoelenData: () => void;
};

export default function CurrentDoelenCard({
  data,
  isMobile,
  getDoelenData
}: CurrentDoelenCardProps) {
  const [doelenModalIsOpen, setDoelenModalIsOpen] = useState(false);

  const createRowButton = (e: IntentionApiModel): ReactElement => {
    return (
      <div className={classes.gegevens_doelen_row_button_fix}>
        <ModalButton
          cardWidth={isMobile ? '12' : '4'}
          modalBody={
            <DoelDetailCardModalBody data={e} getDoelenData={getDoelenData} />
          }>
          <DoelDetailButton />
        </ModalButton>{' '}
      </div>
    );
  };

  return (
    <div className={`d-flex flex-column ${isMobile ? 'col-12' : 'col-4'}`}>
      <ModalButton
        cardWidth={isMobile ? '12' : '4'}
        modalBody={<DoelenCardModalBody getDoelenData={getDoelenData} />}
        isOpen={doelenModalIsOpen}>
        <DoelenModalButton
          setDoelenModalIsOpen={setDoelenModalIsOpen}
          doelenModalIsOpen={doelenModalIsOpen}
        />
      </ModalButton>
      <Card backgroundColor={null} col="12" height={null}>
        <div className={`d-flex flex-column ${classes.gegevens_doelen}`}>
          <div
            className={`d-flex flex-column ${classes.gegevens_doelen_wrapper}`}>
            <div
              className={`col-12 ${classes.gegevens_doelen_text} ${classes.gegevens_doelen_header}`}>
              <span>Welke tip?</span>
            </div>
            {data.length === 0 && (
              <div className="col-12">
                <LogoItem
                  logo="?"
                  name="Maak je eerste tip aan"
                  company="De tip geeft aan waar de referentie mogelijk geïnteresseerd in is."
                />
              </div>
            )}
            {data.map((item: IntentionApiModel) => {
              return (
                <LogoItem
                  key={item.id}
                  name={item.name}
                  company={`Waarde: € ${item.reward}`}
                  rowButton={createRowButton(item)}
                  hr
                />
              );
            })}
          </div>
        </div>
      </Card>
    </div>
  );
}
