import React, { FunctionComponent, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AppState, updateAppState, useAppState } from '../apiServices/appState';
import { ApiController } from '../apiServices/calls/apiController';
import { Roles } from './router';

type AuthenticatedAdminProps = {
  component: FunctionComponent;
  path: string;
};

const selector = (state: AppState) => ({
  role: state.authentication.accessToken?.role,
  userDetails: state.userDetails
});

const setFirstName = (
  name: string,
  email: string,
  id: string,
  loading: boolean
) => {
  updateAppState((state) => {
    state.userDetails.name = name;
    state.userDetails.loading = loading;
    state.userDetails.email = email;
    state.userDetails.id = id;
  });
};

export const AuthenticatedAdminRoute = ({
  component: Component,
  path
}: AuthenticatedAdminProps) => {
  const { role, userDetails } = useAppState(selector).current;
  useEffect(() => {
    if (userDetails.loading) {
      Promise.all([ApiController.Identity.getDetails()]).then((data) => {
        if (data[0]) {
          setFirstName(
            data[0].name || '',
            data[0].email || '',
            data[0].id || '',
            false
          );
        }
      });
    }
  }, [userDetails]);

  return (
    <Route path={path}>
      {role === Roles.AttensieAdmin ? (
        <Component />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { prevPath: window.location.pathname }
          }}
        />
      )}
    </Route>
  );
};
