import React from 'react';
import classes from './redeem-card-modal.module.scss';
import { RedeemModalSteps } from './redeem-card-modal-body';
import { SparenStateType } from '../../sparen';
import { ApiController } from '../../../../apiServices/calls/apiController';

export const renderFooter = (
  currentStep: RedeemModalSteps,
  setModal: (value: React.SetStateAction<RedeemModalSteps>) => void,
  closeModal: (() => void) | undefined,
  currentState: SparenStateType,
  productName: string,
  setState: (value: React.SetStateAction<SparenStateType>) => void
) => {
  const prefix = '€';
  let amount = '0';

  switch (currentStep) {
    case RedeemModalSteps.stepOne:
      return (
        <div className={`d-flex flex-column ${classes.redeem_modal_footer}`}>
          <div
            className={`d-flex flex-column ${classes.redeem_modal_input_wrapper}`}>
            <span className={`${classes.redeem_modal_input_label}`}>
              Hoeveel wil je verzilveren?
            </span>
            <input
              ref={(target) => {
                if (target) {
                  target.value = prefix;
                }
              }}
              id="amount"
              type="text"
              onChange={(e) => {
                const input = e.target.value;
                amount = input.substr(prefix.length);
                e.target.value = prefix + input.substr(prefix.length);
              }}
            />
            {currentState.error && currentState.error.message && (
              <span
                className={`${classes.redeem_modal_input_label} ${classes.redeem_modal_input_label_error}`}>
                {currentState.error.message}
              </span>
            )}
          </div>

          <div
            onClick={async () => {
              if (currentState.buttonDisabled) {
                return;
              }
              const numberAmount = Number(amount);
              if (Number.isNaN(numberAmount)) {
                setState({
                  ...currentState,
                  error: {
                    message: 'Voer een geldig getal in'
                  }
                });

                return;
              }

              if (
                currentState.credit &&
                currentState.credit.totalAvailable >= numberAmount &&
                numberAmount !== 0
              ) {
                const update = {
                  ...currentState.credit,
                  totalAvailable:
                    currentState.credit.totalAvailable - numberAmount,
                  totalSpent: currentState.credit.totalSpent + numberAmount
                };
                setState({
                  credit: update,
                  error: undefined,
                  buttonDisabled: true
                });

                await ApiController.Credit.redeemCredits(
                  productName,
                  numberAmount
                );
                setModal(RedeemModalSteps.success);
                setState({
                  ...currentState,
                  credit: update,
                  buttonDisabled: false
                });
                return;
              }

              if (
                currentState.credit &&
                currentState.credit.totalAvailable < numberAmount
              ) {
                setState({
                  credit: {
                    ...currentState.credit
                  },
                  error: {
                    message: 'Zoveel geld heb je nog niet gespaard.'
                  },
                  buttonDisabled: false
                });
              }
            }}
            className={`${classes.redeem_modal_footer_button}`}>
            VERZILVEREN
          </div>
        </div>
      );
    case RedeemModalSteps.success:
      return (
        <div className={`d-flex flex-column ${classes.redeem_modal_footer}`}>
          <div
            onClick={() => {
              if (closeModal) {
                closeModal();
              }

              setModal(RedeemModalSteps.stepOne);
            }}
            className={`${classes.redeem_modal_footer_button}`}>
            AFRONDEN
          </div>
        </div>
      );
    default:
      return <></>;
  }
};
