import React from 'react';
import accountSVG from '../../assets/svg/Account.svg';
import DashboardSVG from '../../assets/svg/Dashboard.svg';
import ReferentiesSVG from '../../assets/svg/Referenties.svg';
import SparenSVG from '../../assets/svg/Sparen.svg';
import RouteItem from '../route-item';

export enum RoutesB2C {
  dashboard = '/dashboard',
  referenties = '/referenties',
  sparen = '/beloningen',
  bedrijven = '/bedrijven',
  profiel = '/profiel'
}

type SidebarBodyProps = {
  currentRoute: string;
};
export default function ClientSidebarBody({ currentRoute }: SidebarBodyProps) {
  return (
    <div>
      <RouteItem
        logo={DashboardSVG}
        routeName="Dashboard"
        route="/dashboard"
        active={currentRoute === RoutesB2C.dashboard}
      />
      <RouteItem
        logo={ReferentiesSVG}
        routeName="Referenties"
        route="/referenties"
        active={currentRoute === RoutesB2C.referenties}
      />
      <RouteItem
        logo={SparenSVG}
        routeName="Beloningen"
        route="/beloningen"
        active={currentRoute === RoutesB2C.sparen}
      />
      <RouteItem
        logo={accountSVG}
        routeName="Profiel"
        route="/profiel"
        active={currentRoute === RoutesB2C.profiel}
      />
    </div>
  );
}
