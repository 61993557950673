import React from 'react';
import classes from '../sparen.module.scss';
import Card from '../../../components/card/card';
import ModalButton from '../../../components/modal-button/modal-button';
import RedeemCardModalBody from './redeem-card-modal/redeem-card-modal-body';
import RedeemCardModalButton from './redeem-card-modal/redeem-card-modal-button';
import { SparenStateType } from '../sparen';

type RedeemCardprops = {
  isMobile: boolean;
  image: string;
  altText: string;
  header: string;
  modalIsOpen: boolean;
  productName: string;
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentState: SparenStateType;
  setState: (value: React.SetStateAction<SparenStateType>) => void;
  title: string;
};

export default function RedeemCard({
  isMobile,
  image,
  altText,
  header,
  modalIsOpen,
  productName,
  setModalIsOpen,
  currentState,
  setState,
  title
}: RedeemCardprops) {
  return (
    <Card col={isMobile ? '12' : '6'} backgroundColor={null} height="288px">
      <div className={`${classes.sparen_redeem} col-12 d-flex flex-column`}>
        <div className={`${classes.sparen_redeem_title}`}>
          <span>{header}</span>
        </div>
        <div className={`${classes.sparen_redeem_image}`}>
          <img
            className={`${classes.sparen_redeem_image}`}
            src={`${image}`}
            alt={altText}
          />
        </div>
        <ModalButton
          cardWidth={isMobile ? '12' : '4'}
          modalBody={
            <RedeemCardModalBody
              image={image}
              altText={altText}
              title={title}
              currentState={currentState}
              productName={productName}
              setState={setState}
            />
          }
          isOpen={modalIsOpen}>
          <RedeemCardModalButton setRedeemModalIsOpen={setModalIsOpen} />
        </ModalButton>
      </div>
    </Card>
  );
}
