import React, { ReactNode } from 'react';
import classes from './redeem-card-modal.module.scss';

export const renderHeader = (
  title: string,
  closeModal: (() => void) | undefined
): ReactNode => {
  return (
    <>
      <span className={classes.redeem_modal_header_title}>{title}</span>
      <div
        className={classes.redeem_modal_header_close}
        onClick={() => {
          if (closeModal) {
            closeModal();
          }
        }}>
        <span>x</span>
      </div>
    </>
  );
};
