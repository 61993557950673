import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import classes from './referenties.module.scss';
import Header from '../../components/header/header';
import CardRow from '../../components/card-row/card-row';
import Datagrid from '../../components/datagrid/datagrid';
import useWindowDimensions from '../../helpers/window-dimensions';
import { CellInfo, Column } from '../../components/datagrid/gridinterface';
import LogoItem from '../../components/logo-item/logo-item';
import { createInitialsFromFullName } from '../../helpers/logo-helpers';
import Footer from '../../components/footer/footer';
import ModalButton from '../../components/modal-button/modal-button';
import ReferentieCardModalBody from './referentie-modal/modal-body';
import ReferentieInfoButton from './referentie-modal/modal-button';
import { ApiController } from '../../apiServices/calls/apiController';
import { enumStatusNumbersToStrings } from '../../helpers/enum-helpers';
import { CompanyReferenceModelWithStringStatus } from '../../apiServices/calls/controllers/company';
import { StatusStrings } from '../../apiServices/models/api';
import { AppState, useAppState } from '../../apiServices/appState';
import { Roles } from '../../router/router';

export default function B2bReferenties() {
  const [referentieModalIsOpen, setReferentieModalIsOpen] = useState(false);
  const { isMobile } = useWindowDimensions();
  const [screenState, setScreenState] =
    useState<CompanyReferenceModelWithStringStatus[]>();
  const selector = (state: AppState) => ({
    companyId:
      Roles.AttensieAdmin === state.authentication.accessToken?.role
        ? state.adminDetails.companyId
        : undefined
  });
  const { companyId } = useAppState(selector).current;

  const getReferenceData = useCallback(() => {
    Promise.all([ApiController.Reference.getCompanyReferences(companyId)]).then(
      (data): void => {
        if (data[0]) {
          const update: CompanyReferenceModelWithStringStatus[] = data[0]
            .references
            ? data[0].references.map((reference) => {
                const referenceWithStringStatus: CompanyReferenceModelWithStringStatus =
                  {
                    ...reference,
                    OriginalStatus: reference.status,
                    status: enumStatusNumbersToStrings(
                      reference.status ? reference.status : 0
                    )
                  };

                return {
                  ...referenceWithStringStatus
                };
              })
            : [];

          return setScreenState(update);
        }
        return undefined;
      }
    );
  }, [companyId]);

  useEffect(() => {
    if (!screenState) {
      getReferenceData();
    }
  }, [screenState, getReferenceData]);

  const createRowButton = (e: CellInfo): ReactElement => {
    return (
      <ModalButton
        cardWidth={isMobile ? '12' : '4'}
        modalBody={
          <ReferentieCardModalBody
            data={e.value}
            getReferenceData={getReferenceData}
          />
        }
        isOpen={referentieModalIsOpen}>
        <ReferentieInfoButton
          setReferentieModalIsOpen={setReferentieModalIsOpen}
          referentieModalIsOpen={referentieModalIsOpen}
        />
      </ModalButton>
    );
  };

  const columnHeaders: Column[] = [
    {
      id: 'name',
      label: 'Referenties',
      active: true,
      offset: 'L',
      offsetSize: 1,
      component: (c) => (
        <div className={classes.logo_height_fix}>
          <LogoItem
            logo={createInitialsFromFullName(c.value.name)}
            name={c.value.name}
          />
        </div>
      )
    },
    { id: 'refereeName', label: 'Ambassadeur', active: false },
    {
      id: 'intention',
      label: 'Doel',
      active: false,
      offset: 'S',
      offsetSize: 1,
      component: (c) => <span>{c.value.intention.name}</span>
    },
    {
      id: 'status',
      label: 'Status',
      active: false,
      offset: 'S',
      offsetSize: 1
    }
  ];

  return (
    <div className={`flex-column ${classes.referenties_container}`}>
      <Header subtitle="Welkom terug!" />
      <CardRow isMobile={isMobile}>
        <h3>Lopende referenties</h3>
        <Datagrid
          backgroundColor={null}
          col={isMobile ? '12' : '10'}
          columns={columnHeaders}
          data={
            screenState?.filter(
              (ref) => ref.status !== StatusStrings.completed
            ) || []
          }
          rowButton={(e) => createRowButton(e)}
        />
        <h3>Afgehandelde referenties</h3>
        <Datagrid
          backgroundColor={null}
          col={isMobile ? '12' : '10'}
          columns={columnHeaders}
          data={
            screenState?.filter(
              (ref) => ref.status === StatusStrings.completed
            ) || []
          }
          rowButton={(e) => createRowButton(e)}
        />
      </CardRow>
      <Footer />
    </div>
  );
}
