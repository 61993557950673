import React, { useRef } from 'react';
import classes from './layout.module.scss';
import attensieLogo from '../assets/svg/LogoAttensie.svg';
import RoundLogo from '../helpers/round-logo/round-logo';

type TopHeaderProps = {
  sidebarOpen?: boolean | undefined;
  setSidebarOpen: (bool: boolean) => void;
};
export default function TopHeader({
  sidebarOpen,
  setSidebarOpen
}: TopHeaderProps) {
  const animation = useRef<string>(
    sidebarOpen ? classes.layout_top_header_hamburger_menu_toCross : ''
  );

  const insertAnimation = (bool: boolean) => {
    animation.current = bool
      ? classes.layout_top_header_hamburger_menu_toCross
      : classes.layout_top_header_hamburger_menu_toMenu;
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className={`col-12 ${classes.layout_top_header}`}>
      <div
        className={classes.layout_top_header_hamburger_container}
        onClick={() => insertAnimation(!sidebarOpen)}>
        <div
          className={`${classes.layout_top_header_hamburger_menu}  ${animation.current}`}>
          <span className="bar1" />
          <span className="bar2" />
          <span className="bar3" />
        </div>
      </div>
      <img src={attensieLogo} alt="test" />
      <div className={`col-2 ${classes.layout_top_header_logo}`}>
        <RoundLogo backgroundColor="#1a237e" diameter="35px" logo="RH" />
      </div>
    </div>
  );
}
