import React from 'react';
import classes from '../dashboard.module.scss';
import Card from '../../../components/card/card';

type CurrentCompaniesCardProps = {
  isMobile: boolean;
};

export default function InfoCard({ isMobile }: CurrentCompaniesCardProps) {
  return (
    <Card backgroundColor="#8DA3FF" col={isMobile ? '12' : '4'} height="185px">
      <div className={`d-flex flex-column ${classes.dashboard_info}`}>
        <div className={`d-flex flex-column ${classes.dashboard_info_wrapper}`}>
          <span className={`${classes.dashboard_info_text}`}>
            Hoe werkt Attensie?
          </span>
        </div>
        <div className={`${classes.dashboard_info_link}`}>
          {/* Todo add correct link */}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.attensie.nl/hoe-werkt-het">
            Lees meer {'>'}
          </a>
        </div>
      </div>
    </Card>
  );
}
