// all single user related calls

import { UserDetailsModel } from '../../models/api';
import { http } from '../http';

type RegisterCustomerModel = {
  email: string;
  fullName: string;
  password: string;
  companyId: string;
  rewardId: string | null;
};

type UpdateCompanyModal = {
  id: string;
  companyName: string;
  fullName: string;
  streetName: string;
  houseNumber: string;
  houseNumberAddition: string;
  postalCode: string;
  city: string;
  email: string;
};

type RegisterCompanyModel = {
  companyName: string;
  fullName: string;
  streetName: string;
  houseNumber: string;
  houseNumberAddition: string;
  postalCode: string;
  city: string;
  email: string;
  password: string;
};

type RegisterResponse = {
  accessToken: {
    token: string;
  };
  refreshToken: {
    token: string;
  };
};

type TokenVerifyRequest = {
  email: string;
  token: string;
};

export class IdentityController {
  constructor() {
    throw Error(
      'IdentityController should not initialize, try using it like this => IdentityController.login'
    );
  }

  static registerCompany = async ({
    email,
    fullName,
    password,
    city,
    companyName,
    houseNumber,
    houseNumberAddition,
    postalCode,
    streetName
  }: RegisterCompanyModel) =>
    http<Promise<RegisterResponse | string>>(
      `${process.env.REACT_APP_SERVER_URL}identity/registerCompany`,
      {
        method: 'POST',
        body: JSON.stringify({
          email,
          fullName,
          password,
          city,
          companyName,
          houseNumber,
          houseNumberAddition,
          postalCode,
          streetName
        })
      }
    );

  static registerCustomer = async ({
    email,
    fullName,
    password,
    companyId,
    rewardId
  }: RegisterCustomerModel) => {
    const qParam = rewardId ? `?rewardId=${rewardId}` : '';

    return http<Promise<RegisterResponse | string>>(
      `${process.env.REACT_APP_SERVER_URL}identity/registercustomer${qParam}`,
      {
        method: 'POST',
        body: JSON.stringify({
          email,
          fullName,
          password,
          companyId
        })
      }
    );
  };

  static confirmEmail = async ({ email, token }: TokenVerifyRequest) =>
    http<Promise<void>>(
      `${process.env.REACT_APP_SERVER_URL}identity/confirmemail?token=${token}&email=${email}`
    );

  static getDetails = async (companyId?: string) => {
    const routeAddition = companyId ? `/${companyId}` : '';

    return http<Promise<UserDetailsModel>>(
      `${process.env.REACT_APP_SERVER_URL}identity/details${routeAddition}`
    );
  };

  static updateCustomerDetails = async (Name: string, Email: string) =>
    http<Promise<Response>>(
      `${process.env.REACT_APP_SERVER_URL}identity/updatecustomerdetails`,
      {
        method: 'PUT',
        body: JSON.stringify({
          Name,
          Email
        })
      }
    );

  static updateCompanyDetails = async ({
    city,
    companyName,
    email,
    fullName,
    houseNumber,
    houseNumberAddition,
    postalCode,
    streetName,
    id
  }: UpdateCompanyModal) =>
    http<Promise<Response>>(
      `${process.env.REACT_APP_SERVER_URL}identity/updatecompanydetails`,
      {
        method: 'PUT',
        body: JSON.stringify({
          id,
          name: companyName,
          email,
          contactFullName: fullName,
          addressStreet: streetName,
          addressNumber: houseNumber,
          addressAddition: houseNumberAddition,
          addressPostalCode: postalCode,
          addressCity: city
        })
      }
    );

  static updatePassword = async (
    CurrentPassword: string,
    NewPassword: string
  ) =>
    http(`${process.env.REACT_APP_SERVER_URL}identity/updatepassword`, {
      method: 'PUT',
      body: JSON.stringify({
        CurrentPassword,
        NewPassword
      })
    });

  static requestPasswordReset = async (email: string) => {
    return http<Promise<Response>>(
      `${process.env.REACT_APP_SERVER_URL}identity/requestpasswordreset`,
      {
        method: 'POST',
        body: JSON.stringify({
          email
        })
      }
    );
  };

  static resetPassword = async (
    email: string,
    resetToken: string,
    newPassword: string
  ) =>
    http<Promise<Response | string>>(
      `${process.env.REACT_APP_SERVER_URL}identity/resetpassword`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          email,
          resetToken,
          newPassword
        })
      }
    );
}
