import React from 'react';
import classes from '../klanten.module.scss';
import sluit from '../../../assets/png/close_icon.png';
import {
  AmbassadorDatagridType,
  AmbasssadorsScreenStateType
} from '../b2b-klanten';
import LogoItem from '../../../components/logo-item/logo-item';
import { createInitialsFromFullName } from '../../../helpers/logo-helpers';
import { ApiController } from '../../../apiServices/calls/apiController';
import { AppState, useAppState } from '../../../apiServices/appState';
import { Roles } from '../../../router/router';

type AmbassadeurCardModalBodyProps = {
  data: AmbassadorDatagridType;
  screenState: AmbasssadorsScreenStateType;
  setScreenState: React.Dispatch<
    React.SetStateAction<AmbasssadorsScreenStateType>
  >;
  closeModal?: () => void;
};

export enum DoelenModalSteps {
  stepOne,
  success
}

export default function AmbassadeurCardModalBody({
  closeModal,
  setScreenState,
  screenState,
  data
}: AmbassadeurCardModalBodyProps) {
  const selector = (state: AppState) => ({
    companyId:
      Roles.AttensieAdmin === state.authentication.accessToken?.role
        ? state.adminDetails.companyId
        : undefined
  });
  const { companyId } = useAppState(selector).current;

  const renderBody = () => {
    return (
      <>
        <div className={classes.ambassadeur_info_modal_body_data}>
          <h4>Gegevens:</h4>
          <span>{`Naam: ${data.ambassadeur}`}</span>
          <span>{`Email: ${data?.email || ''}`}</span>
          <span>{`Telefoon: ${data.tel}`}</span>
          <span>{`Toegevoegd op: ${data.aanmeldingsDatum || ''}`}</span>
          <span>{`Conversie: ${data?.conversie || ''}`}</span>
        </div>
        <div className={classes.ambassadeur_info_modal_remove_wrapper}>
          <span
            onClick={async () => {
              ApiController.Company.deleteCompanyAmbassador(data.id, companyId);
              if (closeModal) {
                setScreenState({
                  ...screenState,
                  fetchData: true
                });
                closeModal();
              }
            }}>
            Ambassadeur verwijderen
          </span>
        </div>
      </>
    );
  };

  return (
    <div className={`d-flex flex-column ${classes.ambassadeur_info_modal}`}>
      <div className={classes.ambassadeur_info_modal_header}>
        <LogoItem
          logo={createInitialsFromFullName(data.ambassadeur)}
          name={data.ambassadeur}
          company=""
        />
        <img
          src={sluit}
          alt="sluit"
          className={classes.ambassadeur_info_modal_header_close}
          onClick={() => {
            if (closeModal) {
              closeModal();
            }
          }}
        />
      </div>
      <div className={classes.ambassadeur_info_modal_body}>{renderBody()}</div>
    </div>
  );
}
