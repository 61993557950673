import React, { ReactElement, useState } from 'react';
import classes from '../doelen.module.scss';
import Card from '../../../components/card/card';
import LogoItem from '../../../components/logo-item/logo-item';
import { RewardApiModel } from '../../../apiServices/models/api';
import ModalButton from '../../../components/modal-button/modal-button';
import DoelDetailButton from '../current-doel-detail-modal/modal-button';
import LosseBeloningCardModalBody from '../losse-beloning-modal/modal-body';
import BeloningModalButton from '../losse-beloning-modal/modal-button';
import { DoelenScreenStateType } from '../b2b-doelen';
import BeloningDetailCardModalBody from '../current-beloning-detail-modal/modal-body';

type CurrentBeloningenCardProps = {
  data: RewardApiModel[];
  state: DoelenScreenStateType;
  isMobile: boolean;
  getDoelenData: () => void;
};

export default function CurrentBeloningenCard({
  data,
  isMobile,
  state,
  getDoelenData
}: CurrentBeloningenCardProps) {
  const [doelenModalIsOpen, setDoelenModalIsOpen] = useState(false);

  const createRowButton = (e: RewardApiModel): ReactElement => {
    return (
      <div className={classes.gegevens_doelen_row_button_fix}>
        <ModalButton
          cardWidth={isMobile ? '12' : '4'}
          modalBody={<BeloningDetailCardModalBody data={e} />}>
          <DoelDetailButton />
        </ModalButton>
      </div>
    );
  };

  return (
    <div className={`d-flex flex-column ${isMobile ? 'col-12' : 'col-4'}`}>
      <ModalButton
        cardWidth={isMobile ? '12' : '4'}
        modalBody={
          <LosseBeloningCardModalBody
            state={state}
            getDoelenData={getDoelenData}
          />
        }
        isOpen={doelenModalIsOpen}>
        <BeloningModalButton
          setDoelenModalIsOpen={setDoelenModalIsOpen}
          doelenModalIsOpen={doelenModalIsOpen}
        />
      </ModalButton>
      <Card backgroundColor={null} col="12" height={null}>
        <div className={`d-flex flex-column ${classes.gegevens_doelen}`}>
          <div
            className={`d-flex flex-column ${classes.gegevens_doelen_wrapper}`}>
            <div
              className={`col-12 ${classes.gegevens_doelen_text} ${classes.gegevens_doelen_header}`}>
              <span>Losse beloningen</span>
            </div>
            {data.length === 0 && (
              <div className="col-12">
                <LogoItem
                  logo="?"
                  name="Maak je eerste losse beloning aan"
                  company="Losse beloningen kun je toekennen aan 1 persoon, alle personen, of als welkomstbeloning."
                />
              </div>
            )}
            {data.map((item: RewardApiModel) => {
              return (
                <LogoItem
                  key={item.id}
                  name={item.name}
                  company={`Waarde: € ${item.value}`}
                  rowButton={createRowButton(item)}
                  hasBeenRedeemed={item.hasBeenRedeemed}
                  hr
                />
              );
            })}
          </div>
        </div>
      </Card>
    </div>
  );
}
