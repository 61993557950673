import React from 'react';
import classes from './round-logo.module.scss';

type RoundLogoProps = {
  logo: string;
  diameter: string | null;
  backgroundColor: string | null;
};

// For now only supports text as logo

export default function RoundLogo({
  logo,
  diameter,
  backgroundColor
}: RoundLogoProps) {
  return (
    <div
      style={{
        height: diameter || '35px',
        width: diameter || '35px',
        backgroundColor: backgroundColor || '#1a237e'
      }}
      className={`${classes.logo_item_logo}`}>
      {logo}
    </div>
  );
}
