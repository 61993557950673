export const http = <T>(
  request: RequestInfo,
  init?: RequestInit | undefined
): Promise<T> => {
  return fetch(request, init)
    .then((response) => response.json())
    .catch((err) => {
      // throw Error(`something went wrong error: ${err}`);
      console.warn(`something went wrong error: ${err}`);
    });
};
