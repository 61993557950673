import React, { ReactNode } from 'react';
import classes from './card-row.module.scss';

type CardRowProps = {
  children: ReactNode;
  isMobile: boolean;
  justifyStart?: boolean | undefined;
};

export default function CardRow({
  children,
  isMobile,
  justifyStart
}: CardRowProps) {
  return (
    <div
      className={` 
      ${classes.card_row}
      ${
        justifyStart
          ? 'justify-content-start'
          : !isMobile
          ? 'justify-content-between'
          : 'justify-content-center'
      } 
      ${!isMobile ? 'd-flex row' : ''} col-12`}>
      {children}
    </div>
  );
}
