import {
  CustomerReferenceListModel,
  CompanyReferenceListModel,
  Status
} from '../../models/api';
import { http } from '../http';

export interface CustomerReferenceModelWithStrings {
  id?: string;
  name?: string;
  email?: string;
  phoneNumber?: string;
  referralDate?: Date;
  originalStatus?: Status;
  status?: string;
  companyName?: string;
  originalConversionTotal?: number;
  conversionTotal?: string;
  isReward?: boolean | undefined;
  rewardAmount?: string;
  awardedTo?: string;
  ambassadorName?: string;
  awardedBy?: string;
  reason?: string;
  createdAt?: string;
  value?: number;
}

export interface CustomerRewardModelWithStrings {
  id?: string;
  name?: string;
  email?: string;
  phoneNumber?: string;
  referralDate?: Date;
  originalStatus?: Status;
  status?: string;
  companyName?: string;
  originalConversionTotal?: number;
  conversionTotal?: string;
}

export class ReferenceController {
  constructor() {
    throw Error(
      'ReferenceController should not initialize, try using it like this => ReferenceController.getCustromerReferences'
    );
  }

  static getCustomerReferences =
    async (): Promise<CustomerReferenceListModel> =>
      http<CustomerReferenceListModel>(
        `${process.env.REACT_APP_SERVER_URL}reference/customer`
      );

  static getLatestCustomerReferences = async (
    amount: number
  ): Promise<CustomerReferenceListModel> =>
    http<CustomerReferenceListModel>(
      `${process.env.REACT_APP_SERVER_URL}reference/customerlatest/${amount}`
    );

  static getCompanyReferences = async (
    companyId?: string | undefined
  ): Promise<CompanyReferenceListModel> =>
    http<CompanyReferenceListModel>(
      `${process.env.REACT_APP_SERVER_URL}reference/company${
        companyId ? `?companyId= ${companyId}` : ''
      }`
    );

  static createReference = (
    CompanyId: string,
    IntentionId: string,
    Name: string,
    EmailAddress: string,
    TelephoneNumber: string,
    MayBeContacted: boolean
  ) =>
    http<Promise<void>>(`${process.env.REACT_APP_SERVER_URL}reference/create`, {
      method: 'POST',
      body: JSON.stringify({
        CompanyId,
        IntentionId,
        Name,
        EmailAddress,
        TelephoneNumber,
        MayBeContacted
      })
    });

  static getCompanyReferenceListModel =
    async (): Promise<CompanyReferenceListModel> =>
      http<CompanyReferenceListModel>(
        `${process.env.REACT_APP_SERVER_URL}reference/completionrequests`
      );

  static referenceComplete = (ReferenceId: string) =>
    http<Promise<void>>(
      `${process.env.REACT_APP_SERVER_URL}reference/complete`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          ReferenceId
        })
      }
    );

  static referenceUpdateStatus = (referenceId: string, newStatus: Status) =>
    http<Promise<void>>(
      `${process.env.REACT_APP_SERVER_URL}reference/updatestatus`,
      {
        method: 'PUT',
        body: JSON.stringify({
          referenceId,
          newStatus
        })
      }
    );
}
