import React, { useEffect, useState } from 'react';
import classes from './datagrid.module.scss';
import { Column } from './gridinterface';

type CardProps = {
  calcCellSize: (
    offset: 'S' | 'L' | undefined,
    offsetSize: number | undefined
  ) => number;
  columns: Column[];
};

export default function GridHeader({ calcCellSize, columns }: CardProps) {
  const [gridColumns, setGridColumns] = useState<Column[]>(columns);

  useEffect(() => {
    setGridColumns(columns);
  }, [columns]);

  return (
    <div className={`${classes.datagrid_header}`}>
      {gridColumns.map((column) => {
        return (
          <div
            key={column.id}
            className={`col-${calcCellSize(column.offset, column.offsetSize)} ${
              classes.datagrid_header_item
            } ${classes.datagrid_header_item_active}`}>
            {column.label}
          </div>
        );
      })}
    </div>
  );
}
