import React, { useEffect, useState } from 'react';
import classes from '../account/account.module.scss';
import Card from '../../components/card/card';
import Input, {
  InputClassNameType,
  InputTypes
} from '../../components/input/Input';
import {
  AppState,
  updateAppState,
  useAppState
} from '../../apiServices/appState';
import { ApiController } from '../../apiServices/calls/apiController';

type ChangeSettingsCardProps = {
  isMobile: boolean;
  height?: string | undefined;
  col: '2' | '4' | '6' | '8' | '10' | '12';
  title: string;
  buttonText: string;
  disabled?: boolean | undefined;
};

type ChangeSettingsScreenStateType = {
  fullName: string | undefined;
  email: string | undefined;
  id: string | undefined;
  companyName: string | undefined;
  street: string | undefined;
  streetNumber: string | undefined;
  suffix: string | undefined;
  zipCode: string | undefined;
  city: string | undefined;
  initialSet: boolean;
};

const selector = (state: AppState) => ({
  companyDetails: state.companyDetails
});

const setCompanyDetails = (
  companyName: string,
  name: string,
  email: string,
  street: string,
  houseNumber: string,
  numberAddition: string,
  postalCode: string,
  city: string
) => {
  updateAppState((state) => {
    state.companyDetails.companyName = companyName;
    state.companyDetails.name = name;
    state.companyDetails.email = email;
    state.companyDetails.addressStreet = street;
    state.companyDetails.addressNumber = houseNumber;
    state.companyDetails.addressAddition = numberAddition;
    state.companyDetails.addressPostalCode = postalCode;
    state.companyDetails.addressCity = city;
  });
};

export default function ChangeSettingsCard({
  isMobile,
  col,
  height,
  title,
  buttonText,
  disabled
}: ChangeSettingsCardProps) {
  const { companyDetails } = useAppState(selector).current;
  const [confirmActive, setConfirmActive] = useState(false);
  const [details, setDetails] = useState<ChangeSettingsScreenStateType>({
    fullName: undefined,
    email: undefined,
    id: undefined,
    companyName: undefined,
    street: undefined,
    streetNumber: undefined,
    suffix: undefined,
    zipCode: undefined,
    city: undefined,
    initialSet: false
  });

  useEffect(() => {
    if (!details.fullName) {
      setDetails({
        fullName: companyDetails.name,
        email: companyDetails.email,
        id: companyDetails.id,
        companyName: companyDetails.companyName,
        street: companyDetails.addressStreet,
        streetNumber: companyDetails.addressNumber,
        suffix: companyDetails.addressAddition,
        zipCode: companyDetails.addressPostalCode,
        city: companyDetails.addressCity,
        initialSet: true
      });
    }
  }, [companyDetails, details]);
  const {
    fullName,
    street,
    streetNumber,
    suffix,
    zipCode,
    city,
    companyName,
    email,
    id
  } = details;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!confirmActive) {
      return;
    }

    await ApiController.Identity.updateCompanyDetails({
      city: city || companyDetails.addressCity,
      companyName: companyName || companyDetails.companyName,
      email: email || companyDetails.email,
      fullName: fullName || companyDetails.name,
      houseNumber: streetNumber || companyDetails.addressNumber,
      houseNumberAddition: suffix || companyDetails.addressAddition,
      id: id || companyDetails.id,
      postalCode: zipCode || companyDetails.addressPostalCode,
      streetName: street || companyDetails.addressStreet
    });

    setCompanyDetails(
      companyName || companyDetails.companyName,
      fullName || companyDetails.name,
      email || companyDetails.email,
      street || companyDetails.addressStreet,
      streetNumber || companyDetails.addressNumber,
      suffix || companyDetails.addressAddition,
      zipCode || companyDetails.addressPostalCode,
      city || companyDetails.addressCity
    );

    setConfirmActive(false);
  };

  return details.fullName ? (
    <Card
      backgroundColor={null}
      col={isMobile ? '12' : col}
      height={height ?? null}
      disabled={disabled}>
      <div
        className={`d-flex flex-column justify-content-start ${classes.account_card}`}>
        <p
          className={`${classes.account_title} ${classes.account_title_align_left}`}>
          {title}
        </p>
        <hr className={classes.account_hr} />
        <form className={classes.account_card_form} onSubmit={handleSubmit}>
          <label htmlFor={fullName}>Naam:</label>
          <Input
            type={InputTypes.text}
            classNameType={InputClassNameType.card}
            name={fullName || ''}
            onChange={(e) => {
              setDetails({ ...details, fullName: e.target.value });
              setConfirmActive(true);
            }}
            value={fullName}
            required
            id="fullName"
          />
          <label htmlFor={email}>Email:</label>
          <Input
            type={InputTypes.text}
            classNameType={InputClassNameType.card}
            name={email || ''}
            onChange={(e) => {
              setDetails({ ...details, email: e.target.value });
              setConfirmActive(true);
            }}
            value={email}
            required
            id="email"
          />
          <label htmlFor={companyName}>Bedrijfs naam:</label>
          <Input
            type={InputTypes.text}
            classNameType={InputClassNameType.card}
            name={companyName || ''}
            onChange={(e) => {
              setDetails({ ...details, companyName: e.target.value });
              setConfirmActive(true);
            }}
            value={companyName}
            required
            id="companyName"
          />
          <label htmlFor={street}>Straatnaam:</label>
          <Input
            type={InputTypes.text}
            classNameType={InputClassNameType.card}
            name={street || ''}
            onChange={(e) => {
              setDetails({ ...details, street: e.target.value });
              setConfirmActive(true);
            }}
            value={street}
            required
            id="street"
          />
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column mr-1">
              <label htmlFor={streetNumber}>Huisnummer:</label>
              <Input
                type={InputTypes.number}
                classNameType={InputClassNameType.card}
                name={streetNumber || ''}
                onChange={(e) => {
                  setDetails({ ...details, streetNumber: e.target.value });
                  setConfirmActive(true);
                }}
                value={streetNumber}
                required
                id="streetNumber"
              />
            </div>
            <div className="d-flex flex-column">
              <label htmlFor={suffix}>Toevoeging:</label>
              <Input
                type={InputTypes.text}
                classNameType={InputClassNameType.card}
                placeholder="Toevoeging"
                name={suffix || ''}
                onChange={(e) => {
                  setDetails({ ...details, suffix: e.target.value });
                  setConfirmActive(true);
                }}
                value={suffix}
                id="suffix"
              />
            </div>
          </div>
          <label htmlFor={zipCode}>Postcode:</label>
          <Input
            type={InputTypes.text}
            classNameType={InputClassNameType.card}
            name={zipCode || ''}
            onChange={(e) => {
              setDetails({ ...details, zipCode: e.target.value });
              setConfirmActive(true);
            }}
            value={zipCode}
            required
            id="zipCode"
          />
          <label htmlFor={city}>Stad:</label>
          <Input
            type={InputTypes.text}
            classNameType={InputClassNameType.card}
            name={city || ''}
            onChange={(e) => {
              setDetails({ ...details, city: e.target.value });
              setConfirmActive(true);
            }}
            value={city}
            required
            id="city"
          />

          <button
            className={`${classes.account_card_form_button} ${
              confirmActive ? classes.account_card_form_button_active : ''
            }`}
            type="submit">
            {buttonText}
          </button>
        </form>
      </div>
    </Card>
  ) : (
    <Card
      backgroundColor={null}
      col={isMobile ? '12' : col}
      height={height ?? null}>
      <div />
    </Card>
  );
}
