import React from 'react';
import { useHistory } from 'react-router-dom';
import classes from '../dashboard.module.scss';
import Card from '../../../components/card/card';
import LogoItem from '../../../components/logo-item/logo-item';
import { CustomerReferenceModel } from '../../../apiServices/models/api';

type CurrentCompaniesCardProps = {
  isMobile: boolean;
  latestReferences: CustomerReferenceModel[];
};

export default function CurrentReferentiesCard({
  isMobile,
  latestReferences
}: CurrentCompaniesCardProps) {
  const history = useHistory();

  return (
    <Card backgroundColor={null} col={isMobile ? '12' : '4'} height={null}>
      <div className={`d-flex flex-column ${classes.dashboard_refference}`}>
        <div
          className={`d-flex flex-column ${classes.dashboard_refference_wrapper}`}>
          <div
            className={`col-12 ${classes.dashboard_refference_text} ${classes.dashboard_refference_header}`}>
            <span>Recente referenties</span>
          </div>
          {latestReferences.map((reference) => {
            const splitName = reference.companyName?.split(' ');
            const first =
              splitName && splitName.length >= 1 ? splitName[0] : '';

            const second =
              splitName && splitName.length >= 2 ? splitName[1] : '';

            return (
              <LogoItem
                logo={`${first.charAt(0)}${second.charAt(0)}`}
                name={`${reference.name}`}
                company={`${reference.companyName}`}
              />
            );
          })}
        </div>
        <div className={`${classes.dashboard_refference_link}`}>
          <span onClick={() => history.push('/referenties')}>
            Bekijk alle referenties {'>'}
          </span>
        </div>
      </div>
    </Card>
  );
}
