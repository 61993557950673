import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classes from './layout.module.scss';

type RouteItemProps = {
  logo: string;
  routeName: string;
  route: string;
  active: boolean;
};

export default function RouteItem({
  logo,
  routeName,
  route,
  active
}: RouteItemProps) {
  const history = useHistory();
  const [rewardNotification, setRewardNotification] = useState(false);
  const routeOnclick = () => {
    if (rewardNotification && route === '/referenties') {
      sessionStorage.removeItem('currentUrlId');
      setRewardNotification(false);
    }
    history.push(route);
  };

  useEffect(() => {
    if (sessionStorage.getItem('currentUrlId') && route === '/referenties') {
      setRewardNotification(true);
    }
  }, []);
  return (
    <div
      key={logo}
      className={`${classes.sidebar_route_container} ${
        active ? `${classes.sidebar_route_container_active}` : ''
      }`}
      onClick={() => routeOnclick()}>
      <div className="row">
        <div className="col-3">
          {/* fix svg */}
          <img
            className={`${classes.sidebar_route_logo} ${
              active ? classes.sidebar_route_logo_active : ''
            }`}
            src={`${logo}`}
            alt={`${routeName}`}
          />
        </div>
        <div className={`col-9 d-flex ${classes.sidebar_route_text_container}`}>
          <span key={route} className={`${classes.sidebar_route_text}`}>
            {routeName}
          </span>
        </div>
      </div>
      {rewardNotification && (
        <span className={`${classes.sidebar_route_notification}`}>!</span>
      )}
    </div>
  );
}
