import React, { ReactElement, SyntheticEvent } from 'react';
import classes from './reward-link-button.module.scss';

type ButtonProps = {
  onClick?: (e: SyntheticEvent<HTMLDivElement, MouseEvent>) => void;
};

export const RewardLinkButton = ({ onClick }: ButtonProps): ReactElement => {
  return (
    <div onClick={onClick} className={`${classes.button}`}>
      Beloning link genereren
    </div>
  );
};
