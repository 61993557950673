import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import classes from '../login-register.module.scss';
import AttensieLogoBlauw from '../../../assets/png/Attensie_Logo_Blauw.png';
import AttensieLogoWit from '../../../assets/svg/Attensie_Logo_Wit.svg';
import Groepskorting from '../../../assets/svg/attensie-groepskorting.svg';
import { ApiController } from '../../../apiServices/calls/apiController';
import Input, { InputTypes } from '../../../components/input/Input';

export default function PasswordForgetTwo() {
  const location = useLocation();
  const history = useHistory();

  const [passwordForgetTwo, setPasswordForgetTwo] = useState({
    email: '',
    password: '',
    passwordRepeat: '',
    error: '',
    submitted: false
  });
  const [passwordResetToken, setPasswordResetToken] = useState('');

  const { email, password, passwordRepeat, error, submitted } =
    passwordForgetTwo;

  function handleFormChange(e: React.ChangeEvent<HTMLInputElement>) {
    setPasswordForgetTwo({
      ...passwordForgetTwo,
      [e.target.name]: e.target.value
    });
  }

  const handlePasswordForgetTwo = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    if (
      email.length === 0 ||
      password.length === 0 ||
      passwordRepeat.length === 0
    ) {
      setPasswordForgetTwo({
        ...passwordForgetTwo,
        error: 'Vul eerst alle gegevens in'
      });

      return;
    }

    if (password !== passwordRepeat) {
      setPasswordForgetTwo({
        ...passwordForgetTwo,
        error: 'Beide wachtwoorden zijn niet gelijk aan elkaar'
      });

      return;
    }

    const data = await ApiController.Identity.resetPassword(
      email,
      passwordResetToken,
      password
    );

    if (typeof data === 'string') {
      setPasswordForgetTwo({
        ...passwordForgetTwo,
        error: data
      });
      return;
    }

    setPasswordForgetTwo({
      ...passwordForgetTwo,
      submitted: true
    });
  };

  useEffect(() => {
    const tokenString = location?.pathname?.split('/').slice(-2)[0];
    return setPasswordResetToken(tokenString);
  }, [location?.pathname]);

  return (
    <>
      <div className={classes.register}>
        <div className={`${classes.register_left}`}>
          <div className={`${classes.register_left_content}`}>
            <div className={classes.register_left_content_img}>
              <img
                className={classes.register_left_content_img_logo}
                src={AttensieLogoBlauw}
                alt="Attensie"
              />
            </div>
            <div className={`${classes.register_left_content_container}`}>
              <p className={`${classes.register_left_content_container_steps}`}>
                &nbsp;
              </p>
              <h1>Wachtwoord vergeten</h1>
              {submitted ? (
                <>
                  <p>
                    Je wachtwoord is gewijzigd. Je kan hier nu mee inloggen.
                  </p>
                  <button type="submit" onClick={() => history.push('/login')}>
                    GA NAAR LOGIN
                  </button>
                </>
              ) : (
                <form
                  className={`d-flex flex-column ${classes.register_left_content_container_form}`}
                  onSubmit={handlePasswordForgetTwo}>
                  <Input
                    type={InputTypes.email}
                    placeholder="Email"
                    name="email"
                    value={email}
                    onChange={(e) => handleFormChange(e)}
                    required
                  />
                  <Input
                    type={InputTypes.password}
                    placeholder="Voer je nieuwe wachtwoord in"
                    name="password"
                    value={password}
                    onChange={(e) => handleFormChange(e)}
                    required
                  />
                  <Input
                    type={InputTypes.password}
                    placeholder="Voor je nieuwe wachtwoord nogmaals in"
                    name="passwordRepeat"
                    value={passwordRepeat}
                    onChange={(e) => handleFormChange(e)}
                    required
                  />
                  {error && (
                    <span className={classes.register_error}>{error}</span>
                  )}
                  <button type="submit">WACHTWOORD WIJZIGEN</button>
                </form>
              )}

              <p className={`${classes.register_left_content_container_terms}`}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.attensie.nl/algemene-voorwaarden">
                  Privacy Policy
                </a>
                &nbsp;en de&nbsp;
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.attensie.nl/algemene-voorwaarden">
                  Algemene Voorwaarden
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className={`${classes.register_right}`}>
          <img src={AttensieLogoWit} alt="Attensie" />
        </div>
      </div>
      <img
        className={`${classes.register_groepskorting}`}
        src={Groepskorting}
        alt="groepskorting"
      />
    </>
  );
}
