import React from 'react';
import { RewardApiModel } from '../../../apiServices/models/api';
import sluit from '../../../assets/png/close_icon.png';
import LogoItem from '../../../components/logo-item/logo-item';
import classes from '../doelen.module.scss';
import { RewardLinkGenerator } from './reward-link-generator/reward-link-generator';

type BeloningDetailCardModalBodyProps = {
  data: RewardApiModel;
  closeModal?: () => void;
};

export default function BeloningDetailCardModalBody({
  closeModal,
  data
}: BeloningDetailCardModalBodyProps) {
  const doelData = {
    name: data.name || '',
    reward: data.value || 0,
    awardedTo: data.awardedTo || '',
    ambassadorName: data.ambassadorName || '',
    awardedBy: data.awardedBy || '',
    reason: data.reason || '',
    date: data.date || '',
    err: false
  };

  const renderBody = () => {
    return (
      <>
        <div className={classes.doelen_modal_body_input}>
          <span>Naam:</span>
          <input
            type="text"
            name="name"
            value={doelData.name}
            placeholder="-"
            disabled
          />
        </div>
        <div className={classes.doelen_modal_body_input}>
          <span>Waarde:</span>
          <input
            type="text"
            name="reward"
            value={doelData.reward}
            placeholder="-"
            disabled
          />
        </div>
        <div className={classes.doelen_modal_body_input}>
          <span>Uitgegeven aan:</span>
          <input
            type="text"
            name="awardedTo"
            value={doelData.awardedTo}
            placeholder="-"
            disabled
          />
        </div>
        <div className={classes.doelen_modal_body_input}>
          <span>Ambassadeur:</span>
          <input
            type="text"
            name="ambassadorId"
            value={doelData.ambassadorName}
            placeholder="-"
            disabled
          />
        </div>
        <div className={classes.doelen_modal_body_input}>
          <span>Uitgegeven door:</span>
          <input
            type="text"
            name="awardedBy"
            value={doelData.awardedBy}
            placeholder="-"
            disabled
          />
        </div>
        <div className={classes.doelen_modal_body_input}>
          <span>Om welke reden:</span>
          <input
            type="text"
            name="reason"
            value={doelData.reason}
            placeholder="-"
            disabled
          />
        </div>
        <div className={classes.doelen_modal_body_input}>
          <span>Datum uitgifte:</span>
          <input
            type="text"
            name="date"
            value={doelData.date}
            placeholder="-"
            disabled
          />
        </div>
        <RewardLinkGenerator rewardId={data.id} />
      </>
    );
  };

  return (
    <div className={`d-flex flex-column ${classes.doelen_modal}`}>
      <div className={classes.doelen_modal_header}>
        <LogoItem
          company={`Waarde: € ${data.value?.toString()}`}
          name={data.name}
        />
        <img
          src={sluit}
          alt="sluit"
          className={classes.doelen_modal_header_close}
          onClick={() => {
            if (closeModal) {
              closeModal();
            }
          }}
        />
      </div>
      <div className={classes.doelen_modal_body}>{renderBody()}</div>
      <div className={classes.doelen_modal_footer} />
    </div>
  );
}
