import React, { useEffect, useState } from 'react';
import classes from './account.module.scss';

import Header from '../../components/header/header';
import CardRow from '../../components/card-row/card-row';
import ReferentieCard from '../dashboard/referentie-card/referentie-card';
import useWindowDimensions from '../../helpers/window-dimensions';
import ChangeAccountCard, { DetailsType } from './change-account-card';
import { InputTypes } from '../../components/input/Input';
import Footer from '../../components/footer/footer';
import { UserDetailsModel } from '../../apiServices/models/api';
import { ApiController } from '../../apiServices/calls/apiController';

type AccountScreenState = {
  details: UserDetailsModel | undefined;
};

export default function Account() {
  const { isMobile } = useWindowDimensions();
  const [referentieModalIsOpen, setReferentieModalIsOpen] = useState(false);
  const [screenState, setScreenState] = useState<AccountScreenState>({
    details: undefined
  });

  useEffect(() => {
    if (!screenState.details) {
      Promise.all([ApiController.Identity.getDetails()]).then((data) => {
        if (data[0]) {
          setScreenState({
            details: data[0]
          });
        }
      });
    }
  }, [screenState]);

  return (
    <div className={`flex-column ${classes.account_container}`}>
      <Header subtitle="Je profieloverzicht" />
      <div>
        <CardRow isMobile={isMobile}>
          <ChangeAccountCard
            col="4"
            isMobile={isMobile}
            height="420px"
            title="Je profielgegevens"
            firstLabel="Naam:"
            firstPlaceholder={screenState.details?.name || 'Nieuwe naam'}
            secondLabel="E-mail:"
            secondPlaceholder={screenState.details?.email || 'Nieuwe email'}
            buttonText="WIJZIG GEGEVENS"
            typeOne={InputTypes.text}
            typeTwo={InputTypes.email}
            detailsType={DetailsType.customerDetails}
          />
          <ChangeAccountCard
            col="4"
            isMobile={isMobile}
            height="420px"
            title="Wachtwoord wijzigen"
            firstValue="currentPassword"
            firstLabel="Huidig wachtwoord:"
            firstPlaceholder="***************"
            secondValue="newPassword"
            secondLabel="Nieuw wachtwoord:"
            secondPlaceholder="Typ hier je nieuwe wachtwoord"
            buttonText="WIJZIG WACHTWOORD"
            typeOne={InputTypes.password}
            typeTwo={InputTypes.password}
            detailsType={DetailsType.password}
          />
          <ReferentieCard
            isMobile={isMobile}
            referentieModalIsOpen={referentieModalIsOpen}
            setReferentieModalIsOpen={setReferentieModalIsOpen}
          />
        </CardRow>
      </div>
      <Footer />
    </div>
  );
}
