import React, { ReactElement } from 'react';
import classes from './datagrid.module.scss';
import { Column, CellInfo } from './gridinterface';

type GridRowProps = {
  index: number;
  calcCellSize: (
    offset: 'S' | 'L' | undefined,
    offsetSize: number | undefined
  ) => number;
  item: Record<string, string | number | boolean | Record<string, unknown>>;
  columns: Column[];
  rowButton?: ((cellInfo: CellInfo) => React.ReactNode) | ReactElement;
};
export default function GridRow({
  index,
  calcCellSize,
  item,
  columns,
  rowButton
}: GridRowProps) {
  return (
    <div key={index} className={classes.datagrid_row}>
      {columns.map((column) => {
        if (column.component) {
          return (
            <div
              key={column.id}
              className={`col-${calcCellSize(
                column.offset,
                column.offsetSize
              )} ${classes.datagrid_row_item}`}>
              {typeof column.component === 'function'
                ? column.component({ index, value: item }, column)
                : column.component}
            </div>
          );
        }
        const keyIndex = Object.keys(item).findIndex(
          (key) => key === column.id
        );
        return (
          <div
            key={column.id}
            className={`col-${calcCellSize(column.offset, column.offsetSize)} ${
              classes.datagrid_row_item
            }`}>
            {keyIndex > -1 ? (
              <span title={Object.values(item)[keyIndex]?.toString()}>
                {Object.values(item)[keyIndex]}
              </span>
            ) : (
              <></>
            )}
          </div>
        );
      })}
      {rowButton && typeof rowButton === 'function'
        ? rowButton({ index, value: item })
        : rowButton}
    </div>
  );
}
