import React, { useState } from 'react';
import classes from './redeem-card-modal.module.scss';
import { renderHeader } from './render-headers';
import { renderBody } from './render-bodies';
import { renderFooter } from './render-footers';
import { SparenStateType } from '../../sparen';

type RedeemCardModalBodyProps = {
  title: string;
  image: string;
  altText: string;
  closeModal?: () => void;
  currentState: SparenStateType;
  productName: string;
  setState: (value: React.SetStateAction<SparenStateType>) => void;
};

export enum RedeemModalSteps {
  stepOne,
  success
}

export default function RedeemCardModalBody({
  closeModal,
  title,
  image,
  altText,
  currentState,
  productName,
  setState
}: RedeemCardModalBodyProps) {
  const [modalStep, setModalStep] = useState(RedeemModalSteps.stepOne);

  return (
    <div
      className={`d-flex flex-column ${classes.redeem_modal} ${
        modalStep === RedeemModalSteps.success
          ? classes.redeem_modal_success
          : ''
      }`}>
      {modalStep !== RedeemModalSteps.success && (
        <div className={classes.redeem_modal_header}>
          {renderHeader(title, closeModal)}
        </div>
      )}
      <div className={classes.referentie_modal_body}>
        {renderBody(modalStep, image, altText)}
      </div>
      <div className={classes.referentie_modal_footer}>
        {renderFooter(
          modalStep,
          setModalStep,
          closeModal,
          currentState,
          productName,
          setState
        )}
      </div>
    </div>
  );
}
