import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import classes from '../../login-register.module.scss';
import RegisterOne from './register-one';
import RegisterTwo from './register-two';
import AttensieLogoBlauw from '../../../../assets/png/Attensie_Logo_Blauw.png';
import AttensieLogoWit from '../../../../assets/svg/Attensie_Logo_Wit.svg';
import Groepskorting from '../../../../assets/svg/attensie-groepskorting.svg';
import { ApiController } from '../../../../apiServices/calls/apiController';

export type RegisterForm = {
  companyName: string;
  email: string;
  password: string;
  error: string | null;
  name: string;
  street: string;
  number: string;
  addition: string;
  zip: string;
  city: string;
};

export enum PageState {
  formOne,
  formTwo
}

export default function RegisterCompany() {
  const history = useHistory();
  const [pageState, setPageState] = useState(PageState.formOne);
  const [registerForm, setRegisterForm] = useState({
    companyName: '',
    email: '',
    password: '',
    error: '',
    name: '',
    street: '',
    number: '',
    addition: '',
    zip: '',
    city: ''
  });
  const {
    companyName,
    email,
    password,
    name,
    street,
    number,
    addition,
    zip,
    city
  } = registerForm;

  function handleFormChange(e: React.ChangeEvent<HTMLInputElement>) {
    setRegisterForm({
      ...registerForm,
      [e.target.name]: e.target.value
    });
  }

  const handleCompanyRegister = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    if (password.length <= 5) {
      return;
    }

    const errorMessage = await ApiController.Identity.registerCompany({
      email,
      fullName: name,
      password,
      city,
      companyName,
      houseNumber: number,
      houseNumberAddition: addition,
      postalCode: zip,
      streetName: street
    });

    if (typeof errorMessage === 'string') {
      setRegisterForm({
        ...registerForm,
        error: errorMessage
      });
      return;
    }

    history.push('/verify');
  };

  return (
    <>
      <div className={classes.register}>
        <div className={`${classes.register_left}`}>
          <div className={`${classes.register_left_content}`}>
            <div className={classes.register_left_content_img}>
              <img
                className={classes.register_left_content_img_logo}
                src={AttensieLogoBlauw}
                alt="Attensie"
              />
            </div>
            <div className={`${classes.register_left_content_container}`}>
              {pageState === PageState.formOne ? (
                <RegisterOne
                  registerForm={registerForm}
                  handleFormChange={handleFormChange}
                  setPageState={setPageState}
                />
              ) : (
                <RegisterTwo
                  registerForm={registerForm}
                  handleFormChange={handleFormChange}
                  handleCompanyRegister={handleCompanyRegister}
                />
              )}
            </div>
          </div>
        </div>
        <div className={`${classes.register_right}`}>
          <img src={AttensieLogoWit} alt="Attensie" />
        </div>
      </div>
      <img
        className={`${classes.register_groepskorting}`}
        src={Groepskorting}
        alt="groepskorting"
      />
    </>
  );
}
