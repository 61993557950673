import React, { useState } from 'react';
import classes from '../doelen.module.scss';
import sluit from '../../../assets/png/close_icon.png';
import helpendeHand from '../../../assets/svg/helpendehand.svg';
import { IntentionApiModel } from '../../../apiServices/models/api';
import InviteButton from '../../../components/button/invite-button';
import { ApiController } from '../../../apiServices/calls/apiController';
import LogoItem from '../../../components/logo-item/logo-item';
import { AppState, useAppState } from '../../../apiServices/appState';
import { Roles } from '../../../router/router';

type DoelDetailCardModalBodyProps = {
  data: IntentionApiModel;
  closeModal?: () => void;
  getDoelenData: () => void;
};

export enum DoelenModalSteps {
  stepOne,
  success,
  delete
}

export default function DoelDetailCardModalBody({
  closeModal,
  data,
  getDoelenData
}: DoelDetailCardModalBodyProps) {
  const [modalStep, setModalStep] = useState(DoelenModalSteps.stepOne);
  const [doelData, setDoelData] = useState({
    name: data.name || '',
    reward: data.reward || 0,
    issuedTo: data.issuedTo || '',
    ambassador: data.ambassador || '',
    issuedBy: data.issuedBy || '',
    reason: data.reason || '',
    issueDate: data.issueDate || '',
    err: false
  });
  const selector = (state: AppState) => ({
    companyId:
      Roles.AttensieAdmin === state.authentication.accessToken?.role
        ? state.adminDetails.companyId
        : undefined
  });
  const { companyId } = useAppState(selector).current;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    if (name === 'reward') {
      return value.match(/^[0-9]*$/)?.input
        ? setDoelData({ ...doelData, [name]: Number(value), err: false })
        : setDoelData({ ...doelData, err: true });
    }
    return setDoelData({ ...doelData, [name]: value });
  };

  const renderBody = (step: DoelenModalSteps) => {
    switch (step) {
      case DoelenModalSteps.stepOne:
        return (
          <>
            <div className={classes.doelen_modal_body_input}>
              <span>Naam:</span>
              <input
                type="text"
                name="name"
                value={doelData.name}
                placeholder="Vul de naam van de beloning in"
                onChange={onChange}
              />
            </div>
            <div className={classes.doelen_modal_body_input}>
              <span>Waarde:</span>
              <input
                type="text"
                name="reward"
                value={doelData.reward}
                placeholder="Vul de waarde van de beloning in"
                onChange={onChange}
              />
            </div>
          </>
        );
      case DoelenModalSteps.success:
      case DoelenModalSteps.delete:
        return (
          <div className={`${classes.doelen_modal_success_wrapper}`}>
            <div>
              <img src={helpendeHand} alt="succes_image" />
            </div>
            <div className={`${classes.doelen_modal_success_title}`}>
              <span>{`Doel is ${
                modalStep === DoelenModalSteps.delete
                  ? `verwijderd`
                  : `aangepast`
              }`}</span>
            </div>
            <div className={`${classes.doelen_modal_success_subtitle}`}>
              <span>De aanpassingen zijn verwerkt.</span>
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div
      className={`d-flex flex-column ${classes.doelen_modal} ${
        modalStep === DoelenModalSteps.success ||
        modalStep === DoelenModalSteps.delete
          ? classes.doelen_modal_success
          : ''
      }`}>
      {modalStep === DoelenModalSteps.stepOne && (
        <div className={classes.doelen_modal_header}>
          <LogoItem
            company={`Waarde: € ${data.reward?.toString()}`}
            name={data.name}
          />
          <img
            src={sluit}
            alt="sluit"
            className={classes.doelen_modal_header_close}
            onClick={() => {
              if (closeModal) {
                setModalStep(DoelenModalSteps.stepOne);
                closeModal();
              }
            }}
          />
        </div>
      )}
      <div className={classes.doelen_modal_body}>{renderBody(modalStep)}</div>
      <div className={classes.doelen_modal_footer}>
        <InviteButton
          col={
            modalStep === DoelenModalSteps.success ||
            modalStep === DoelenModalSteps.delete
              ? '12'
              : '8'
          }
          text={
            modalStep === DoelenModalSteps.success ||
            modalStep === DoelenModalSteps.delete
              ? 'AFRONDEN'
              : 'WIJZIG DOEL'
          }
          func={async (): Promise<void> => {
            if (
              (modalStep === DoelenModalSteps.success ||
                modalStep === DoelenModalSteps.delete) &&
              closeModal
            ) {
              setModalStep(DoelenModalSteps.stepOne);
              getDoelenData();
              return closeModal();
            }
            return ApiController.Company.updateCompanyIntention(
              data.id || '',
              doelData.name,
              doelData.reward,
              doelData.issuedTo,
              doelData.ambassador,
              doelData.issuedBy,
              doelData.reason,
              doelData.issueDate,
              companyId
            ).then(() => setModalStep(DoelenModalSteps.success));
          }}
          isMobile={false}
        />
        {!(
          modalStep === DoelenModalSteps.success ||
          modalStep === DoelenModalSteps.delete
        ) && (
          <InviteButton
            col="8"
            text="VERWIJDER"
            func={async (): Promise<void> => {
              return ApiController.Company.deleteCompanyIntention(
                data.id || '',
                companyId
              ).then(() => setModalStep(DoelenModalSteps.delete));
            }}
            isMobile={false}
          />
        )}
      </div>
    </div>
  );
}
