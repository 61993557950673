import React from 'react';
import classes from '../dashboard.module.scss';
import Card from '../../../components/card/card';
import LogoItem from '../../../components/logo-item/logo-item';
import { EmbassyApiModel } from '../../../apiServices/models/api';

type CurrentCompaniesCardProps = {
  isMobile: boolean;
  embassies: EmbassyApiModel[];
};

export default function CurrentCompaniesCard({
  isMobile,
  embassies
}: CurrentCompaniesCardProps) {
  return (
    <Card backgroundColor={null} col={isMobile ? '12' : '4'} height={null}>
      <div className={`d-flex flex-column ${classes.dashboard_refference}`}>
        <div
          className={`d-flex flex-column ${classes.dashboard_refference_wrapper}`}>
          <div
            className={`col-12 ${classes.dashboard_refference_text} ${classes.dashboard_refference_header}`}>
            <span>Voor deze bedrijven ben jij ambassadeur:</span>
          </div>
          {embassies.map((embassy) => {
            const splitName = embassy.companyName?.split(' ');
            const first =
              splitName && splitName.length >= 1
                ? splitName[0].toUpperCase()
                : '';

            const second =
              splitName && splitName.length >= 2
                ? splitName[1].toUpperCase()
                : '';

            return (
              <LogoItem
                logo={`${first.charAt(0)}${second.charAt(0)}`}
                name={`${embassy.companyName}`}
                company={`${embassy.referenceCount} Referentie(s) aangemeld`}
              />
            );
          })}
        </div>
      </div>
    </Card>
  );
}
