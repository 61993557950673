import { Status } from '../apiServices/models/api';

export const enumStatusNumbersToStrings = (status: Status): string => {
  switch (status) {
    case Status.NUMBER_1:
      return 'Nieuw';
    case Status.NUMBER_2:
      return 'Afgehandeld';
    case Status.NUMBER_3:
      return 'Neemt contact op';
    case Status.NUMBER_4:
      return 'Contact opnemen';
    case Status.NUMBER_5:
      return 'Afgeketst';
    case Status.NUMBER_6:
      return 'Verwijderd';
    default:
      return '';
  }
};
