import React from 'react';
import classes from '../referenties.module.scss';
import sluit from '../../../assets/png/close_icon.png';
import LogoItem from '../../../components/logo-item/logo-item';
import { createInitialsFromFullName } from '../../../helpers/logo-helpers';
import { CustomerReferenceModelWithStrings } from '../../../apiServices/calls/controllers/reference';

type ReferentieCardModalBodyProps = {
  data: CustomerReferenceModelWithStrings;
  closeModal?: () => void;
};

export enum DoelenModalSteps {
  stepOne,
  success
}

export default function ReferentieCardModalBody({
  closeModal,
  data
}: ReferentieCardModalBodyProps) {
  return (
    <div
      key={data.id}
      className={`d-flex flex-column ${classes.referentie_info_modal} `}>
      <div className={classes.referentie_info_modal_header}>
        <LogoItem
          logo={createInitialsFromFullName(data.name || '')}
          name={data.name}
          company={data.companyName || ''}
        />
        <img
          src={sluit}
          alt="sluit"
          className={classes.referentie_info_modal_header_close}
          onClick={() => {
            if (closeModal) {
              closeModal();
            }
          }}
        />
      </div>
      <div className={classes.referentie_info_modal_body}>
        <div className={classes.referentie_info_modal_body_data}>
          <h4>Gegevens:</h4>
          <span>{`Naam: ${data.name || ''}`}</span>
          <span>{`Email: ${data.email || ''}`}</span>
          <span>{`Telefoon: ${data.phoneNumber || ''}`}</span>
          <span>{`Toegevoegd op: ${
            data.referralDate
              ? new Date(data.referralDate).toLocaleDateString()
              : ''
          }`}</span>
        </div>
        <div className={classes.referentie_info_modal_body_data}>
          <h4>Referentie voor:</h4>
          <span>{`Bedrijf: ${data.companyName || ''}`}</span>
          <span>{`Contact status: ${data.status || ''}`}</span>
          <span>{`Conversie: € ${data.originalConversionTotal || 0}`}</span>
        </div>
        <div className={classes.referentie_info_modal_body_data}>
          <h4>Beloning:</h4>
          <span>{`Beloning naam: ${data.name || ''}`}</span>
          <span>{`Waarde van de beloning: ${data.value || 0}`}</span>
          <span>{`Uitgegeven aan: ${data.awardedTo || ''}`}</span>
          <span>{`Ambassadeur: ${data.ambassadorName || ''}`}</span>
          <span>{`Uitgegeven door: ${data.awardedBy || ''}`}</span>
          <span>{`Om welke reden: ${data.reason || ''}`}</span>
          <span>{`Datum uitgifte: ${
            data.createdAt ? new Date(data.createdAt).toLocaleDateString() : ''
          }`}</span>
        </div>
      </div>
      <div className={classes.referentie_info_modal_footer} />
    </div>
  );
}
