import { useState, useEffect } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  let isMobile = false;
  let isTablet = false;

  if (width < 992) {
    isMobile = true;
  }
  if (width > 576 && width < 992) {
    isTablet = true;
  }

  return {
    width,
    height,
    isMobile,
    isTablet
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
