import { http } from '../http';

// All calls for the credit modal
export interface CreditType {
  totalEarned: number;
  totalSpent: number;
  totalAvailable: number;
}

export interface MonthlyCreditType {
  monthStartDate: string;
  totalEarnings: number;
}

export class CreditController {
  constructor() {
    throw Error(
      'CreditController should not initialize, try using it like this => CreditController.login'
    );
  }

  static getCredit = async (): Promise<CreditType> =>
    http<CreditType>(`${process.env.REACT_APP_SERVER_URL}credit`);

  static getMonthlyCredit = async (): Promise<MonthlyCreditType> =>
    http<MonthlyCreditType>(
      `${process.env.REACT_APP_SERVER_URL}credit/monthly`
    );

  static redeemCredits = async (productName: string, amount: number) =>
    http<Promise<void>>(`${process.env.REACT_APP_SERVER_URL}credit/redeem`, {
      method: 'POST',
      body: JSON.stringify({
        productName,
        amount
      })
    });
}
