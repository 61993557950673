import React, { ReactNode, useEffect, useState } from 'react';
import classes from './sparen.module.scss';
import Header from '../../components/header/header';
import CardRow from '../../components/card-row/card-row';
import useWindowDimensions from '../../helpers/window-dimensions';
import EarningsCard from '../dashboard/earnings-card/earnings-card';
import dilleEnKamille from '../../assets/png/dille_en_kamille.jpg';
import fonq from '../../assets/png/fonq.jpg';
import nationaleEntertainmentCard from '../../assets/png/nationale_entertainment_card.jpg';
import patheThuis from '../../assets/png/pathe_thuis.jpg';
import restaurantCadeau from '../../assets/png/restaurant_cadeau.jpg';
import sportCadeau from '../../assets/png/sport_cadeau.jpg';
import bol from '../../assets/png/bol.png';
import zalando from '../../assets/png/zalando.jpg';
import fashionCadeau from '../../assets/png/fashioncadeau.png';
import beautyCadeau from '../../assets/png/beautycadeau.png';
import hema from '../../assets/png/hema.jpg';
import bijenkorf from '../../assets/png/bijenkof.png';

import RedeemCard from './redeem-card/redeem-card';
import SummaryCard from './summary-card/summary-card';
import ReferentieCard from '../dashboard/referentie-card/referentie-card';
import Footer from '../../components/footer/footer';
import { CreditType } from '../../apiServices/calls/controllers/credit';
import { ApiController } from '../../apiServices/calls/apiController';

type ErrorType = {
  message: string;
};
export type SparenStateType = {
  credit: CreditType | undefined;
  error: ErrorType | undefined;
  buttonDisabled: boolean;
};

export default function Sparen() {
  const { isMobile } = useWindowDimensions();
  const [referentieModalIsOpen, setReferentieModalIsOpen] = useState(false);
  const [champModalIsOpen, setChampModalIsOpen] = useState(false);
  const [nationaleModaleIsOpen, setNationaleModalIsOpen] = useState(false);
  const [restaurantCadeauIsOpen, setRestaurantCadeauIsOpen] = useState(false);
  const [fonqModalIsOpen, setFonqModalIsOpen] = useState(false);
  const [patheModalIsOpen, setPatheModalIsOpen] = useState(false);
  const [dilleEnKamilleModalIsOpen, setDilleEnKamilleModalIsOpen] =
    useState(false);
  const [bolModalIsOpen, setBolModalIsOpen] = useState(false);
  const [zalandoModalIsOpen, setZalandoModalIsOpen] = useState(false);
  const [fashionCadeauModalIsOpen, setFashionCadeauModalIsOpen] =
    useState(false);
  const [beautyCadeauModalIsOpen, setBeautyCadeauModalIsOpen] = useState(false);
  const [hemaModalIsOpen, setHemaModalIsOpen] = useState(false);
  const [bijenKorfModalIsOpen, setBijenkorfModalIsOpen] = useState(false);

  const [screenState, setScreenState] = useState<SparenStateType>({
    credit: undefined,
    error: undefined,
    buttonDisabled: false
  });

  useEffect(() => {
    if (!screenState.credit) {
      Promise.all([ApiController.Credit.getCredit()]).then((data) => {
        if (data[0]) {
          setScreenState({
            credit: data[0],
            error: undefined,
            buttonDisabled: false
          });
        }
      });
    }
  }, [screenState]);

  const renderCards = (): ReactNode => {
    return (
      <>
        <RedeemCard
          altText="Champ sportcadeau"
          productName="champ"
          image={sportCadeau}
          isMobile={isMobile}
          header="SportCadeau Card powered by Champ: €25"
          title="Wissel tegoed in voor een SportCadeau by Champ"
          modalIsOpen={champModalIsOpen}
          setModalIsOpen={setChampModalIsOpen}
          currentState={screenState}
          setState={setScreenState}
        />
        <RedeemCard
          altText="Nationale Entertainment Card"
          productName="Nationale Entertainment Card"
          image={nationaleEntertainmentCard}
          isMobile={isMobile}
          header="Nationale Entertainment Card: €25"
          title="Wissel tegoed in voor een Nationale Entertainment Card"
          modalIsOpen={nationaleModaleIsOpen}
          setModalIsOpen={setNationaleModalIsOpen}
          currentState={screenState}
          setState={setScreenState}
        />
        <RedeemCard
          altText="Restaurant Cadeau"
          productName="Restaurant Cadeau"
          image={restaurantCadeau}
          isMobile={isMobile}
          header="Restaurant Cadeau: €25"
          title="Wissel tegoed in voor een Restaurant Cadeau"
          modalIsOpen={restaurantCadeauIsOpen}
          setModalIsOpen={setRestaurantCadeauIsOpen}
          currentState={screenState}
          setState={setScreenState}
        />
        <RedeemCard
          altText="Fonq"
          productName="Fonq"
          image={fonq}
          isMobile={isMobile}
          header="Fonq Giftcard: €25"
          title="Wissel tegoed in voor een Fonq Giftcard"
          modalIsOpen={fonqModalIsOpen}
          setModalIsOpen={setFonqModalIsOpen}
          currentState={screenState}
          setState={setScreenState}
        />
        <RedeemCard
          altText="Pathe Thuis"
          productName="Pathe THuis"
          image={patheThuis}
          isMobile={isMobile}
          header="Pathe Thuis: €25"
          title="Wissel tegoed in bij Pathe Thuis"
          modalIsOpen={patheModalIsOpen}
          setModalIsOpen={setPatheModalIsOpen}
          currentState={screenState}
          setState={setScreenState}
        />
        <RedeemCard
          altText="Dille &#38; Kamille"
          productName="Dille &#38; Kamille"
          image={dilleEnKamille}
          isMobile={isMobile}
          header="Dille &#38; Kamille: €25"
          title="Wissel tegoed in bij Dille &#38; Kamille"
          modalIsOpen={dilleEnKamilleModalIsOpen}
          setModalIsOpen={setDilleEnKamilleModalIsOpen}
          currentState={screenState}
          setState={setScreenState}
        />
        <RedeemCard
          altText="Bol.com"
          productName="Bol.com"
          image={bol}
          isMobile={isMobile}
          header="Bol.com: €25"
          title="Wissel tegoed in bij Bol.com"
          modalIsOpen={bolModalIsOpen}
          setModalIsOpen={setBolModalIsOpen}
          currentState={screenState}
          setState={setScreenState}
        />
        <RedeemCard
          altText="Zalando"
          productName="Zalando"
          image={zalando}
          isMobile={isMobile}
          header="Zalando: €25"
          title="Wissel tegoed in bij Zalando"
          modalIsOpen={zalandoModalIsOpen}
          setModalIsOpen={setZalandoModalIsOpen}
          currentState={screenState}
          setState={setScreenState}
        />
        <RedeemCard
          altText="FashionCadeau"
          productName="FashionCadeau"
          image={fashionCadeau}
          isMobile={isMobile}
          header="FashionCadeau: €25"
          title="Wissel tegoed in bij FashionCadeau"
          modalIsOpen={fashionCadeauModalIsOpen}
          setModalIsOpen={setFashionCadeauModalIsOpen}
          currentState={screenState}
          setState={setScreenState}
        />
        <RedeemCard
          altText="BeautyCadeau"
          productName="BeautyCadeau"
          image={beautyCadeau}
          isMobile={isMobile}
          header="BeautyCadeau: €25"
          title="Wissel tegoed in bij BeautyCadeau"
          modalIsOpen={beautyCadeauModalIsOpen}
          setModalIsOpen={setBeautyCadeauModalIsOpen}
          currentState={screenState}
          setState={setScreenState}
        />
        <RedeemCard
          altText="Hema"
          productName="Hema"
          image={hema}
          isMobile={isMobile}
          header="Hema: €25"
          title="Wissel tegoed in bij Hema"
          modalIsOpen={hemaModalIsOpen}
          setModalIsOpen={setHemaModalIsOpen}
          currentState={screenState}
          setState={setScreenState}
        />
        <RedeemCard
          altText="Bijenkorf"
          productName="Bijenkorf"
          image={bijenkorf}
          isMobile={isMobile}
          header="Bijenkorf: €25"
          title="Wissel tegoed in bij Bijenkorf"
          modalIsOpen={bijenKorfModalIsOpen}
          setModalIsOpen={setBijenkorfModalIsOpen}
          currentState={screenState}
          setState={setScreenState}
        />
      </>
    );
  };

  const determineCardOrder = (): ReactNode => {
    if (isMobile) {
      return (
        <>
          <div className="p-0 d-flex flex-column">
            <EarningsCard
              col="6"
              amount={
                screenState.credit?.totalAvailable &&
                screenState.credit.totalAvailable
              }
              showLink={false}
              subTitle="Kies een leuke giftcard en verzilver je beloningen"
              title="Dit zijn jouw totale beloningen:"
              isMobile={isMobile}
              height="245px"
            />
            <SummaryCard
              isMobile={isMobile}
              totalAvailable={
                screenState.credit?.totalAvailable &&
                screenState.credit.totalAvailable
              }
              totalEarned={
                screenState.credit?.totalEarned &&
                screenState.credit.totalEarned
              }
              totalSpent={
                screenState.credit?.totalSpent && screenState.credit.totalSpent
              }
            />
            {renderCards()}
            <ReferentieCard
              isMobile={isMobile}
              referentieModalIsOpen={referentieModalIsOpen}
              setReferentieModalIsOpen={setReferentieModalIsOpen}
              height="515px"
            />
          </div>
        </>
      );
    }

    return (
      <>
        <div className="col-8 row">
          <EarningsCard
            col="6"
            amount={
              screenState.credit?.totalAvailable &&
              screenState.credit.totalAvailable
            }
            showLink={false}
            subTitle="Kies een leuke giftcard en verzilver je beloningen"
            title="Dit zijn jouw totale beloningen:"
            isMobile={isMobile}
            height="245px"
          />
          <SummaryCard
            isMobile={isMobile}
            totalAvailable={
              screenState.credit?.totalAvailable &&
              screenState.credit.totalAvailable
            }
            totalEarned={
              screenState.credit?.totalEarned && screenState.credit.totalEarned
            }
            totalSpent={
              screenState.credit?.totalSpent && screenState.credit.totalSpent
            }
          />
          {renderCards()}
        </div>
        <ReferentieCard
          isMobile={isMobile}
          referentieModalIsOpen={referentieModalIsOpen}
          setReferentieModalIsOpen={setReferentieModalIsOpen}
          height="515px"
        />
      </>
    );
  };

  return (
    <div className={`flex-column ${classes.sparen_container}`}>
      <Header subtitle="Jouw beloningen" />
      <div>
        <CardRow isMobile={isMobile}>{determineCardOrder()}</CardRow>
      </div>
      <Footer />
    </div>
  );
}
