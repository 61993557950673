import React, { ReactNode, SyntheticEvent } from 'react';
import classes from './card.module.scss';

type CardProps = {
  children: ReactNode;
  col: '2' | '4' | '6' | '8' | '10' | '12';
  backgroundColor: string | null;
  height: string | null;
  isModal?: boolean | undefined;
  onClick?: (e: SyntheticEvent<HTMLDivElement, MouseEvent>) => void;
  disabled?: boolean | undefined;
};

export default function Card({
  children,
  col,
  backgroundColor,
  height,
  isModal,
  onClick,
  disabled
}: CardProps) {
  return (
    <div
      onClick={onClick}
      style={{
        minHeight: height || '100%'
      }}
      className={`col-${col} ${
        disabled === true ? classes.card_wrapper_disabled : classes.card_wrapper
      } ${isModal ? classes.card_modal : ''}`}>
      <div
        style={{
          height: height || '100%',
          backgroundColor: backgroundColor || '#ffffff'
        }}
        className={`${classes.card_container}`}>
        {children}
      </div>
    </div>
  );
}
