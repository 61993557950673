import React, { useState } from 'react';
import InviteButton from '../../../components/button/invite-button';
import classes from '../doelen.module.scss';
import helpendeHand from '../../../assets/svg/helpendehand.svg';
import { ApiController } from '../../../apiServices/calls/apiController';
import sluit from '../../../assets/png/close_icon.png';
import { AppState, useAppState } from '../../../apiServices/appState';
import { Roles } from '../../../router/router';

type ReferentieCardModalBodyProps = {
  closeModal?: () => void;
  getDoelenData: () => void;
};

export enum DoelenModalSteps {
  stepOne,
  success
}

export default function DoelenCardModalBody({
  closeModal,
  getDoelenData
}: ReferentieCardModalBodyProps) {
  const [modalStep, setModalStep] = useState(DoelenModalSteps.stepOne);
  const [doelData, setDoelData] = useState({
    name: '',
    reward: 0,
    err: false
  });
  const selector = (stateSelector: AppState) => ({
    companyId:
      Roles.AttensieAdmin === stateSelector.authentication.accessToken?.role
        ? stateSelector.adminDetails.companyId
        : undefined
  });
  const { companyId } = useAppState(selector).current;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    if (name === 'reward') {
      return value.match(/^[0-9]*$/)?.input
        ? setDoelData({ ...doelData, [name]: Number(value), err: false })
        : setDoelData({ ...doelData, err: true });
    }
    return setDoelData({ ...doelData, [name]: value });
  };

  const renderBody = (step: DoelenModalSteps) => {
    switch (step) {
      case DoelenModalSteps.stepOne:
        return (
          <>
            <div className={classes.doelen_modal_body_input}>
              <span>Tip omschrijving:</span>
              <input
                type="text"
                name="name"
                placeholder="Tip naam"
                required
                onChange={onChange}
              />
            </div>
            <div className={classes.doelen_modal_body_input}>
              <span>Waarde van tip:</span>
              <input
                type="text"
                name="reward"
                placeholder="Waarde in euro`s"
                pattern="^[0-9]*$"
                required
                onChange={onChange}
              />
            </div>
          </>
        );
      case DoelenModalSteps.success:
        return (
          <div className={`${classes.doelen_modal_success_wrapper}`}>
            <div>
              <img src={helpendeHand} alt="succes_image" />
            </div>
            <div className={`${classes.doelen_modal_success_title}`}>
              <span>Doel is aangemaakt</span>
            </div>
            <div className={`${classes.doelen_modal_success_subtitle}`}>
              <span>
                Het doel is aangemaakt en ambassadeurs kunnen dit doel nu
                selecteren om referenties aan te koppelen.
              </span>
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div
      className={`d-flex flex-column ${classes.doelen_modal} ${
        modalStep === DoelenModalSteps.success
          ? classes.doelen_modal_success
          : ''
      }`}>
      {modalStep === DoelenModalSteps.stepOne && (
        <div className={classes.doelen_modal_header}>
          <span className={classes.doelen_modal_header_title}>
            Tip aanmaken
          </span>
          <span className={classes.doelen_modal_header_subtitle}>
            Maak hieronder een tip aan die ambassadeurs meegeven aan een
            referentie.
          </span>
          <img
            src={sluit}
            alt="sluit"
            className={classes.doelen_modal_header_close}
            onClick={() => {
              if (closeModal) {
                setModalStep(DoelenModalSteps.stepOne);
                closeModal();
              }
            }}
          />
        </div>
      )}
      <div className={classes.doelen_modal_body}>{renderBody(modalStep)}</div>
      <div
        className={
          doelData.name && doelData.reward && !doelData.err
            ? classes.doelen_modal_footer
            : `${classes.doelen_modal_footer} ${classes.doelen_modal_footer_disabled}`
        }>
        <InviteButton
          text={
            modalStep === DoelenModalSteps.success ? 'AFRONDEN' : 'TIP AANMAKEN'
          }
          func={(): void | null => {
            if (doelData.name && doelData.reward) {
              if (modalStep === DoelenModalSteps.success && closeModal) {
                setModalStep(DoelenModalSteps.stepOne);
                setDoelData({
                  name: '',
                  reward: 0,
                  err: false
                });
                getDoelenData();
                return closeModal();
              }
              if (!doelData.err) {
                ApiController.Company.createCompanyIntention(
                  doelData.name,
                  doelData.reward,
                  companyId
                ).then(() => setModalStep(DoelenModalSteps.success));
              }
            }

            return null;
          }}
          isMobile={false}
        />
      </div>
    </div>
  );
}
