import React, { useState } from 'react';
import { AppState, useAppState } from '../../../apiServices/appState';
import { ApiController } from '../../../apiServices/calls/apiController';
import { RewardApiModel } from '../../../apiServices/models/api';
import sluit from '../../../assets/png/close_icon.png';
import helpendeHand from '../../../assets/svg/helpendehand.svg';
import InviteButton from '../../../components/button/invite-button';
import { Roles } from '../../../router/router';
import { DoelenScreenStateType } from '../b2b-doelen';
import classes from '../doelen.module.scss';

type LosseBeloningCardModalBodyProps = {
  closeModal?: () => void;
  getDoelenData: () => void;
  state: DoelenScreenStateType;
  data?: RewardApiModel;
};

type DoelData = {
  name: string;
  reward: number;
  awardedBy: string;
  awardedTo: string;
  reason: string;
  date: string;
  ambassadorName: null | string;
  toAllAmbassadors: boolean;
  isOnboardingReward: boolean;
  err: boolean;
};

export enum DoelenModalSteps {
  stepOne,
  success
}

export default function LosseBeloningCardModalBody({
  closeModal,
  getDoelenData,
  state,
  data
}: LosseBeloningCardModalBodyProps) {
  const todayDate = new Date().toLocaleDateString('en-CA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });
  const [modalStep, setModalStep] = useState(DoelenModalSteps.stepOne);
  const [allAmbassadors, setAllAmbassadors] = useState(false);
  const [isOnboardingReward, setisOnboardingReward] = useState(false);
  const [doelData, setDoelData] = useState<DoelData>({
    name: data ? data.name : '',
    reward: data ? data.value : 0,
    awardedBy: data ? data.awardedBy : '',
    awardedTo: data ? data.awardedTo : '',
    reason: data ? data.reason : '',
    date: data ? data.date : todayDate,
    ambassadorName: null,
    toAllAmbassadors: false,
    isOnboardingReward: false,
    err: false
  });

  const selector = (stateSelector: AppState) => ({
    companyId:
      Roles.AttensieAdmin === stateSelector.authentication.accessToken?.role
        ? stateSelector.adminDetails.companyId
        : undefined
  });
  const { companyId } = useAppState(selector).current;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name, checked } = e.target;

    if (name === 'reward') {
      return value.match(/^[0-9]*$/)?.input
        ? setDoelData({ ...doelData, [name]: Number(value), err: false })
        : setDoelData({ ...doelData, err: true });
    }

    if (checked) {
      setDoelData({ ...doelData, [name]: true, err: false });
    }

    return setDoelData({ ...doelData, [name]: value });
  };

  const renderBody = (step: DoelenModalSteps) => {
    switch (step) {
      case DoelenModalSteps.stepOne:
        return (
          <>
            <div className={classes.doelen_modal_body_input}>
              <span>Beloning naam:</span>
              <input
                type="text"
                name="name"
                placeholder="Vul de naam van de beloning in"
                onChange={onChange}
              />
            </div>
            <div className={classes.doelen_modal_body_input}>
              <span>Waarde van beloning:</span>
              <input
                type="text"
                name="reward"
                placeholder="Vul de waarde in euro's in"
                pattern="^[0-9]*$"
                onChange={onChange}
              />
            </div>
            <div className={classes.doelen_modal_body_input}>
              <span>Uitgegeven door:</span>
              <input
                type="text"
                name="awardedBy"
                placeholder="Vul de uitgever in"
                onChange={onChange}
              />
            </div>
            <div className={classes.doelen_modal_body_input}>
              <span>Uitgegeven aan:</span>
              <input
                type="text"
                name="awardedTo"
                placeholder="Vul de ontvanger in"
                onChange={onChange}
              />
            </div>
            <div className={classes.doelen_modal_body_input}>
              <span>Om welke reden:</span>
              <input
                type="text"
                name="reason"
                placeholder="Vul de reden in"
                onChange={onChange}
              />
            </div>
            <div className={classes.doelen_modal_body_input}>
              <span>Datum:</span>
              <input
                type="text"
                name="date"
                placeholder="Vul de datum in"
                value={doelData.date}
                onChange={onChange}
              />
            </div>
            <div className={classes.doelen_modal_body_input}>
              <span>Ambassadeur selecteren:</span>
              <select
                name="ambassador"
                disabled={allAmbassadors || isOnboardingReward}
                onChange={(e) => {
                  setDoelData({
                    ...doelData,
                    ambassadorName: e.currentTarget.value
                  });
                }}>
                {!allAmbassadors && (
                  <option value="" selected disabled hidden>
                    Kies hier
                  </option>
                )}
                {state.ambassadors?.map((ambassador) => {
                  if (allAmbassadors || isOnboardingReward) {
                    return <option value=""> ----- </option>;
                  }
                  return (
                    <option
                      key={ambassador.ambassadorId}
                      value={`${ambassador.ambassadorId}`}>
                      {ambassador.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className={classes.doelen_modal_body_checkbox_container}>
              <div className="d-flex align-items-baseline">
                <input
                  type="checkbox"
                  name="allAmbassadors"
                  onChange={() => {
                    if (!allAmbassadors) {
                      setDoelData({
                        ...doelData,
                        ambassadorName: null
                      });
                    }

                    setAllAmbassadors(!allAmbassadors);
                  }}
                  checked={isOnboardingReward ? false : allAmbassadors}
                  disabled={isOnboardingReward}
                />
                <p className="ml-2">Voor alle ambassadeurs</p>
              </div>
              <div className="d-flex align-items-baseline">
                <input
                  type="checkbox"
                  name="isOnboardingReward"
                  onChange={() => {
                    setDoelData({
                      ...doelData,
                      ambassadorName: null
                    });

                    setisOnboardingReward(!isOnboardingReward);
                  }}
                  checked={allAmbassadors ? false : isOnboardingReward}
                  disabled={allAmbassadors}
                />
                <p className="ml-2">Onboarding reward</p>
              </div>
            </div>
          </>
        );
      case DoelenModalSteps.success:
        return (
          <div className={`${classes.doelen_modal_success_wrapper}`}>
            <div>
              <img src={helpendeHand} alt="succes_image" />
            </div>
            <div className={`${classes.doelen_modal_success_title}`}>
              <span>Beloning is aangemaakt</span>
            </div>
            <div className={`${classes.doelen_modal_success_subtitle}`}>
              <span>
                De beloning is aangemaakt en ambassadeurs kunnen deze beloning
                nu selecteren om referenties aan te koppelen.
              </span>
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div
      className={`d-flex flex-column ${classes.doelen_modal} ${
        modalStep === DoelenModalSteps.success
          ? classes.doelen_modal_success
          : ''
      }`}>
      {modalStep === DoelenModalSteps.stepOne && (
        <div className={classes.doelen_modal_header}>
          <span className={classes.doelen_modal_header_title}>
            Beloning aanmaken
          </span>
          <span className={classes.doelen_modal_header_subtitle}>
            Maak hieronder een beloning aan wat ambassadeurs kunnen selecteren.
          </span>
          <img
            src={sluit}
            alt="sluit"
            className={classes.doelen_modal_header_close}
            onClick={() => {
              if (closeModal) {
                setModalStep(DoelenModalSteps.stepOne);
                closeModal();
              }
            }}
          />
        </div>
      )}
      <div className={classes.doelen_modal_body}>{renderBody(modalStep)}</div>
      <div
        className={
          doelData.name && doelData.reward && !doelData.err
            ? classes.doelen_modal_footer
            : `${classes.doelen_modal_footer} ${classes.doelen_modal_footer_disabled}`
        }>
        <InviteButton
          text={
            modalStep === DoelenModalSteps.success
              ? 'AFRONDEN'
              : 'BELONING AANMAKEN'
          }
          func={(): void | null => {
            if (doelData.name && doelData.reward) {
              if (modalStep === DoelenModalSteps.success && closeModal) {
                setModalStep(DoelenModalSteps.stepOne);
                setDoelData({
                  name: '',
                  reward: 0,
                  awardedBy: '',
                  awardedTo: '',
                  reason: '',
                  date: todayDate,
                  ambassadorName: '',
                  toAllAmbassadors: allAmbassadors,
                  isOnboardingReward,
                  err: false
                });
                getDoelenData();
                return closeModal();
              }
              if (!doelData.err) {
                ApiController.Company.createCompanyReward(
                  doelData.name ?? '',
                  doelData.reward ?? 0,
                  doelData.awardedBy ?? '',
                  doelData.awardedTo ?? '',
                  doelData.reason ?? '',
                  doelData.date ?? todayDate,
                  allAmbassadors || isOnboardingReward
                    ? null
                    : doelData.ambassadorName,
                  allAmbassadors,
                  isOnboardingReward,
                  companyId
                ).then(() => setModalStep(DoelenModalSteps.success));
              }
            }

            return null;
          }}
          isMobile={false}
        />
      </div>
    </div>
  );
}
